import React, { FunctionComponent, useContext } from 'react';
import { Navigate } from "react-router-dom";
//context
import { UserContext } from 'contexts/user.context';
//service
import { signOutService } from 'services/auth.service/auth.service';

type roleGuardPorps = {
  children: React.ReactElement,
  role: "ROLE_ADMIN" | "ROLE_SUPER_ADMIN"
}

const RoleGuard : FunctionComponent<roleGuardPorps> = ({ role, children }) => {

  const { user } = useContext(UserContext);

  if (
    user.role_name === "ROLE_USER" ||
    (user.role_name === "ROLE_ADMIN" && role === "ROLE_SUPER_ADMIN")
  ) {
    signOutService();
    return (
      <Navigate to="/auth/sign-in" replace />
    )
  } else
    return children;

}

export default RoleGuard;
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi'
import ReactTooltip from 'react-tooltip';
//assets
import logo from 'assets/logo/logo.svg';
//util
import { getUserNav } from 'utils/userNavigation/userNavigation.util';
//interface
import { defaultList } from 'utils/userNavigation/navigationInterface';
//components
import NavItem from './NavItem/navItem.component';
//context
import { UserContext } from 'contexts/user.context';
//style
import './dashboardNav.scss';

type DashboardNavProps = {
  select: string,
  openMenu: number
}

const DashboardNav: FC<DashboardNavProps> = ({ select, openMenu }) => {

  const [list, setList] = useState(defaultList);
  const { user, signOut } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const [cordStart, setCordStart] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [cord, setCord] = useState(height);
  const [startSwipe, setStart] = useState(0);
  const navigate = useNavigate();

  const open = useMemo(() => () => {
    if (width > 700) return;
    const element = document.getElementById('dashboardNav');
    if (element) {
      setIsOpen(true);
      element.style.transitionDuration = '0.5s';
      element.style.transitionTimingFunction = 'ease-in-out';
      setCord(80);
      setTimeout(() => {
        element.style.transitionDuration = '0s';
        element.style.transitionTimingFunction = '';
        document.body.style.overflow = 'auto';
      }, 500);
    }
  }, [width]);

  const closeMenu = () => {
    if (width > 700) return;
    const element = document.getElementById('dashboardNav');
    if (element) {
      setIsOpen(false);
      element.style.transitionDuration = '0.5s';
      element.style.transitionTimingFunction = 'ease-in-out';
      setCord(height - 20);
      setTimeout(() => {
        element.style.transitionDuration = '0s';
        element.style.transitionTimingFunction = '';
        document.body.style.overflow = 'auto';
      }, 500);
    }
  }

  useEffect(() => {
    if (openMenu > 0 && !isOpen)
      open();
  }, [openMenu, open]); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (width > 700) return;
    setHeight(height);
    const element = document.getElementById('dashboardNav');
    if (element) {
      element.style.transitionDuration = '0.5s';
      element.style.transitionTimingFunction = 'ease-in-out';
      setCord(oldValue => oldValue > height / 2 ? height - 20 : 80);
      setTimeout(() => {
        element.style.transitionDuration = '0s';
        element.style.transitionTimingFunction = '';
        document.body.style.overflow = 'auto';
      }, 500);
    }
  }, [height, width]);

  useEffect(() => {
    setList(getUserNav(user.role_name));
  }, [user.role_name]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight)
  }

  const logout = () => {
    try {
      signOut();
      navigate("/auth/sign-In");
    } catch (e) {
    }
  }

  //start swipe
  const start = (e: any) => {
    if (width > 700) return;
    document.body.style.overflow = 'hidden';
    setStart(e.changedTouches[0].clientY);
    setCordStart(cord);
  }
  //stop swipe
  const stop = () => {
    if (width > 700) return;
    const element = document.getElementById('dashboardNav');
    if (element) {
      element.style.transitionDuration = '0.5s';
      element.style.transitionTimingFunction = 'ease-in-out';
      setTimeout(() => {
        element.style.transitionDuration = '0s';
        element.style.transitionTimingFunction = '';
        setStart(0);
        document.body.style.overflow = 'auto';
      }, 500);
    }
    if (cord > height / 2) {
      setIsOpen(false);
      setCord(height - 20);
    } else {
      setIsOpen(true)
      setCord(80);
    }
  }
  //moving menu
  const move = (e: any) => {
    if (width > 700) return;
    let y = e.changedTouches[0].clientY;
    let distance = startSwipe - y;
    let mv = cordStart - distance;
    if (cord - 40 < 40 || cord - 40 > (height - 40)) return;
    if (mv - 40 > (height - 40)) mv = height;
    if (mv - 40 < 40) mv = 80;
    setCord(mv);
  }

  return (
    <div
      id='dashboardNav'
      className="dashboardNav"
      style={{
        top: width < 700 ? cord - 40 + 'px' : ''
      }}
      onTouchMoveCapture={move}
      onTouchStart={start}
      onTouchEnd={stop}
    >
      <div>
        <div className='D700 lineContaine'>
          <div className='line'></div>
        </div>
        <div className='logoContainer'>
          <Link to="/"><img className='logo' src={logo} alt="" /></Link>
        </div>
        <div className='navItemList'>
          {
            list.map((item) => {
              return <NavItem
                click={closeMenu}
                key={item.id}
                id={item.id}
                selected={select}
                icon={item.icon}>
                {item.text}
              </NavItem>
            })
          }
        </div>
      </div>
      <div className='dashboardNav-bottom'>
        <div className='settings'>
          <NavItem
            click={closeMenu}
            key={"settings"}
            id={"settings"}
            selected={select}
            icon={FiSettings}>
            Parametre
          </NavItem>
        </div>
        <div className='line'></div>
        <div className='account'>
          <div className='account-desplay'>
            <div className='account-img'>
              <div>
                {user.firstName[0]}
              </div>
            </div>
            <div className='account-name'>
              <div>{user.firstName} {user.lastName}</div>
              <div className='email'>{user.email}</div>
            </div>
          </div>
          <div className='logout' onClick={logout}>
            <HiOutlineLogout data-tip="Déconnexion" />
            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardNav;
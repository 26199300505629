import { Delete, Get, Post, Put } from "services/axios.service"
import { AddContractInterface, ContractInterface, ContractInterfaceInput } from "./contract.interface"

const api = 'contracts'


export const reservation = (data: ContractInterfaceInput) => {
  return Post(`/${api}/add-contract`, data)
}

export const addContactAdmin = (data: AddContractInterface) => {
  return Post(`/${api}/admin/add-contract`, data)
}

export const getContracts = () => {
  return Get(
    `/${api}/user-contracts`)
}

export const getMyContracts = () => {
  return Get(
    `/${api}/user-history`)
}

export const getContractsAdmin = (page = 1, rowsPerPage = 5, car?: number, model?: number, brand?: number, search?: string)
  : Promise<{ contracts: ContractInterface[], contractsCount: number }> => {
  return Get(`/${api}/get-all-contracts`, { page, rowsPerPage, car, model, brand, search })
}

export const getUserContracts = (id: number) => {
  return Get(
    `/${api}/admin/get-user-history/${id}`)
}

export const deleteContract = (id: number) => {
  return Delete(
    `/${api}/super-admin/delete-contract/${id}`)
}

export const getStatus = () => {
  return Get(
    `/${api}/admin/get-statuses`)
}

export const ContractStatus = (id: number, statusId: number) => {
  return Put(
    `/${api}/admin/update-contract-status/${id}`, { idStatus: statusId })
}

export const ContractUpdate = (id: number, paidAmount: number) => {
  return Put(
    `/${api}/admin/set-paid-amount/${id}`, { paidAmount: paidAmount })
}

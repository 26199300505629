import { Get, Post, Put } from "services/axios.service";
import {
  signInInputs,
  signUpInputs,
  newPasswordInputs,
  updateProfileInputs,
  updatePasswordInputs
} from "./auth.interface";

const api = 'user';

export const signIn = (formFields: signInInputs) => {
  return Post(`/${api}/authenticate`, formFields)
    .then((user) => {
      localStorage.setItem("token", user.token)
      return Promise.resolve(user);
    })
}


export const signUp = (formFields: signUpInputs) => {
  return Post(`/${api}/register/`, formFields)
}

export const verifyEmail = (token: string) => {
  return Post(`/${api}/verify-email/`, { token: token })
}

export const changeEmailOfVerification = (email: string) => {
  return Post(`/${api}/update-email-verification/`, { newEmailVerification: email })
}

export const resendEmailOfVerification = () => {
  return Get(`/${api}/resend-email-verification/`)
}

export const updateProfile = (formFields: updateProfileInputs, token: string) => {
  return Put(`/${api}/update-credentials/`, formFields)
}

export const updateEmail = (email: string) => {
  return Post(
    `/${api}/update-email-request/`, { newEmail: email })
}


export const verifyNewEmail = (token: string) => {
  return Post(`/${api}/update-email/${token}`, {})
}

export const updatePassword = (formFields: updatePasswordInputs) => {
  return Put(`/${api}/update-password/`, formFields)
}

export const resetPassword = (email: string) => {
  return Post(`/${api}/forgot-password/`, { email: email })
}

export const newPassword = (formFields: newPasswordInputs) => {
  return Post(`/${api}/reset-password/`, formFields)
}

export const signOutService = () => {
  localStorage.clear();
}


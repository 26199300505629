import { Delete, Get, Post, Put } from "services/axios.service";
import { EmailInputInterface, EmailResponseInterface } from "./contact.interface";

const api = "contact";

export const getEmails = () =>{
  return Get(`/${api}/get-all-contacts`);
}

export const sendMail = (formFields : EmailInputInterface)=>{
  return Post(
    `/${api}/post-demand`,formFields);
}

export const sendResponse = (formFields : EmailResponseInterface)=>{
  return Post(
    `/${api}/respond-contact`,formFields);
}

export const viewEmail = (id : number)=>{
  return Put(
    `/${api}/set-contact-viewed/${id}`,{});
}

export const deleteEmail = (id : number) =>{
  return Delete(`/${api}/delete-contact/${id}`);
}


import { ModelInterface, ModelInterfaceInput } from "./model.interface";
import { defaultBrand } from "services/brand.service/brand.object";

export const defaultModel: ModelInterface = {
  id: 0,
  name: '',
  BrandId: 0,
  Brand: defaultBrand
}

export const defaultModelInput: ModelInterfaceInput = {
  name: '',
  idBrand: 0,
}

export const ModelInputErrors = {
  name : '',idBrand : ''
}
import { clientInput, UserInterface } from "./user.interface";

export const defaultClient: clientInput = {
  email: null,
  firstName: "",
  lastName: "",
  cin: "",
  permis: "",
  permisExpDate: null,
  num_tel: "",
  birthDate: null,
}

export const defaultClientErrors = {
  email: "",
  firstName: "",
  lastName: "",
  cin: "",
  permis: "",
  permisExpDate: "",
  num_tel: "",
  birthDate: "",
}

export const defaultUser: UserInterface = {
  id: 0,
  email: '',
  newEmail: '',
  firstName: '',
  lastName: '',
  num_tel: '',
  birthDate: new Date(''),
  role_name: 'Role_USER',
  cin: '',
  permis: '',
  permisExpDate: new Date(''),
  token: localStorage.getItem("token") ?? '',
  isVerified: false,
  cinIsVerified: false,
}
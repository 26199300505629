import { Get, Post, Delete } from "services/axios.service";
import { UserInterface, clientInput } from "./user.interface";

const api = "user";

export const getUsers = (page = 1, rowsPerPage = 5, search?: string, role?: string)
  : Promise<{ users: Array<UserInterface>, usersCount: number }> => {
  return Get(`/${api}/admin/get-users`, { page, rowsPerPage, search, role })
}
export const getUserData = () => {
  return Get(`/${api}/get-user-credentials`)
}

export const getEmployers = () => {
  return Get(`/${api}/super-admin/get-employees`)
}

export const getBans = () => {
  return Get(`/${api}/super-admin/get-banned`)
}

export const addClient = (form: clientInput) => {
  return Post(`/${api}/admin/add-client`, form)
}

export const blockUser = (id: number, status: boolean) => {
  return Post(`/${api}/super-admin/block-user/${id}`, { accountStatus: status })

}

export const deleteUser = (id: number) => {
  return Delete(`/${api}/super-admin/delete-user/${id}`)
}

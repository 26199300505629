import React, { FunctionComponent } from 'react';
import { Navigate } from "react-router-dom";

type authGuardPorps = {
  auth: boolean,
  children: React.ReactElement
}

const AuthGuard: FunctionComponent<authGuardPorps> = ({ children, auth }) => {

  switch (auth) {
    case true:
      if (!localStorage.getItem('token'))
        return (
          <Navigate to="/auth/sign-in" replace />
        )
      break;
    case false:
      if (localStorage.getItem('token'))
        return (
          <Navigate to="/dashboard/settings" replace />
        )
      break;
  }
  return children;

}

export default AuthGuard;
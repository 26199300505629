import { Delete, Get, Post, Put } from "services/axios.service"
import { ModelInterface, ModelInterfaceInput } from "./model.interface"

const api = 'model'

export const getModels = () : Promise<Array<ModelInterface>> => {
  return Get(
    `/${api}/get-all-models`)
}

export const addModel = (data: ModelInterfaceInput) => {
  return Post(`/${api}/add-model`, data)
}

export const getModelId = (id: number) => {
  return Get(`/${api}/get-model/${id}`)
}

export const updateModel = (data:ModelInterfaceInput,id : number) => {
  return Put(
    `/${api}/update-model/${id}`,data)
}

export const deleteModel = (id: number) => {
  return Delete(
    `/${api}/delete-model/${id}`)
}
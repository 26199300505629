import { useState, ReactNode, FC, useEffect } from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

type textInputProps = {
  moreStyle?: string
  option?: any
  required?: boolean
  error?: string
  message?: string
  label?: string,
  icon?: ReactJSXElement | null | ReactNode,
  handle: (event: any) => void
}

const TextInput: FC<textInputProps> = (props) => {

  const [type, setType] = useState(props.option?.type);
  const [icon, setIcon] = useState(props.icon);

  useEffect(() => {

    const changeVisibility = () => {
      if (type === "text")
        setType("password");
      else setType("text");
    }

    if (props.option?.type === "password") {
      if (type === "password") {
        setIcon(<span onClick={changeVisibility}>
          <MdOutlineVisibilityOff />
        </span>)
      } else {
        setIcon(<span onClick={changeVisibility}>
          <MdOutlineVisibility />
        </span>)
      }
    }
  }, [props.option?.type, type])

  const onchange = (event: any) => {
    props.handle(event.target);
  }

  return (
    <div className={`inputComponent ${props.moreStyle?.length ? props.moreStyle : ""}`}>
      {
        props.label &&
        <label
          className="label"
          htmlFor={props.option?.name}>
          {props.label}
        </label>
      }
      <div className={`input ${props.error?.length ? "error-style" : ""}`}>
        <input id={props.option?.name} {...props.option} type={type} onChange={onchange} required={props.required} />
        {icon}
      </div>

      {props.error?.length ?
        <div className="input-error">{props.error}</div>
        : null}
      {props.message?.length ?
        <div className="input-message">{props.message}</div>
        : null}
    </div>

  )


}

export default TextInput;
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
import { PopupContext } from "contexts/popup.context";
import { FC, useContext, useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import ReactTooltip from "react-tooltip";
//interface
import { UserInterface } from "services/user.service/user.interface";
//service
import { blockUser, getBans } from "services/user.service/user.service";
//style
import './ban.scss';

const Ban: FC = () => {

  const [banList, setBanList] = useState<Array<UserInterface>>([]);
  const { pushNext } = useContext(PopupContext);

  useEffect(() => {
    getBans()
      .then((result) => {
        setBanList(result)
      })
      .catch(() => {
        setBanList([]);
      })
  }, [])

  const removeBlock = (id: number, name: string) => {
    const handle = () => {
      blockUser(id, true)
        .then(() => {
          setBanList(
            banList.filter((user) => {
              return user.id !== id;
            }));
        })
        .catch(() => { })
    }
    const messages = [`${name} pourra peut-être se connecter à son compte et faire des réservations`];
    pushNext(<PopupVerify handle={handle} message={messages} target={name} type='unban' />);
  }

  return (
    <div className="banPage">
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <h1>Liste d'utilisateurs blocké</h1>
      <p>
        Une fois que vous avez bloqué quelqu'un, cette personne ne peut plus connecter via son compte
      </p>
      <div className="banList">
        {
          banList.length ?
            banList.map((user) => {
              return (
                <div key={user.id} className="userBanned ">
                  {user.cin} - {user.firstName} {user.lastName}
                  <IoIosClose
                    data-tip="Supprimer le blocage"
                    onClick={() => removeBlock(user.id, user.firstName + " " + user.lastName)}
                    className="removeIcon" />
                </div>
              )
            })
            : "0 Utilisateur blocké"
        }
      </div>
    </div>
  );
}

export default Ban;
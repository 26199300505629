import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FaBan } from 'react-icons/fa';
import { TiUserDelete } from 'react-icons/ti';
import ReactPaginate from 'react-paginate';
//context
import { UserContext } from 'contexts/user.context';
import { PopupContext } from 'contexts/popup.context';
//component
import SearchBar from '../searchBar/searchBar.component';
import Button from 'components/button/Button.component';
import UserHistory from './userHistory/userHistory.component';
import PopupVerify from 'components/popup/popupVerify/popupVerify.component';
//interface
import { UserInterface } from 'services/user.service/user.interface';
//service
import { blockUser, deleteUser, getUsers } from 'services/user.service/user.service';
//utils
import { date } from 'utils/date/date.util';
//style
import './users.scss';

const Users: FC = () => {

  const { user } = useContext(UserContext);
  const { pushNext } = useContext(PopupContext);

  const [users, setUsers] = useState<Array<UserInterface>>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowPerPage] = useState(10);
  const [usersCount, setUsersCount] = useState(0);
  let delayedSearch: NodeJS.Timeout;

  const getData = useMemo(() => () => {
    getUsers(page, rowPerPage, search)
      .then((result) => {
        setUsers(result.users);
        setUsersCount(result.usersCount);
      })
      .catch(() => {
        setUsers([]);
        setUsersCount(0);
      })
  }, [page, rowPerPage, search]);

  useEffect(() => {
    getData();
    return () => {
      if (delayedSearch) {
        clearTimeout(delayedSearch);
      }
    }
  }, [getData])

  useEffect(() => {
    setPage(1);
  }, [search])

  const handleSearch = (value: string) => {
    if (delayedSearch) {
      clearTimeout(delayedSearch);
    }
    delayedSearch = setTimeout(() => {
      setSearch(value);
    }, 500);
  }

  const block = (id: number, name: string) => {
    const handle = () => {
      blockUser(id, false)
        .then((res) => {
          setUsers(
            users.filter((user) => {
              return user.id !== id;
            }));
        })
        .catch(() => { })
    }

    const messages = [
      "En cas de blocage,",
      `${name} ne peut pas ouvrier son compter une autrefois.`,
      "Sauf si vous supprimez l'action dans la liste blocage dans le dashboard."
    ]
    pushNext(<PopupVerify handle={handle} message={messages} target={name} type='ban' />);
  }

  const removeUser = (id: number, name: string) => {
    const handle = () => {
      deleteUser(id)
        .then(() => {
          setUsers(
            users.filter((user) => {
              return user.id !== id;
            }));
        })
        .catch(() => { })
    }
    const messages = [
      "En cas de suppression, si le client a des contrats, vous perdrez ses informations.",
    ]
    pushNext(<PopupVerify message={messages} handle={handle} target={name} type='delete' />);
  }

  const openHistory = (id: number, fullName: string) => {
    pushNext(<UserHistory fullName={fullName} id={id} />)
  }

  const pageCount = useMemo(() => {
    return Math.ceil(usersCount / rowPerPage)
  }, [usersCount, rowPerPage])

  return (
    <div className="usersPage">
      <SearchBar text={true} title='Liste des utilisateurs' handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Nom</th>
              <th>Identité</th>
              <th>Permis</th>
              <th>Date de délivration</th>
              <th>Tél</th>
              <th>E-mail</th>
              <th>Date néssence</th>
              {
                (() => {
                  if (user.role_name === "ROLE_SUPER_ADMIN")
                    return (<th />)
                })()
              }
            </tr>
          </thead>
          <tbody>
            {
              users.map((element) => {
                return (
                  <tr key={`user${element.id} `} className='table-row'>
                    <td className='sticky-col'>
                      <span className='name' onClick={
                        () => openHistory(element.id, element.firstName + " " + element.lastName)
                      }>
                        {element.firstName} {element.lastName}
                      </span>
                    </td>
                    <td>{element.cin}</td>
                    <td>{element.permis ? element.permis : "N/A"}</td>
                    <td>{element.permisExpDate ? element.permisExpDate : "N/A"}</td>
                    <td>{element.num_tel}</td>
                    <td>{element.email} {!element.isVerified ? "(Non verifié)" : null}</td>
                    <td>{date(element.birthDate, false)}</td>
                    {
                      (() => {
                        if (user.role_name === "ROLE_SUPER_ADMIN")
                          return (
                            <td
                              className='action2'>
                              <Button
                                handle={() => removeUser(element.id, element.firstName + " " + element.lastName)}
                                moreStyle='action-btn'
                                tooltip='Supprimer compte utilisateur'
                                size='rounded'><TiUserDelete /></Button>
                              <Button
                                moreStyle='action-btn'
                                tooltip='Blocage'
                                handle={() =>
                                  block(element.id, element.firstName + " " + element.lastName)}
                                size='rounded'>
                                <FaBan />
                              </Button>
                            </td>
                          )
                      })()
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {pageCount > 1 &&
          <div className='pagination-container'>
            <ReactPaginate
              className='pagination'
              breakLabel="..."
              nextLabel=">>"
              forcePage={page - 1}
              onPageChange={(event) => { setPage(event.selected + 1) }}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<<"
              renderOnZeroPageCount={null}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default Users;
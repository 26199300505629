import { FC, useContext, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PhoneInput from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';
//service
import { defaultNewEmployer, defaultNewEmployerErrors } from "services/employer.service/employer.object";
//component
import Button from "components/button/Button.component";
import TextInput from "components/inputs/textInput/text.component";
//style
import './newAccount.scss';
import { addNewEmployer } from "services/employer.service/employer.service";
import { PopupContext } from "contexts/popup.context";

interface NewAccountProps {
  handleAdd: () => void
}

const NewAccount: FC<NewAccountProps> = ({ handleAdd }) => {

  const { closePopUp } = useContext(PopupContext);
  const [formFields, setFormFields] = useState(defaultNewEmployer);
  const [error, setError] = useState(defaultNewEmployerErrors);

  const handleForm = (event: any) => {
    const { name, value } = event
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" })
  }

  const handleNumber = (tel: any) => {
    setFormFields(oldValues => { return { ...oldValues, num_tel: tel } })
    setError(oldValues => { return { ...oldValues, num_tel: "" } })
  }

  const submit = (event: any) => {
    event.preventDefault();
    setError(defaultNewEmployerErrors);
    addNewEmployer(formFields)
      .then((result) => {
        if (result) {
          setError(defaultNewEmployerErrors);
          closePopUp();
          handleAdd();
        }
      }).catch((error) => {
        setError({ ...defaultNewEmployerErrors, email: error.message, ...error.errors });
      });
  }


  return (
    <div className="newAccount">
      <h2>
        Nouveau compte
      </h2>
      <p>
        Ajouter un admin comme nouveau compte
      </p>
      <div className="form">
        <form onSubmit={submit}>
          <TextInput
            option={{
              name: "email",
              type: "email",
              placeholder: "Adresse email"
            }}
            error={error.email}
            handle={handleForm} />
          <div className="gap"></div>

          <TextInput
            option={{
              name: "cin",
              type: "text",
              placeholder: "Carte d'identité"
            }}
            error={error.cin}
            handle={handleForm} />
          <div className="gap"></div>

          <div className="nameInput">
            <TextInput
              option={{
                name: "firstName",
                type: "text",
                placeholder: "Nom"
              }}
              error={error.firstName}
              handle={handleForm} />
            <TextInput
              option={{
                name: "lastName",
                type: "text",
                placeholder: "Prénom"
              }}
              error={error.lastName}
              handle={handleForm} />
          </div>
          <div className="gap"></div>

          <div className="inputComponent">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                value={formFields.birthDate}
                onChange={(newValue) => {
                  handleForm({ name: 'birthDate', value: newValue })
                }}
                renderInput={({ inputRef, inputProps, InputProps }) =>
                  <TextInput
                    icon={InputProps?.endAdornment}
                    handle={handleForm}
                    error={error.birthDate}
                    option={{
                      placeholdertext: "Date naissance",
                      name: 'birthDate',
                      ref: inputRef, ...inputProps
                    }} />
                }
              />
            </LocalizationProvider>
          </div>
          <div className="gap"></div>

          <div className="inputComponent">
            <div className="input">
              <PhoneInput
                labels={fr}
                country="TN"
                placeholder="Numéro téléphone"
                onChange={handleNumber} />
            </div>
            {error.num_tel?.length ?
              <div className="input-error">{error.num_tel}</div>
              : null}
          </div>
          <div className="gap"></div>

          <TextInput
            option={{
              name: "password",
              type: "password",
              placeholder: "Mot de passe"
            }}
            error={error.password}
            handle={handleForm} />


          <div className="gap"></div>

          <Button submit={true} moreStyle="auth-btn">Enregistrer</Button>
        </form>
      </div>
    </div>
  );
}

export default NewAccount;
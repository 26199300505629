import { navigate,closePopupHandle } from 'App';
import axios from 'axios';
import { signOutService } from 'services/auth.service/auth.service';

axios.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : localStorage.getItem('verifyToken') ;
        req.headers = {
            ...req.headers,
            'Authorization': "Bearer " + token  ,
            'Content-Type': "application/json"
        }
        return req;
    }
);
axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if ([401, 403].includes(err.response.status)) {
            signOutService();
            closePopupHandle();
            navigate('/auth/sign-in');
        }

        return Promise.reject(err);
    }
);



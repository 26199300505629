import SearchBar from "app/dashboard/searchBar/searchBar.component";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
//component
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
import Button from "components/button/Button.component";
import AddBrand from "./addBrand/addBrand.component";
//context
import { CarsContext } from "contexts/cars.context";
import { PopupContext } from "contexts/popup.context";
import { UserContext } from "contexts/user.context";
//interface
import { BrandInterface } from "services/brand.service/brand.interface";
import { deleteBrand } from "services/brand.service/brand.service";

interface BrandsManageProps {

}

const BrandsManage: FunctionComponent<BrandsManageProps> = () => {

  const { user } = useContext(UserContext);
  const { brands, cars, models, reset } = useContext(CarsContext);
  const { pushNext, setContent } = useContext(PopupContext);
  const [brandFiltered, setBrandFiltered] = useState<Array<BrandInterface>>();
  const [search, setSearch] = useState<string>('');
  const [controlAccess, setControlAccess] = useState<{ text: string, handle: () => void }>();

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (user.role_name === "ROLE_SUPER_ADMIN")
      setControlAccess({
        text: "Ajouter une marque",
        handle: () => { pushNext(<AddBrand type="add" />) }
      })
  }, [user, pushNext]);

  useEffect(() => {
    setBrandFiltered(brands)
  }, [brands]);

  useEffect(() => {
    setBrandFiltered(brands.filter((element) => {
      return element.name.toLowerCase().includes(search.toLowerCase())
    }))
  }, [search, brands])

  const handleSearch = (event: any) => {
    setSearch(event);
  }

  const upDateBrand = (brand: BrandInterface) => {
    pushNext(<AddBrand brand={brand} type="update" />);
  }

  const removeBrand = (brand: BrandInterface) => {
    const handle = () => {
      deleteBrand(brand.id)
        .then(() => {
          reset();
        })
        .catch(() => {

        })
    }
    setContent(<PopupVerify handle={handle} target={brand.name} type='delete' />);
  }

  return (
    <div className="manage-brand">
      <SearchBar
        text={true}
        add={controlAccess}
        title='Paramètre des marques'
        handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Marque</th>
              <th>Models</th>
              <th>Voitures</th>
              {
                user.role_name === "ROLE_SUPER_ADMIN" ?
                  <th /> : null
              }
            </tr>
          </thead>
          <tbody>
            {
              brandFiltered ?
                brandFiltered.map((element) => {
                  const carCount = cars.filter((elementCar) => {
                    return elementCar.Model.BrandId === element.id;
                  }).length;
                  const modelCount = models.filter((elementModel) => {
                    return elementModel.BrandId === element.id;
                  }).length;
                  return (
                    <tr key={element.id} className='table-row'>
                      <td className='sticky-col'>{element.name}</td>
                      <td>{modelCount}</td>
                      <td>{carCount}</td>
                      {
                        user.role_name === "ROLE_SUPER_ADMIN" ?
                          <td className='action2'>
                            <Button
                              tooltip='Modifier marque'
                              moreStyle='action-btn'
                              handle={() => upDateBrand(element)}
                              size='rounded'
                              type='edit'>
                              <MdEdit />
                            </Button>
                            <Button
                              tooltip='Supprimer marque'
                              moreStyle='action-btn'
                              handle={() => removeBrand(element)}
                              size='rounded'>
                              <AiFillDelete />
                            </Button>
                          </td>
                          : null}
                    </tr>
                  )
                })
                : null
            }
          </tbody>

        </table>
      </div>
    </div>
  );
}

export default BrandsManage;
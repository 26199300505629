import Button from "components/button/Button.component";
import TextInput from "components/inputs/textInput/text.component";
import { PopupContext } from "contexts/popup.context";
import { FC, FormEvent, useContext, useEffect, useRef, useState } from "react";
import { EmailInputInterface } from "services/contact.service/contact.interface";
import { defaultContactInputs } from "services/contact.service/contact.object";
import { sendMail } from "services/contact.service/contact.service";
//style
import './contactUs.scss';
import { indexPage, setDescription, setTitle } from "utils/seo/seo.utils";

const ContactUs: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Contacter nous");
    setDescription(`Contacter nous pour plus d'information, 
    GSM 1 : +216 27 900 011
    GSM 2 : +216 27 900 054
    Localisation : Av jalloule ben chrifa, sousse, 4071
    Email : contact@slama-rent-car.com
    `)
  }, []);

  const { done } = useContext(PopupContext);

  const [formFields, setFormFields] = useState<EmailInputInterface>(defaultContactInputs);
  const [error, setError] = useState<EmailInputInterface>(defaultContactInputs);
  const form = useRef<HTMLFormElement>(null);

  const handleForm = (event: any) => {
    const { name, value } = event;
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" });
  }

  const handleTarget = (event: any) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" });
  }

  const submit = (event: FormEvent) => {
    event.preventDefault();
    setError(defaultContactInputs);
    sendMail(formFields)
      .then(() => {
        done("E-mail envoyé");
        form.current?.reset();
      })
      .catch((errors) => {
        setError({ ...defaultContactInputs, ...errors.errors })
      })
  }

  return (
    <div className="contact-us">
      <div className='header'>
        <div className='background'></div>
        <h1>Contacter nous</h1>
      </div>
      <div className="content view">
        <h4 className="description">
          N'hésitez pas à nous contacter
        </h4>
        <form ref={form} className="form" onSubmit={submit}>
          <div className="email">
            <TextInput
              label="E-mail"
              option={{
                name: "from",
                placeholder: "client@exemple.com",
                type: "email"
              }}
              handle={handleForm}
            />
          </div>
          <TextInput
            label="Sujet"
            option={{
              name: "subject",
              placeholder: "Sujet"
            }}
            handle={handleForm}
          />
          <div className="inputComponent">
            <label htmlFor="bodyMessage">Message</label>
            <textarea
              onChange={handleTarget}
              name="bodyMessage"
              id="bodyMessage"
              className="input textarea" />
          </div>
          <Button
            submit={true}
            moreStyle="action"
          >Envoyer</Button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
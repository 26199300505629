import { FC, useEffect } from 'react';
//style
import './aboutUs.scss';
import { indexPage, setDescription, setTitle } from 'utils/seo/seo.utils';

const AboutUs: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("A propos");
    setDescription(`
    SLAMA Rent a Car est une agence de location de voitures en Tunisie qui propose à ses clients
    la location de voitures pas chères à Tunisie et avec possibilité de retrait la voiture aux aéroports de la Tunisie
    Tel que l'aéroport Tunis Carthage, aéroport Enfidha, aéroport Monastir.
    `)
  }, [])

  return (
    <div className="aboutUsPage">
      <div className='background'>
      </div>
      <div className='content view'>
        <div className='title'>
          <h2>SLAMA RENT A CAR</h2>
          <p>toujours opté pour le professionnalisme et le meilleur service</p>
        </div>
        <div className='aboutUs-container'>
          <h2>Agence location voiture</h2>
          <p>
            SLAMA Rent a Car est une agence de location de voitures en Tunisie qui propose à ses clients
            la location de voitures pas chères à Tunisie et avec possibilité de retrait la voiture aux aéroports de la Tunisie
            Tel que l'aéroport Tunis Carthage, aéroport Enfidha, aéroport Monastir.
            SLAMA Rent a Car est une société dont l'activité principale consiste en la location
            de voitures de tourisme et utilitaires en Tunisie notre objectif principal est la satisfaction du client .
          </p>
          <h3>Notre agence</h3>
          <div className='descriptionContainer'>
            <div className='description'>
              <b>Téléphone</b>
              <p>Mobile 1 : {process.env.REACT_APP_Tel}</p>
              <p>Mobile 2 : {process.env.REACT_APP_Tel_fix}</p>
              <b>Adresse</b>
              <p>{process.env.REACT_APP_Location}</p>
              <b>E-mail</b>
              <p>{process.env.REACT_APP_Email}</p>
            </div>
            <div></div>
            <iframe
              title='address'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.26951327071!2d10.6170849!3d35.842394399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd8ba373c08cfd%3A0x95118349b10bf4e1!2sSLAMA%20RENT%20A%20CAR!5e0!3m2!1sfr!2stn!4v1655256340546!5m2!1sfr!2stn"
              className='map'
              loading="lazy" ></iframe>
            <div></div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default AboutUs;
import { FC, useContext, useEffect, useState } from "react";
import { BsFillFilterCircleFill } from "react-icons/bs";
import { IoAddCircleSharp } from 'react-icons/io5';
import ReactTooltip from "react-tooltip";
//context
import { CarsContext } from "contexts/cars.context";
//service
import { getModelId } from "services/model.service/model.service";
import { getCarId } from "services/car.service/car.service";
//interface
import { Car } from "services/car.service/car.interface";
//component
import Button from "components/button/Button.component";
import ListInput from "components/inputs/selectList/list.component";
import TextInput from "components/inputs/textInput/text.component";
//style
import './searchBar.scss';
import { ModelInterface } from "services/model.service/model.interface";
import { FiRefreshCcw } from "react-icons/fi";

const defaultSearch = {
  brand: 0,
  model: 0,
  car: 0
}

type searchProps = {
  text?: boolean,
  add?: {
    text: string,
    handle: () => void
  }
  car?: boolean
  title?: string
  reload?: () => void
  handle?: (event: string) => void
}

const SearchBar: FC<searchProps> = ({ title, add, car, text, reload, handle }) => {

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [filterStatus, setFilterStatus] = useState(false);
  const {
    search,
    brands,
    modelsFiltered,
    carsFiltered,
    setSearch,
    filterCars,
    filterModels,
    setCarsFiltered,
    filterCarsByBrand
  } = useContext(CarsContext);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [width]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const handleSearch = (event: any) => {
    let { name, value } = event;
    if (typeof value === "string" && name !== "search") {
      value = parseInt(value);
    }
    switch (name) {
      case 'brand':
        filterModels(value);
        filterCarsByBrand(value);
        setSearch({ ...defaultSearch, brand: value })
        break;
      case 'model':
        if (value === 0) {
          filterModels(search.brand);
          filterCarsByBrand(search.brand);
          setSearch({
            ...search,
            model: value,
            car: value,
          })
        }
        else
          getModelId(value)
            .then((result: ModelInterface) => {
              filterModels(result.BrandId);
              filterCars(value);
              setSearch({
                brand: result.BrandId,
                model: value,
                car: 0,
              })
            })
        break;
      case 'search':
        if (handle)
          handle(event.value);
        break;
      default:
        if (value === 0) {
          filterModels(search.brand);
          filterCars(search.model);
          setSearch({
            ...search,
            car: value,
          })
        }
        else
          getCarId(value)
            .then((result: Car) => {
              filterModels(result.Model.BrandId);
              setCarsFiltered([result]);
              setSearch({
                brand: result.Model.BrandId,
                model: result.ModelId,
                car: value,
              })
            }).catch(() => { })
        break;
    }
  }

  const handleReload = () => {
    if (reload) {
      reload();
      setSearch(defaultSearch);
    }
  }

  return (
    <div className="search">
      <h1 className="title">{title} {
        reload ? <FiRefreshCcw data-tip={"Rafraîchir"} className="reload" onClick={handleReload} /> : ""
      }</h1>
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <div className='search-bar'>
        <div className="search-container">
          <div className="search-bar-inputs">
            {
              text &&
              <TextInput
                moreStyle={"search-input"}
                handle={handleSearch}
                option={{
                  name: "search",
                  type: "text",
                  placeholder: "Recherche"
                }}
              />
            }

            {car && width < 1200 &&
              <BsFillFilterCircleFill
                data-tip="Filter"
                onClick={() => setFilterStatus(!filterStatus)}
                className="filter" />}
            {
              (() => {
                if (car) {
                  return (
                    <div className="search-select">
                      <ListInput
                        placeholder="Marque"
                        name="brand"
                        prefix="B"
                        value={search?.brand}
                        handle={handleSearch}
                        list={brands} />
                      <ListInput
                        placeholder="Modèle"
                        name="model"
                        prefix="M"
                        value={search?.model}
                        handle={handleSearch}
                        list={modelsFiltered} />
                      <ListInput
                        placeholder="Matricule"
                        name="car"
                        prefix="C"
                        value={search?.car}
                        handle={handleSearch}
                        list={carsFiltered} />
                    </div>
                  )
                }
              })()
            }
          </div>
          {
            (() => {
              if (add) {
                return width > 800 ?
                  <Button
                    handle={add.handle}
                    type="success"
                    moreStyle="add-btn"
                    size={width > 700 ? "normal" : "rounded"}>{
                      add?.text
                    }</Button>
                  : <IoAddCircleSharp
                    data-tip={add?.text}
                    onClick={add.handle}
                    className="add" />
              }
            })()
          }
        </div>
        {car && filterStatus &&
          <div className="mobile-filter">
            <ListInput
              placeholder="Marque"
              name="brand"
              prefix="B"
              value={search?.brand}
              handle={handleSearch}
              list={brands} />
            <ListInput
              placeholder="Modèle"
              name="model"
              prefix="M"
              value={search?.model}
              handle={handleSearch}
              list={modelsFiltered} />
            <ListInput
              placeholder="Matricule"
              name="car"
              prefix="C"
              value={search?.car}
              handle={handleSearch}
              list={carsFiltered} />
          </div>}
      </div>
      <div className='line'></div>
    </div>
  )
}

export default SearchBar;
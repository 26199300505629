import { FC } from "react";
//style
import './image.scss';

interface ImageInputProps {
  error?: string
  handle: (event: any) => void;
}

const ImageInput: FC<ImageInputProps> = ({ handle, error }) => {

  const handleImage = (event: any) => {
    handle(event.target)
  }

  return (
    <div className="imageCar">
      <label
        className="label"
        htmlFor="imageCar">
        Image
      </label>

      <div className="imageCarSelect">
        <input
          className="carImage-btn"
          type="button"
          value="Choisir image"
          onClick={() => document?.getElementById('file')?.click()} />

        <input
          id="file"
          name="logoImg"
          onChange={handleImage}
          type="file"
          accept="image/png,image/jpg,image/jpeg" />
      </div>
      {
        error &&
        <div className="error">
          - {error}
        </div>
      }


    </div>);
}

export default ImageInput;
import { FC, useContext, useEffect, useState } from 'react';
//service
import { updatePassword } from 'services/auth.service/auth.service';
//component
import TextInput from 'components/inputs/textInput/text.component';
import Button from 'components/button/Button.component';
//object
import { defaultUpdatePasswordInputs } from 'services/auth.service/auth.object';
//style
import './security.scss';
import { PopupContext } from 'contexts/popup.context';



const Security: FC = () => {

  const [formFields, setFormFields] = useState(defaultUpdatePasswordInputs);
  const { done } = useContext(PopupContext)

  const [error, setError] = useState(defaultUpdatePasswordInputs);
  const [, setLoading] = useState(false);

  useEffect(() => {
    return () => setLoading(false);
  }, [])

  const handleForm = (event: any) => {
    const { name, value } = event
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" });
  }

  const checkChange = (): boolean => {
    if (formFields.actual_password &&
      formFields.new_password &&
      formFields.confirm_password)
      return true;
    return false;
  }

  const submit = (event: any) => {
    event.preventDefault();
    if (checkChange()) {
      setError(defaultUpdatePasswordInputs);
      setLoading(true);
      updatePassword(formFields)
        .then((result) => {
          setError(defaultUpdatePasswordInputs);
          const form = document.getElementById("securityForm") as HTMLFormElement;
          form?.reset();
          done("Mot de passe modifié")
        })
        .catch((error) => {
          setError({ ...defaultUpdatePasswordInputs, ...error.errors });
          //error
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }


  return (
    <div className='profile'>
      <h4>Securité</h4>
      mettez à jour votre mot de passe
      <div className='line'></div>
      <span className='description'>
        C'est une bonne idée d'utiliser un mot de passe fort
        que vous n'utilisez pas ailleurs
      </span>
      <form id="securityForm" onSubmit={submit}>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Actuel
            </span>
          </div>
          <div className=' settingsProfilInputs'>
            <TextInput
              option={{
                name: "actual_password",
                type: "password",
                placeholder: "Mot de passe actuel"
              }}
              error={error.actual_password}
              handle={handleForm}></TextInput>
          </div>
        </div>

        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Nouveau
            </span>
          </div>
          <div className=' settingsProfilInputs'>
            <TextInput
              option={{
                name: "new_password",
                type: "password",
                placeholder: "Nouveau mot de passe"
              }}
              error={error.new_password}
              handle={handleForm}></TextInput>
          </div>
        </div>

        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Confirmer
            </span>
          </div>
          <div className=' settingsProfilInputs'>
            <TextInput
              option={{
                name: "confirm_password",
                type: "password",
                placeholder: "Répetez nouveau mot de passe"
              }}
              error={error.confirm_password}
              handle={handleForm}></TextInput>
          </div>
        </div>
        <div className="update-btn">
          <Button submit={true}>Modifier</Button>
        </div>
      </form>



    </div>
  )
}

export default Security;
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from 'date-fns/locale/fr';
import { FC, useMemo } from "react";
import { isToday, isTomorrow } from "utils/date/date.util";
//component
import TextInput from "../textInput/text.component";
//style
import './selectDateTime.scss'

interface SelectDateTimeProps {
  type: 'start' | 'end',
  error?: boolean
  id?: string,
  defaultForm: {
    start: Date | null,
    end: Date | null
  },
  admin: boolean,
  handle: (e: any) => void
}

const SelectDateTime: FC<SelectDateTimeProps> = ({ id, error, type, defaultForm, admin, handle }) => {

  const calculateHourLimit = useMemo(() => (): Date | null => {
    if (type === 'start') {
      if (defaultForm.start) {
        if (isToday(defaultForm.start)) {
          return new Date(Date.now() + (3600 * 1000 * 2));
        } else {
          return null
        }
      } else {
        return new Date(Date.now() + (3600 * 1000 * 2));
      }
    } else {
      if (defaultForm.start && isToday(defaultForm.start)) {
        return new Date(Date.now() + (3600 * 1000 * 26));
      } else if (defaultForm.end) {
        if (isTomorrow(defaultForm.end)) {
          return new Date(Date.now() + (3600 * 1000 * 26));
        } else {
          return null
        }
      } else {
        return new Date(Date.now() + (3600 * 1000 * 26));
      }
    }
  }, [type, defaultForm])

  const handleForm = (event: any) => {
    handle(event);
  }

  return (
    <div id={id} className='selectDateTime'>
      <LocalizationProvider dateAdapter={AdapterDateFns} inline={true}
        locale={frLocale}>
        <DatePicker
          label={"Date"}
          okText={"ok"}
          cancelText={"Annuler"}
          minDate={
            type === 'start' ?
              admin ? undefined : new Date(Date.now() + (3600 * 1000 * 2))
              : defaultForm.start ?
                new Date(
                  new Date(defaultForm.start.getTime() + (3600 * 1000 * 24))
                ) : new Date(Date.now() + (3600 * 1000 * 26))
          }
          value={type === 'start' ? defaultForm.start : defaultForm.end}
          onChange={(newValue) => {
            handleForm({ name: type, value: newValue })
          }}
          renderInput={({ inputRef, inputProps, InputProps }) =>
            <TextInput
              error={error ? " " : ""}
              moreStyle='date-picker'
              label={type === 'start' ? "Date de retrait" : "Date de retour"}
              icon={InputProps?.endAdornment}
              handle={handleForm}
              option={{
                name: { type },
                ref: inputRef, ...inputProps,
                placeholder: "jj/mm/aaaa"
              }} />
          }
        />
        <TimePicker
          label={"Heure"}
          okText={"ok"}
          cancelText={"Annuler"}
          clearText={"réinitialiser"}
          minTime={
            calculateHourLimit()
          }
          clearable
          DialogProps={{
            PaperProps: {
              sx: { '& *:focus': { outline: 'none' } },
            },
          }}
          ampm={false}
          views={["hours"]}
          value={type === 'start' ? defaultForm.start : defaultForm.end}
          onChange={(newValue) => {
            handleForm({ name: 'hour', value: newValue })
          }}
          renderInput={({ inputRef, inputProps, InputProps }) =>
            <TextInput
              error={error ? " " : ""}
              label="Heure"
              icon={InputProps?.endAdornment}
              handle={handleForm}
              option={{
                name: 'hour',
                ref: inputRef,
                ...inputProps,
                placeholder: "HH:mm"
              }} />
          }
        />
      </LocalizationProvider>
    </div>
  )
}

export default SelectDateTime;
import { newEmployerInputs, newEmployerInputsErrors } from "./employer.interface";

export const defaultNewEmployer: newEmployerInputs = {
  email: '',
  firstName: '',
  lastName: '',
  num_tel: '',
  cin: '',
  birthDate: null,
  password: ''
}

export const defaultNewEmployerErrors: newEmployerInputsErrors = {
  email: '',
  firstName: '',
  lastName: '',
  num_tel: '',
  cin: '',
  birthDate: '',
  password: ''
}
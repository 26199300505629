import { Delete, Get, Post, Put } from "services/axios.service"
import { ReceivePointInterface } from "./points.interface"

const api = 'locations'

export const getPoints = () : Promise<Array<ReceivePointInterface>>=> {
  return Get(
    `/${api}/get-all-rtv-points`)
}

export const addPoint = (name: string) => {
  return Post(
    `/${api}/add-rtv-point`, { name: name })
}

export const updatePoint = (id: number, name: string) => {
  return Put(
    `/${api}/update-rtv-point/${id}`, { name: name })
}

export const deletePoint = (id: number) => {
  return Delete(
    `/${api}/delete-rtv-point/${id}`)
}



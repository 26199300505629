import { Post } from "services/axios.service";
import { newEmployerInputs } from "./employer.interface";

const api = "user";

export const employerStatus = (id: number , admin : boolean) => {
  return Post(
    `/${api}/super-admin/change-status/${id}`, { admin: admin })
}

export const addNewEmployer = (data : newEmployerInputs) => {
  return Post(
    `/${api}/super-admin/save-admin`, data)
}

import React, { createContext, useEffect, useMemo, useState } from "react";

import { getCars, getCarStatus, getFuel, getGearBox } from "services/car.service/car.service";
import { getModels } from "services/model.service/model.service";
import { getBrands } from "services/brand.service/brand.service";

import { BrandInterface } from "services/brand.service/brand.interface";
import { ModelInterface } from "services/model.service/model.interface";
import { Car, FuelInterface, GearBoxInterface, Status } from "services/car.service/car.interface";

const defaultSearch: Search = {
  brand: 0,
  model: 0,
  car: 0
}

interface Search {
  brand: number;
  model: number;
  car: number;
}

interface CarsContextInterface {
  brands: Array<BrandInterface>,
  models: Array<ModelInterface>,
  cars: Array<Car>,

  modelsFiltered: Array<ModelInterface>,
  carsFiltered: Array<Car>,

  status: Array<Status>,
  fuel: Array<FuelInterface>,
  carGear: Array<GearBoxInterface>,

  search: Search,

  setBrands: (brands: Array<BrandInterface>) => void,
  setModels: (models: Array<ModelInterface>) => void,
  setCars: (cars: Array<Car>) => void,

  setModelsFiltered: (models: Array<ModelInterface>) => void,
  setCarsFiltered: (cars: Array<Car>) => void,

  setStatus: (status: Array<Status>) => void,
  setFuel: (fuel: Array<FuelInterface>) => void,
  setCarGear: (gear: Array<GearBoxInterface>) => void,

  setSearch: (search: Search) => void

  reset: () => void,
  filterModels: (brand: number) => void,
  filterCars: (model: number) => void,
  filterCarsByBrand: (brand: number) => void,
}

export const CarsContext = createContext<CarsContextInterface>({
  brands: [],
  models: [],
  cars: [],

  modelsFiltered: [],
  carsFiltered: [],

  status: [],
  fuel: [],
  carGear: [],

  search: defaultSearch,

  setBrands: () => { },
  setModels: () => { },
  setCars: () => { },

  setModelsFiltered: () => { },
  setCarsFiltered: () => { },

  setStatus: () => { },
  setFuel: () => { },
  setCarGear: () => { },

  setSearch: () => { },

  reset: () => { },
  filterModels: () => { },
  filterCars: () => { },
  filterCarsByBrand: () => { },
})

type userProps = {
  children: React.ReactChild
}

export const CarsProvider = ({ children }: userProps) => {

  const [brands, setBrands] = useState<Array<BrandInterface>>([]);
  const [models, setModels] = useState<Array<ModelInterface>>([]);
  const [cars, setCars] = useState<Array<Car>>([]);

  const [modelsFiltered, setModelsFiltered] = useState<Array<ModelInterface>>([]);
  const [carsFiltered, setCarsFiltered] = useState<Array<Car>>([]);

  const [status, setStatus] = useState<Array<Status>>([]);
  const [fuel, setFuel] = useState<Array<FuelInterface>>([]);
  const [carGear, setCarGear] = useState<Array<GearBoxInterface>>([]);

  const [search, setSearch] = useState<Search>(defaultSearch);

  const sortCars = useMemo(() => (list: Array<Car>) => {
    if (!list.length) return list;
    else return list.sort(
      (carA, carB) => {
        const nameA = carA.registrationNumber.toLowerCase();
        const nameB = carB.registrationNumber.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    ).sort(
      (a, b) => {
        const nameA = a.Model.Brand.name.toLowerCase();
        const nameB = b.Model.Brand.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
  }, [])

  const sortBrandsAndModels = useMemo(() => <T extends BrandInterface | ModelInterface>(list: Array<T>): Array<T> => {
    if (!list.length) return list;
    else return list.sort(
      (objectA, objectB) => {
        const nameA = objectA.name.toLowerCase()
        const nameB = objectB.name.toLowerCase()
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }
    )
  }
    , [])

  const reset = useMemo(() => () => {
    getBrands()
      .then((result) => {
        setBrands(sortBrandsAndModels(result));
      });
    getModels()
      .then((result) => {
        const Models = sortBrandsAndModels(result);
        setModels(Models);
        setModelsFiltered(Models);
      });
    getCars()
      .then((result) => {
        const Cars = sortCars(result);
        setCars(Cars);
        setCarsFiltered(Cars)
      })
    setSearch(defaultSearch);
  }, [sortCars, sortBrandsAndModels])

  useEffect(() => {
    getCarStatus()
      .then((result) => {
        setStatus(result);
      })
    getFuel()
      .then((result) => {
        setFuel(result);
      })
    getGearBox()
      .then((result) => {
        setCarGear(result);
      })
    reset();
  }, [reset]);

  const filterModels = useMemo(() => (brand: number) => {
    const brandExist = brands.find((element) => {
      return element.id === brand;
    })
    if (brand === 0 || !brandExist) {
      setModelsFiltered(models)
      return;
    }
    setModelsFiltered(models.filter((element) => { return element.BrandId === brand }));
  }, [brands, models])

  const filterCarsByBrand = useMemo(() => (brand: number) => {
    const brandExist = brands.find((element) => {
      return element.id === brand;
    })
    if (brand === 0 || !brandExist) {
      setCarsFiltered(cars)
      return;
    }
    setCarsFiltered(cars.filter((element) => { return element.Model.BrandId === brand }));
  }, [brands, cars])

  const filterCars = useMemo(() => (model: number) => {
    const modelExist = models.find((element) => {
      return element.id === model;
    })
    if (model === 0 || !modelExist) {
      setCarsFiltered(cars)
      return;
    }
    setCarsFiltered(cars.filter((element) => { return element.ModelId === model }));
  }, [models, cars])

  const value = {
    brands,
    models,
    cars,

    modelsFiltered,
    carsFiltered,

    status,
    fuel,
    carGear,

    search,

    setBrands,
    setModels,
    setCars,

    setModelsFiltered,
    setCarsFiltered,

    setStatus,
    setFuel,
    setCarGear,

    setSearch,

    reset,
    filterModels,
    filterCars,
    filterCarsByBrand
  };
  return <CarsContext.Provider value={value}>{children}</CarsContext.Provider>
}
import { Delete, Get, Post, Put } from "services/axios.service"
import { BrandInterface } from "./brand.interface";

const api = 'brand';

export const addBrand = (data: FormData) => {
  return Post(`/${api}/add-brand`, data)
}

export const getBrands = (): Promise<Array<BrandInterface>> => {
  return Get(`/${api}/get-all-brands`)
}

export const getBrandId = (id: number) => {
  return Get(`/${api}/get-brand/${id}`)
}

export const updateBrand = (data: FormData, id: number) => {
  return Put(`/${api}/update-brand/${id}`, data)
}

export const deleteBrand = (id: number) => {
  return Delete(
    `/${api}/delete-brand/${id}`)
}
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import ReactTooltip from "react-tooltip";
//component
import Button from "components/button/Button.component";
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
import AddPoint from "./addPoint/addPoint.component";
//context
import { ContractContext } from "contexts/contract.context";
import { PopupContext } from "contexts/popup.context";
import { UserContext } from "contexts/user.context";
//interface
import { ReceivePointInterface } from "services/points.service/points.interface";
import { deletePoint } from "services/points.service/points.service";
//style
import "./points.scss";

const Points: FC = () => {

  const { points, resetPoints } = useContext(ContractContext);
  const { user, signOut } = useContext(UserContext);
  const { setContent } = useContext(PopupContext);
  const navigate = useNavigate();

  useEffect(() => {
    resetPoints();
    if (user.role_name !== "ROLE_SUPER_ADMIN") {
      try {
        signOut();
        navigate("/auth/sign-In");
      } catch (e) {
      }
    }
  }, [user.role_name, resetPoints, signOut, navigate])

  const updatePoint = (point: ReceivePointInterface) => {
    setContent(<AddPoint point={point} />)
  }

  const addPoint = () => {
    setContent(<AddPoint />)
  }

  const removePoint = (point: ReceivePointInterface) => {
    const handle = () => {
      deletePoint(point.id)
        .then((res) => {
          resetPoints();
        })
        .catch((error) => {
          //error
        })
    }
    setContent(<PopupVerify handle={handle} target={point.name} type='delete' />);
  }

  return (
    <div className="points">
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <h1>Liste des points de retrait</h1>
      <p>
        Ajout et modification des points de livraisons
      </p>
      <div className="add-point" onClick={addPoint}>
        <Button size="rounded" type="success">+</Button>
        <span>Ajouter un points</span>
      </div>
      <div className="points-list">
        {
          points.map((point) => {
            return (
              <div className="point" key={point.id}>
                {point.name}
                <span className="action">
                  <MdEdit data-tip={"Modifier"} className="edit" onClick={() => updatePoint(point)} />
                  <AiFillDelete data-tip={"Supprimer"} className="delete" onClick={() => removePoint(point)} />
                </span>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default Points;
import { FC, useContext, useState } from "react";
//components
import NavItem from "components/navItem/NavItem.component";
import Points from "./points/points.component";
import RentsManagment from "./rentsManagment/rentsManagment.component";
import { UserContext } from "contexts/user.context";
//style
import "./rents.scss";

const Rents: FC = () => {

  const { user } = useContext(UserContext);
  const [pageSelected, setPage] = useState<string>('rents');

  const pageSelectHandler = (page: string) => {
    setPage(page);
  }

  return (
    <div className="rents">
      <nav className='nav'>
        <NavItem id={"rents"} select={pageSelected} handle={pageSelectHandler}>Locations</NavItem>
        {
          user.role_name !== "ROLE_SUPER_ADMIN" ? "" :
            <NavItem id={"point"} select={pageSelected} handle={pageSelectHandler}>Point de retrait</NavItem>
        }

      </nav>
      {
        (() => {
          switch (pageSelected) {
            case 'rents': return <RentsManagment />;
            case 'point': return <Points />;
          }
        })()
      }
    </div>);
}

export default Rents;
import { Outlet } from 'react-router-dom';
//component
import MainNavigation from './header/navigation.component';
import Footer from './footer/footer.component';

function Main() {
  return (
    <div className="Main">
      <MainNavigation />
      <div className='main'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default Main; 
import { FC, useContext } from "react";
import Button from "components/button/Button.component";
//style
import './newOrOld.scss';
import { PopupContext } from "contexts/popup.context";
import NewAccount from "./newAccount/newAccount.component";
import OldAccount from "./oldAccount/oldAccount.component";

interface NewOrOldEmployerProps {
  handleAdd: () => void
}

const NewOrOldEmployer: FC<NewOrOldEmployerProps> = ({ handleAdd }) => {

  const { pushNext } = useContext(PopupContext);

  const handle = (type: string) => {
    if (type === "new")
      pushNext(<NewAccount handleAdd={handleAdd} />);
    else if (type === "old")
      pushNext(<OldAccount handleAdd={handleAdd} />);
  }

  return (
    <div className="newOrOlfEmployer">
      <Button
        handle={() => handle('new')}
        moreStyle="white-btn">
        Nouveau Compte
      </Button>

      <div className="gap">
      </div>

      <Button
        handle={() => handle('old')}
        moreStyle="white-btn">
        Compte existe
      </Button>

    </div>
  );
}

export default NewOrOldEmployer;
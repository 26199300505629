import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillPersonCheckFill } from "react-icons/bs";
//component
import Button from "components/button/Button.component";
import Footer from "app/main/footer/footer.component";
import MainNavigation from "app/main/header/navigation.component";
//style
import { verifyEmail } from "services/auth.service/auth.service";
import { noIndexPage, setDescription, setTitle } from "utils/seo/seo.utils";

const VerifyEmail: FC = () => {

  useEffect(() => {
    noIndexPage();
    setTitle("Vérification de l'E-mail | Slama rent a car");
    setDescription(`
    Vérifiez votre adresse e-mail chez Slama rent a car pour valider votre compte. 
    Suivez les instructions pour confirmer votre adresse e-mail et accéder à nos services en toute sécurité.
    `)
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const [result, setResult] = useState<boolean>();
  const [token] = useState<string | null>(useQuery().get('token'));
  const navigate = useNavigate();

  useEffect(() => {
    if (token)
      verifyEmail(token)
        .then(() => {
          setResult(true);
          navigate("/auth/sign-in");
        })
        .catch(() => {
          setResult(false);
        })
  }, [token, navigate])

  return (
    <div className='auth'>
      <div className="forget-password">
        <MainNavigation />
        <div className='forget-password-content'>
          <div className='background'>
          </div>
          <div className='forget-password-container'>
            {
              (
                result ?
                  <div className="email-verified">
                    <div className="description">Votre e-mail est activé vous pouvez connectez<BsFillPersonCheckFill /></div>
                    <Link to={'/auth/sign-in'}><Button moreStyle={"reset-btn"}>Connecter</Button></Link>
                  </div> :
                  <div className="email-verified">
                    <div className="description">un problème de vérification email veuillez envoyer un autre email</div>
                    <Link to={'/auth/sign-in'}><Button moreStyle={"reset-btn"}>Connecter</Button></Link>
                  </div>
              )
            }
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default VerifyEmail;
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { BiShowAlt } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
//component
import PopupVerify from 'components/popup/popupVerify/popupVerify.component';
import SearchBar from '../../searchBar/searchBar.component';
import Button from 'components/button/Button.component';
import UpdateRent from './../updateRent/updateRent.component';
import AddRent from './../addRent/addRent.component';
//utils
import { date } from 'utils/date/date.util';
//context
import { PopupContext } from 'contexts/popup.context';
import { CarsContext } from 'contexts/cars.context';
import { UserContext } from 'contexts/user.context';
//service
import { ContractStatus, deleteContract, getContractsAdmin, getStatus } from 'services/contract.service/contract.service';
//interface
import { ContractInterface, ContractStatusInterface } from 'services/contract.service/contract.interface';
//style
import './rentsManagment.scss';

const RentsManagment: FunctionComponent = () => {

  const { search, reset } = useContext(CarsContext);
  const { pushNext } = useContext(PopupContext);
  const { user } = useContext(UserContext);

  const [status, setStatus] = useState<Array<ContractStatusInterface>>([]);
  const [contracts, setContracts] = useState<Array<ContractInterface>>([]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [rowPerPage] = useState(10);
  const [contractsCount, setContractsCount] = useState(0);
  const [delayedSearch, setDelayedSearch] = useState<NodeJS.Timeout>();

  useEffect(() => {
    reset();
    getStatus()
      .then((result) => {
        setStatus(result);
      })
  }, [reset]);

  useEffect(() => {
    setPage(oldValue => {
      if (oldValue === 1)
        getData();
      return 1;
    });
  }, [search, name])

  const getData = useMemo(() => () => {
    getContractsAdmin(page, rowPerPage,
      search.car || undefined,
      search.model || undefined,
      search.brand || undefined,
      name).then((result) => {
        setContracts(result.contracts);
        setContractsCount(result.contractsCount)
      })
  }, [page, search, rowPerPage, name]);

  useEffect(() => {
    getData();
    return () => {
      if (delayedSearch) {
        clearTimeout(delayedSearch);
      }
    }
  }, [getData])

  const handleSearch = (value: string) => {
    if (delayedSearch) {
      clearTimeout(delayedSearch);
    }
    setDelayedSearch(setTimeout(() => {
      setName(value)
    }, 500));
  }

  const handleUpdate = () => {
    getContractsAdmin()
      .then((result) => {
        setContracts(result.contracts);
      })
  }

  const updateContract = (contract: ContractInterface, show: boolean = false) => {
    pushNext(<UpdateRent status={status} show={show} handle={handleUpdate} contractInput={contract} />)
  }

  const removeContract = (id: number) => {
    const handle = () => {
      deleteContract(id)
        .then(() => {
          getData();
        })
    }
    pushNext(<PopupVerify target='contat' handle={handle} type='delete' />)
  }

  const addRent = () => {
    pushNext(<AddRent handleContacts={() => {
      setPage(oldValue => {
        if (oldValue === 1)
          getData();
        return 1;
      });
    }} />);
  }

  const stopContract = (contract: ContractInterface) => {
    const statusSelected = status.find((status) => {
      return status.name === "Annulé";
    });
    if (statusSelected?.id)
      ContractStatus(contract.id, statusSelected.id)
        .then(() => {
          getContractsAdmin()
            .then((result) => {
              setContracts(result.contracts);
            })
        })
        .catch(() => { })
  }

  const pageCount = useMemo(() => {
    return Math.ceil(contractsCount / rowPerPage)
  }, [contractsCount, rowPerPage])

  return (
    <div className="rentsPage">
      <SearchBar
        text={true}
        add={{
          text: 'Ajouter un contrat',
          handle: addRent
        }}
        car={true}
        title='Liste des locations'
        reload={getData}
        handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Nom</th>
              <th>Matricule</th>
              <th>De</th>
              <th>à</th>
              <th>Status</th>
              <th>Montant</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              contracts.map((element) => {
                return (
                  <tr key={element.id} className='table-row'>
                    <td className='sticky-col'>{element.User.firstName} {element.User.lastName}</td>
                    <td>{element.Car.registrationNumber}</td>
                    <td>{date(element.startDate)}</td>
                    <td>{date(element.endDate)}</td>
                    <td className={
                      (() => {
                        switch (element.ContractStatus.name) {
                          case 'Approuvé':
                            return 'green';
                          case 'Rejeté':
                          case 'Annulé':
                            return 'red';
                          case 'En attente':
                            return 'yellow'
                          default: return 'black';
                        }
                      })()
                    }
                    >{element.ContractStatus.name}</td>
                    <td>{
                      element.paidAmount && element.paidAmount !== element.initialAmount ?
                        <span>
                          <del>{element.initialAmount}</del> {element.paidAmount}
                        </span> :
                        element.initialAmount
                    } TND</td>
                    <td className='action2'>

                      {
                        !['En cours', 'Annulé', 'Terminé'].includes(element.ContractStatus.name) ? (
                          <div className='action2'>
                            <Button
                              tooltip='Modifier la reservation'
                              moreStyle='action-btn'
                              handle={() => updateContract(element)}
                              size='rounded'
                              type='edit'><MdEdit />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              tooltip='Voir contrat'
                              moreStyle='action-btn'
                              handle={() => updateContract(element, true)}
                              size='rounded'
                              type='success'><BiShowAlt />
                            </Button>
                          </div>
                        )
                      }
                      {
                        element.ContractStatus.name === "En cours" ?
                          <div>
                            <Button
                              tooltip='Annuler contrat'
                              moreStyle='action-btn'
                              handle={() => stopContract(element)}
                              size='rounded'
                            ><IoIosClose />
                            </Button>
                          </div>
                          : ""
                      }
                      {
                        user.role_name !== "ROLE_SUPER_ADMIN" ? "" :
                          <Button
                            tooltip='Supprimer'
                            moreStyle='action-btn'
                            handle={() => removeContract(element.id)}
                            size='rounded'
                          ><AiFillDelete />
                          </Button>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {pageCount > 1 &&
          <div className='pagination-container'>
            <ReactPaginate
              className='pagination'
              breakLabel="..."
              nextLabel=">>"
              forcePage={page - 1}
              onPageChange={(event) => { setPage(event.selected + 1) }}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<<"
              renderOnZeroPageCount={null}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default RentsManagment;
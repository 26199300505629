import { defaultModel } from "services/model.service/model.object"
import { Car, CarInput, FuelInterface, GearBoxInterface } from "./car.interface"

export const defaultErrors = {
  registrationNumber: '',
  carColor: '',
  fuel: '',
  description: '',
  carGear: '',
  FuelId: '',
  GearBoxId: '',
  images: '',
  dayPrice: '',
  horsePower: '',
  carNbPlaces: '',
  carNbDoor: '',
  caution: '',
  ModelId: '',
  BrandId: '',
  carYear: '',
}

export const defaultCarInput: CarInput = {
  registrationNumber: '',
  carColor: '#000000',
  FuelId: 1,
  description: '',
  GearBoxId: 1,
  images: '',
  CarPrices: [],
  horsePower: undefined,
  carNbPlaces: undefined,
  carNbDoor: undefined,
  caution: undefined,
  ModelId: 0,
  carYear: new Date(),
}

const fuel: FuelInterface = {
  id: 0,
  name: ''
}

const carGear: GearBoxInterface = {
  id: 0,
  name: ''
}

export const defaultCar: Car = {
  id: 0,
  inService: true,
  CarStatusId: 0,
  CarStatus: {
    id: 0,
    name: "Disponible"
  },
  CarImages: [],
  Model: defaultModel,
  registrationNumber: '',
  carColor: '#000000',
  FuelId: 0,
  Fuel: fuel,
  description: '',
  GearBoxId: 0,
  GearBox: carGear,
  CarPrices: [],
  horsePower: 0,
  carNbPlaces: 0,
  carNbDoor: 0,
  caution: 0,
  ModelId: 0,
  images: '',
  carYear: new Date(),
}


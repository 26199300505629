import { FC, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsFillPersonCheckFill } from "react-icons/bs";
//component
import Button from "components/button/Button.component";
import Footer from "app/main/footer/footer.component";
import MainNavigation from "app/main/header/navigation.component";
//service
import { verifyNewEmail } from "services/auth.service/auth.service";
//context
import { UserContext } from 'contexts/user.context';
import { noIndexPage, setDescription, setTitle } from "utils/seo/seo.utils";


const UpdateEmail: FC = () => {

  useEffect(() => {
    noIndexPage();
    setTitle("Mise à Jour de l'Adresse E-mail | Slama rent a car");
    setDescription(`
    Mettez à jour votre adresse e-mail chez Slama rent a car en toute simplicité. 
    Suivez les étapes pour modifier votre adresse e-mail actuelle et assurez-vous de rester connecté à nos services en toute sécurité.
    `)
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const { user, setUser } = useContext(UserContext);
  const [result, setResult] = useState<boolean>();
  const [token] = useState<string | null>(useQuery().get('token'));
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      if (user.token) {
        verifyNewEmail(token)
          .then((res) => {
            setUser(oldValues => { return { ...oldValues, email: res.newEmail, newEmail: "" } })
            setResult(true);
            navigate("/dashboard/settings");
          })
          .catch((error) => {
            setResult(false);
          })
      } else {
        navigate(`/auth/sign-in?verifyEmail=${token}`);
      }
    } else {
      setTimeout(() => {
        navigate("/404");
      }, 1)
    }

  }, [token, user.token, navigate, setUser])

  return (
    <div className='auth'>
      <div className="forget-password">
        <MainNavigation />
        <div className='forget-password-content'>
          <div className='background'>
          </div>
          <div className='forget-password-container'>
            {
              (
                result ?
                  <div className="email-verified">
                    <div className="description">Votre e-mail est activé vous pouvez connectez<BsFillPersonCheckFill /></div>
                    <Link to={"/dashboard/settings"}><Button moreStyle={"reset-btn"}>Connecter</Button></Link>
                  </div> :
                  <div className="email-verified">
                    <div className="description">un problème de vérification email veuillez envoyer un autre email</div>
                    <Link to={"/dashboard/settings"}><Button moreStyle={"reset-btn"}>Connecter</Button></Link>
                  </div>
              )
            }


          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default UpdateEmail;
import axios from "axios";

export const Post = (api: string, data: any) => {
  return axios.post(
    generateUrl(api), data)
    .then(handleResult)
    .catch(handleError)
}

export const Get = (api: string , data ?: any) => {
  return axios.get(generateUrl(api),{params : data})
    .then(handleResult)
    .catch(handleError)
}

export const Put = (api: string, data: any) => {
  return axios.put(
    generateUrl(api), data)
    .then(handleResult)
    .catch(handleError)
}

export const Delete = (api: string) => {
  return axios.delete(
    generateUrl(api))
    .then(handleResult)
    .catch(handleError)
}

const handleError = (res: any) => {
  if(res.response)
    return Promise.reject(res.response.data);
  else{
    return Promise.reject(res.response);
  }
};

const handleResult = (res: any) => {
  return Promise.resolve(res.data);
};

const generateUrl = (url: string) => {
  return process.env.REACT_APP_API_URL + url;
}
import { FC, useState } from 'react';
//components
import Email from './email/email.component';
import NavItem from './navItem/navItem.component';
import Profile from './profile/profile.component';
import Security from './security/security.component';
//style
import './settings.scss';

const Settings: FC = () => {

  const [pageSelected, setPage] = useState('profile');

  const pageSelectHandler = (page: string) => {
    setPage(page);
  }

  return (
    <div className="settingsPage">
      <h1>Paramètre</h1>
      <nav className='settingsNav'>
        <NavItem select={pageSelected} handle={pageSelectHandler}>Profile</NavItem>
        <NavItem select={pageSelected} handle={pageSelectHandler}>E-mail</NavItem>
        <NavItem select={pageSelected} handle={pageSelectHandler}>Securité</NavItem>
      </nav>
      {
        (() => {
          switch (pageSelected) {
            case 'profile': return <Profile />;
            case 'securité': return <Security />;
            case 'e-mail': return <Email />;
          }
        })()
      }
    </div>
  )
}

export default Settings;
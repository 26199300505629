import { FC, useContext, useEffect, useState } from "react";
import { GiCarDoor, GiGasPump, GiGearStickPattern, GiPerson } from "react-icons/gi";
import { BsCheckCircle, BsPeopleFill } from "react-icons/bs";
import { Checkbox } from "@mui/material";
import { FaBaby } from "react-icons/fa";
//context
import { PopupContext } from "contexts/popup.context";
import { ContractContext } from "contexts/contract.context";
import { SearchContext } from "contexts/search.context";
//component
import SelectDateTime from "components/inputs/selectDateTime/selectDateTime.component";
import Button from "components/button/Button.component";
import ListInput from "components/inputs/selectList/list.component";
import PopupDone from "../popupDone/popupDone.component";
//interface
import { Car } from "services/car.service/car.interface";
import { ContractInterfaceInput, DefaultContractInterfaceInputsErrors } from "services/contract.service/contract.interface";
//object
import { defaultContractInputs, defaultContractInputsErrors } from "services/contract.service/contract.object";
//service
import { reservation } from "services/contract.service/contract.service";
//style
import './carDescription.scss';
import { UserContext } from "contexts/user.context";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { checkCarById } from "services/car.service/car.service";
import { getDaysInMonthInRange, isToday, isTomorrow, months } from "utils/date/date.util";


const url = process.env.REACT_APP_API_URL;
const separator = process.env.REACT_APP_SEPARATOR ?? '/';

interface CarDescriptionProps {
  car: Car
}

const CarDescription: FC<CarDescriptionProps> = ({ car }) => {

  const {
    startDate,
    endDate,
    reset
  } = useContext(SearchContext);
  const { setContent, closePopUp } = useContext(PopupContext);
  const { points, update } = useContext(ContractContext);
  const { user } = useContext(UserContext);
  const [cookies, setCookie, removeCookie] = useCookies(['reservation']);

  const navigator = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [duration, setDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [prices, setPrices] = useState(new Map<number, number>());
  const [stepper, setStepper] = useState<"0" | "1">("0");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [errors, setErrors] =
    useState<DefaultContractInterfaceInputsErrors>(defaultContractInputsErrors);
  const [formFields, setFormFields] = useState<ContractInterfaceInput>(cookies.reservation ? {
    ...cookies.reservation,
    startDate: cookies.reservation.startDate ? new Date(cookies.reservation.startDate) : null,
    endDate: cookies.reservation.startDate ? new Date(cookies.reservation.endDate) : null,
    CarId: car.id
  } : {
    ...defaultContractInputs,
    CarId: car.id
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  // save cookie
  useEffect(() => {
    var someDate = new Date();
    var numberOfDaysToAdd = 10;
    var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    setCookie("reservation", formFields, { expires: new Date(result) });
  }, [formFields, setCookie]);

  // calculate duration and end date
  useEffect(() => {
    let newDuration = 0;
    let total = 0;

    if (formFields.startDate && formFields.endDate) {

      let newEndDate: Date | null = null;
      if (formFields.endDate <= formFields.startDate) {
        newEndDate = new Date(
          new Date(formFields.startDate)
            .setDate(formFields.startDate.getDate() + 1)
        )
      }

      const start = formFields.startDate.getTime();
      const end = formFields.endDate.getTime();
      const Difference_In_Time = Math.abs(start - end);
      newDuration = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

      const dayParMonth = new Map<number, number>()

      for (let month = formFields.startDate.getMonth(); month <= formFields.endDate.getMonth(); month++) {
        const days = getDaysInMonthInRange(formFields.startDate, formFields.endDate, month)
        if (days) {
          const price = car.CarPrices.find(carPrice => carPrice.month === month + 1);
          let monthPrice = 0;
          if (price) {
            monthPrice = price.firstPrice;
            if (newDuration > 3 && newDuration < 9)
              monthPrice = price.secondPrice;
            else if (newDuration > 8 && newDuration < 21)
              monthPrice = price.thirdPrice;
            else if (newDuration > 20)
              monthPrice = price.fourthPrice;
            dayParMonth.set(month, monthPrice)
          }
          total += monthPrice * days;
        }
      }

      setPrices(dayParMonth);

      if (newEndDate) {
        setFormFields(oldValues => {
          return {
            ...oldValues,
            endDate: newEndDate
          }
        })
      }
    }
    setTotalPrice(total);
    setDuration(newDuration);
  }, [formFields.startDate, formFields.endDate, car.CarPrices])

  // check car validity
  useEffect(() => {
    if (formFields.startDate && formFields.endDate) {
      checkCarById(({
        idCar: car.id,
        startDate: formFields.startDate,
        endDate: formFields.endDate,
      })).then((_result) => {
        setErrors(oldValues => { return { ...oldValues, car: "" } })
      })
        .catch((error) => {
          setErrors(oldValues => { return { ...oldValues, car: error.errors.car } })
        });
    }
  }, [formFields.startDate, formFields.endDate, car.id])

  // get dates from search
  useEffect(() => {
    if (endDate && startDate) {
      setFormFields(oldValues => {
        return {
          ...oldValues,
          startDate: startDate,
          endDate: endDate,
        }
      })
    }
  }, [endDate, startDate])


  const handleForm = (event: any) => {
    const { name, value } = event;
    let hour;
    if (value)
      switch (name) {
        case "end":
        case "start":
          if (formFields.startDate) {
            hour = formFields.startDate.getHours();
            if ((isToday(formFields.startDate) || isToday(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else if (formFields.endDate) {
            hour = formFields.endDate.getHours();
            if ((isTomorrow(formFields.endDate) || isTomorrow(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else {
            const date1 = new Date(new Date(value).setHours(value.getHours()));
            const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
            if (
              ((name === "start" && isToday(value))
                || (name === "end" && isTomorrow(value)))
              && date1 < date2
            )
              hour = value.getHours() + 1;
            else {
              hour = value.getHours();
            }
          }

          name === "end" ?
            setFormFields({
              ...formFields,
              startDate: formFields.startDate ? new Date(formFields.startDate.setHours(hour, 0, 0)) : null,
              endDate: new Date(value.setHours(hour, 0, 0))
            })
            : setFormFields({
              ...formFields,
              endDate: formFields.endDate ? new Date(formFields.endDate.setHours(hour, 0, 0)) : null,
              startDate: new Date(value.setHours(hour, 0, 0))
            });
          break;
        case "hour":
          hour = value.getHours();
          if (formFields.endDate && formFields.startDate) {
            setFormFields({
              ...formFields,
              endDate: new Date(formFields.endDate.setHours(hour, 0, 0)),
              startDate: new Date(formFields.startDate.setHours(hour, 0, 0))
            })
          } else if (formFields.endDate) {
            setFormFields({
              ...formFields,
              endDate: new Date(formFields.endDate.setHours(hour, 0, 0))
            })
          } else if (formFields.startDate) {
            setFormFields({
              ...formFields,
              startDate: new Date(formFields.startDate.setHours(hour, 0, 0))
            })
          }
          break;
        default:
          setFormFields({ ...formFields, [name]: value });
          if (name === "RetreivePointId")
            setErrors(oldValues => { return { ...oldValues, RetreivePointId: "" } })
          break;
      }
  }

  const handleCheck = (event: any) => {
    const { name, checked } = event.target;
    setFormFields({ ...formFields, [name]: checked });
  }

  const reserve = (event: any) => {
    event.preventDefault();
    setErrors(defaultContractInputsErrors)
    if (user.token) {
      setLoading(true)
      reservation(formFields)
        .then((result) => {
          removeCookie("reservation");
          reset();
          update();
          setContent(
            <PopupDone
              message="Voiture reservé veuillez attendre la confirmation de l'agence"
            />);
        })
        .catch((error) => {
          setErrors({
            ...defaultContractInputsErrors,
            ...error.errors
          })
          if (error?.errors?.permis) {
            closePopUp();
            navigator(`/dashboard/settings?permis=none&reservation=${car.id}`)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      closePopUp();
      navigator(`/auth/sign-in?reservation=${car.id}`);
    }
  }

  return (
    <div className="carDescription">
      <div className="carDescriptionHeader">
        <div className="gap"></div>
        <img className="carImage" src={url + "/uploads/" + car.CarImages[0]?.imagePath} alt="" />
        {
          stepper === "1" && width < 700 ?
            "" :
            <div className="carTechniq">
              <h4>ÉQUIPEMENTS</h4>
              <div className="equipments">
                {
                  car.description.split(separator)
                    .map((element, index) => {
                      return (
                        <div
                          className="equipmentItem"
                          key={index}>
                          <div>

                            <BsCheckCircle className="icon" />
                          </div>
                          <span>{element}</span>
                        </div>
                      )
                    }
                    )
                }
              </div>
            </div>
        }
        <h2 className="carName">
          <img className="brandLogo" src={url + "/uploads/" + car.Model.Brand.logoImg} alt={car.Model.Brand.name} />
          {car.Model.name}</h2>
      </div>
      {
        stepper === "1" && width < 700 ?
          "" :
          <div className="carDescription-mobile D700">
            <div className='carDescription-2'>
              <div><GiGearStickPattern />{car.GearBox.name}</div>
              <div><GiCarDoor />{`${car.carNbDoor} Portes`}</div>
              <div><GiGasPump />{car.Fuel.name}</div>
              <div><GiPerson />{`${car.carNbPlaces} Places`}</div>
            </div>
          </div>
      }
      {
        width > 700 ?
          <div className="formContainer">
            <div className='carDescription-2'>
              <div><GiGearStickPattern />{car.GearBox.name}</div>
              <div><GiCarDoor />{`${car.carNbDoor} Portes`}</div>
              <div><GiGasPump />{car.Fuel.name}</div>
              <div><GiPerson />{`${car.carNbPlaces} Places`}</div>
            </div>
            <form onSubmit={reserve}>
              <div className="form">
                <ListInput
                  error={errors.RetreivePointId}
                  value={formFields.RetreivePointId}
                  name="RetreivePointId"
                  id="point"
                  handle={handleForm}
                  placeholder={'Point de retrait'}
                  list={points} />

                <div className="inputField resum">
                  <ul>
                    <li>Caution : {car.caution} TND</li>
                    <li>Coût de la location : {duration ? "" : <span className="TND">__ TND TTC/jour</span>}</li>
                    <li className="remarq">* Le Prix de la location varie selon la durée</li>
                    {duration ? Array.from(prices.entries()).map(([month, price]) =>
                      <li className="price-item" key={"month" + month}>{months[month] + " " + price} <span className="TND">TND TTC/jour</span></li>
                    ) : ""}
                    <li>Durée : {duration} jours</li>
                  </ul>

                </div>
                <SelectDateTime
                  error={errors.car ? true : false}
                  id="startDateReservation"
                  admin={false}
                  handle={handleForm} defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="start" />

                <div id="baby" className="inputField checkBox">
                  <div className="checkBoxLabel">
                    <FaBaby className="icon" />
                    <span>Siège bébé</span>
                    <span>0 TND TTC/jour</span>
                  </div>
                  <Checkbox
                    checked={formFields.seatBebe || false}
                    className="checkbox"
                    onChange={handleCheck}
                    name="seatBebe" />
                </div>

                <SelectDateTime
                  error={errors.car ? true : false}
                  id="endDateReservation"
                  admin={false}
                  handle={handleForm} defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="end" />

                <div id="secondDriver" className="inputField checkBox">
                  <div className="checkBoxLabel">
                    <BsPeopleFill className="icon" />
                    <span>2éme conducteur</span>
                    <span>0 TND TTC/jour</span>
                  </div>
                  <Checkbox
                    checked={formFields.secondDriver || false}
                    className="checkbox"
                    onChange={handleCheck}
                    name="secondDriver" />
                </div>
                <div className="container-btn">
                  <p className="total">Prix Total : {totalPrice} TND</p>
                  <Button
                    disabled={errors.car ? true : false}
                    loading={loading}
                    moreStyle={"reserve-btn"}
                    submit={true}>
                    {errors.car ? errors.car : "Reserver"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
          : <div>
            {
              stepper === "0" ?
                <Button handle={() => setStepper("1")}>Suivant</Button>
                :
                <div className="formContainer">
                  <form onSubmit={reserve}>
                    <div className="form">
                      <ListInput
                        error={errors.RetreivePointId}
                        value={formFields.RetreivePointId}
                        name="RetreivePointId"
                        id="point"
                        handle={handleForm}
                        placeholder={'Point de retrait'}
                        list={points} />

                      <div className="inputField resum">
                        <ul>
                          <li>Caution : {car.caution} TND</li>
                          <li>Coût de la location : {duration ? "" : <span className="TND">__ TND TTC/jour</span>}</li>
                          <li className="remarq">* Le Prix de la location varie selon la durée</li>
                          {duration ? Array.from(prices.entries()).map(([month, price]) =>
                            <li className="price-item" key={"month" + month}>{months[month] + " " + price} <span className="TND">TND TTC/jour</span></li>
                          ) : ""}
                          <li>Durée : {duration} jours</li>
                        </ul>
                        <span className="total">Prix Total : {totalPrice} TND</span>
                      </div>
                      <SelectDateTime
                        error={errors.car ? true : false}
                        id="startDateReservation"
                        admin={false}
                        handle={handleForm} defaultForm={{
                          start: formFields.startDate,
                          end: formFields.endDate
                        }} type="start" />

                      <div id="baby" className="inputField checkBox">
                        <div className="checkBoxLabel">
                          <FaBaby className="icon" />
                          <span>Siège bébé</span>
                          <span>0 TND TTC/jour</span>
                        </div>
                        <Checkbox
                          checked={formFields.seatBebe || false}
                          className="checkbox"
                          onChange={handleCheck}
                          name="seatBebe" />
                      </div>

                      <SelectDateTime
                        error={errors.car ? true : false}
                        id="endDateReservation"
                        admin={false}
                        handle={handleForm} defaultForm={{
                          start: formFields.startDate,
                          end: formFields.endDate
                        }} type="end" />

                      <div id="secondDriver" className="inputField checkBox">
                        <div className="checkBoxLabel">
                          <BsPeopleFill className="icon" />
                          <span>2éme conducteur</span>
                          <span>0 TND TTC/jour</span>
                        </div>
                        <Checkbox
                          checked={formFields.secondDriver || false}
                          className="checkbox"
                          onChange={handleCheck}
                          name="secondDriver" />
                      </div>
                      <Button
                        disabled={errors.car ? true : false}
                        loading={loading}
                        moreStyle={"reserve-btn"}
                        submit={true}>
                        {errors.car ? errors.car : "Reserver"}
                      </Button>
                    </div>
                  </form>
                </div>
            }
          </div>
      }

    </div>
  )
}

export default CarDescription;
import { FC, useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
//component
import SelectDateTime from "components/inputs/selectDateTime/selectDateTime.component";
import { CarsContext } from "contexts/cars.context";
//interface
import { Car, FilterInterface } from "services/car.service/car.interface";
//service
import { getAvailabilityCars } from "services/car.service/car.service";
//style
import './availability.scss';

const carStatus = {
  "Disponible": "black",
  "Loué": "green",
  "Reservé": "yellow",
  "Hors service": "red"
}

const Availability: FC = () => {

  const { cars } = useContext(CarsContext);
  const [carsState, setCarsState] = useState<Array<Car>>([]);
  const [formFields, setFormFields] = useState<FilterInterface>({
    startDate: null,
    endDate: null,
    idGear: 0,
    idFuel: 0,
    nbDoor: 0,
    nbPlaces: 0
  });

  useEffect(() => {
    getAvailabilityCars(formFields)
      .then((result) => {
        setCarsState(cars.filter((car) => {
          return result.find((element: any) => {
            return car.id === element.id;
          })
        }));
      })
      .catch(() => {
        setCarsState([]);
      })
  }, [cars, formFields]);

  useEffect(() => {
    if (formFields.startDate && formFields.endDate) {
      if (formFields.endDate <= formFields.startDate) {
        setFormFields(oldValues => {
          return {
            ...oldValues,
            endDate: new Date(
              new Date(formFields.startDate!!)
                .setDate(formFields.startDate!!.getDate() + 1)
            )
          }
        })

      }
    }
  }, [formFields.startDate, formFields.endDate])

  const handleForm = (event: any) => {
    const { name, value } = event;
    let hour;
    if (value) {
      switch (name) {
        case "end":
        case "start":
          if (formFields.startDate) {
            hour = formFields.startDate.getHours();
          } else if (formFields.endDate) {
            hour = formFields.endDate.getHours();
          } else {
            hour = value.getHours();
          }

          name === "end" ?
            setFormFields({
              ...formFields,
              startDate: formFields.startDate ? new Date(formFields.startDate.setHours(hour, 0, 0)) : null,
              endDate: new Date(value.setHours(hour, 0, 0))
            })
            : setFormFields({
              ...formFields,
              startDate: new Date(value.setHours(hour, 0, 0)),
              endDate: formFields.endDate ? new Date(formFields.endDate.setHours(hour, 0, 0)) : null
            });
          break;
        case "hour":
          hour = value.getHours();
          if (formFields.endDate && formFields.startDate) {
            setFormFields({
              ...formFields,
              endDate: new Date(formFields.endDate.setHours(hour, 0, 0)),
              startDate: new Date(formFields.startDate.setHours(hour, 0, 0))
            });
          } else if (formFields.endDate) {
            setFormFields({
              ...formFields,
              endDate: new Date(formFields.endDate.setHours(hour, 0, 0))
            });
          } else if (formFields.startDate) {
            setFormFields({
              ...formFields,
              startDate: new Date(formFields.startDate.setHours(hour, 0, 0))
            });
          }
          break;
      }
    }

  }

  return (
    <div className="availablity">
      <h1 className="title">Voiture Disponibilité</h1>
      <ReactTooltip place="bottom" type="dark" effect="solid" />
      <div className='search-bar'>
        <div className="search-container">
          <div className='inputContainer'>
            <SelectDateTime
              admin={true}
              handle={handleForm}
              defaultForm={{
                start: formFields.startDate,
                end: formFields.endDate
              }} type="start" />
          </div>
          <div className='inputContainer'>
            <SelectDateTime
              admin={true}
              handle={handleForm}
              defaultForm={{
                start: formFields.startDate,
                end: formFields.endDate
              }} type="end" />
          </div>
        </div>
      </div>
      <div className='line'></div>
      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Matricule</th>
              <th>Marque</th>
              <th>Model</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              carsState.map((element) => {
                return (
                  <tr key={element.id} className='table-row'>
                    <td className='sticky-col'>{element.registrationNumber}</td>
                    <td>{element.Model.Brand.name}</td>
                    <td>{element.Model.name}</td>
                    <td className={carStatus[element.CarStatus.name]}>
                      {element.CarStatus.name}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Availability;
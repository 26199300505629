import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { BsToggle2Off, BsToggle2On } from "react-icons/bs";
import { IoAddCircleSharp } from "react-icons/io5";
import frLocale from 'date-fns/locale/fr';
//context
import { AddCarContext } from "contexts/addCar.context";
import { CarsContext } from "contexts/cars.context";
import { PopupContext } from "contexts/popup.context";
//interface
import { CarInput } from "services/car.service/car.interface";
//component
import Button from "components/button/Button.component";
import ListInput from "components/inputs/selectList/list.component";
import ImageInput from "components/inputs/selectImage/image.component";
import TextInput from "components/inputs/textInput/text.component";
import AddBrand from '../../manageBrans/addBrand/addBrand.component';
import AddModel from "../../manageModels/addModel/addModel.component";
//style
import './carFiels.scss';
import { months } from "utils/date/date.util";

const URL_BACK = process.env.REACT_APP_API_URL;

interface CarFieldsProps {
  loading: boolean
  formFields: CarInput,
  errors: any,
  type: "add" | "update",
  carStatus?: string,
  image: File | null,
  handleSubmit: (event: any) => void,
  handleForm: (event: any) => void,
  handlePrice: (month: number, name: "firstPrice" | "secondPrice" | "thirdPrice" | "fourthPrice", price: number) => void,
  handleStatus?: () => void,
}

const CarFields: FC<CarFieldsProps> = ({
  handleSubmit,
  handleForm,
  handleStatus,
  handlePrice,
  loading,
  formFields,
  errors,
  type,
  carStatus,
  image }) => {

  const { brands, models, fuel, carGear } = useContext(CarsContext);
  const { brand, setCar, setBrand, setImg } = useContext(AddCarContext);
  const { pushNext } = useContext(PopupContext);

  const [stepper, setStepper] = useState<0 | 1 | 2>(0);
  const [date, setDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (formFields.ModelId) {
      setStepper(2);
    }
    else if (brand) setStepper(1);
    else setStepper(0);
  }, [formFields, brand]);

  const selectBrand = (event: any) => {
    setBrand(event.value);
    handleForm({
      name: "ModelId",
      value: 0
    });
  }

  const filterModels = useMemo(() => (id: number) => {
    return models.filter((element) => {
      return element.BrandId == id;
    })
  }, [models])

  const addBrandOrModel = (type: string) => {
    setCar(formFields);
    setImg(image);
    switch (type) {
      case "brand":
        pushNext(<AddBrand type="add" />);
        break;
      case "model":
        pushNext(<AddModel type="add" idBrand={brand} />)
        break;
    }
  }

  return (
    <form className="carFields" onSubmit={handleSubmit}>
      <div className="addBrandModel">
        <ListInput
          prefix="B"
          handle={selectBrand}
          name="brand"
          list={brands}
          value={brand ? brand : 0}
          label="Marque"
          placeholder="--Marque--"
        />
        <IoAddCircleSharp
          onClick={() => addBrandOrModel("brand")}
          className="add" />
      </div>
      {stepper > 0 &&
        <div className="addBrandModel">
          <ListInput
            prefix="M"
            handle={handleForm}
            name="ModelId"
            list={brand ? filterModels(brand) : models}
            value={formFields.ModelId}
            label="Modele"
            placeholder="--Modele--"
          />
          <IoAddCircleSharp
            onClick={() => addBrandOrModel("model")}
            className="add" />
        </div>
      }
      {stepper > 1 &&
        <div>
          <TextInput
            label="Matricule"
            error={errors.registrationNumber}
            option={{
              name: "registrationNumber",
              placeholder: 'Matricule',
              value: formFields.registrationNumber || ''
            }}
            handle={handleForm} />

          <div className="colorCar">
            <label
              className="label"
              htmlFor="colorCar">
              Couleur
            </label>
            <div className="colorCarInput">
              <input
                value={formFields.carColor}
                onChange={(event) => { handleForm(event.target); }}
                name="carColor"
                type="color"
                className="input" />
              {formFields.carColor}
            </div>
          </div>

          <TextInput
            label="Nombre de places"
            error={errors.carNbPlaces}

            option={{
              name: 'carNbPlaces',
              placeholder: 'Places',
              type: "number",
              value: formFields.carNbPlaces || ''
            }}
            handle={handleForm}
          />

          <TextInput
            label="Nombre de porte"
            error={errors.carNbDoor}
            option={{
              name: 'carNbDoor',
              placeholder: 'Portes',
              type: "number",
              value: formFields.carNbDoor || ''
            }}
            handle={handleForm} />

          <TextInput
            error={errors.horsePower}
            label="Puissance"
            option={{
              name: "horsePower",
              placeholder: '000',
              type: "number",
              value: formFields.horsePower || ''
            }}
            handle={handleForm} />
          {
            months.map((month, index) => {
              return (
                <div key={index}>
                  <p className="label">Prix {month}</p>
                  <div className="prices">
                    <TextInput
                      label="1-3j"
                      error={errors["CarPrices." + index + ".firstPrice"]}
                      option={{
                        name: "firstPrice",
                        placeholder: '000',
                        type: "number",
                        step: "1",
                        value: formFields.CarPrices.find(price => price.month === index + 1)?.firstPrice || ''
                      }}
                      handle={(event) => { handlePrice(index + 1, "firstPrice", event.value) }} />

                    <TextInput
                      label="4-8j"
                      error={errors["CarPrices." + index + ".secondPrice"]}
                      option={{
                        name: "secondPrice",
                        placeholder: '000',
                        type: "number",
                        step: "1",
                        value: formFields.CarPrices.find(price => price.month === index + 1)?.secondPrice || ''
                      }}
                      handle={(event) => { handlePrice(index + 1, "secondPrice", event.value) }} />

                    <TextInput
                      label="9-20j"
                      error={errors["CarPrices." + index + ".thirdPrice"]}
                      option={{
                        name: "thirdPrice",
                        placeholder: '000',
                        type: "number",
                        step: "1",
                        value: formFields.CarPrices.find(price => price.month === index + 1)?.thirdPrice || ''
                      }}
                      handle={(event) => { handlePrice(index + 1, "thirdPrice", event.value) }} />

                    <TextInput
                      label="20j+"
                      error={errors["CarPrices." + index + ".fourthPrice"]}
                      option={{
                        name: "fourthPrice",
                        placeholder: '000',
                        type: "number",
                        step: "1",
                        value: formFields.CarPrices.find(price => price.month === index + 1)?.fourthPrice || ''
                      }}
                      handle={(event) => { handlePrice(index + 1, "fourthPrice", event.value) }} />
                  </div>
                </div>
              )
            })
          }
          <TextInput
            label="Caution"
            error={errors.caution}
            option={{
              name: "caution",
              placeholder: '000',
              type: "number",
              value: formFields.caution || ''
            }}
            handle={handleForm} />

          <TextInput
            error={errors.description}
            label="Description"
            option={{
              name: "description",
              placeholder: 'Equipement 1/Equipement 2...',
              value: formFields.description || ''
            }}
            handle={handleForm} />

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            inline={true}
            locale={frLocale}>
            <DatePicker
              label={"Date"}
              okText={"ok"}
              cancelText={"Annuler"}
              views={["year"]}
              maxDate={new Date()}
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
                handleForm({ name: 'carYear', value: newValue })
              }}
              renderInput={({ inputRef, inputProps, InputProps }) =>
                <TextInput
                  moreStyle='date-picker'
                  label={"Année de fabrication "}
                  icon={InputProps?.endAdornment}
                  handle={handleForm}
                  option={{
                    name: 'carYear',
                    ref: inputRef, ...inputProps,
                  }} />
              }
            />
          </LocalizationProvider>
          <div className="radioSelections">
            <div className="radioSelectContainer">
              <label className="label">Boite vitesse</label>
              <div className="radioSelect">
                {
                  carGear.map((element) => {
                    return <div className="radioList" key={"CarGear" + element.id}>
                      <input
                        className="radio"
                        onChange={(event) => { handleForm(event.target); }}
                        type="radio"
                        id={"gear" + element.id.toString()}
                        name="GearBoxId"
                        value={element.id}
                        checked={formFields.GearBoxId === element.id}
                      />
                      <label htmlFor={"gear" + element.id.toString()}>{element.name}</label>
                    </div>
                  })
                }
              </div>
            </div>

            <div className="radioSelectContainer">
              <label className="label">Carburant</label>
              <div className="radioSelect">
                {

                  fuel.map((element) => {
                    return <div className="radioList" key={"Fuel" + element.id}>
                      <input
                        className="radio"
                        onChange={(event) => { handleForm(event.target); }}
                        type="radio"
                        id={"fuel" + element.id.toString()}
                        name="FuelId"
                        value={element.id}
                        checked={formFields.FuelId === element.id}
                      />
                      <label htmlFor={"fuel" + element.id.toString()}>{element.name}</label>
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          <div className="imageDisplayContainer">
            {
              image ?
                <img className="imageDisplay" src={URL.createObjectURL(image)} alt="" />
                : (type === "update" && <img className="imageDisplay" src={URL_BACK + "/uploads/" + formFields.images} alt="" />)
            }
          </div>

          <ImageInput error={errors.images} handle={handleForm} />

          {
            errors.CarPrices && <div className="error">
              {errors.CarPrices}
            </div>
          }
          {
            type === "add" ?
              <div className="addCar-btn">
                <Button loading={loading} submit={true}>Ajouter</Button>
              </div>
              :
              <div className="upDateCar-btn">
                {
                  (() => {
                    if (handleStatus && carStatus)
                      if (carStatus === 'Hors service')
                        return <Button
                          tooltip="Disactiver"
                          handle={handleStatus}
                          moreStyle='action-btn'
                          size='rounded' >
                          <BsToggle2On />
                        </Button>
                      else if (['Disponible', 'Reservé'].includes(carStatus))
                        return <Button
                          tooltip="Activer"
                          handle={handleStatus}
                          moreStyle='action-btn'
                          size='rounded'
                          type='success'>
                          <BsToggle2Off />
                        </Button>
                  })()
                }
                <Button loading={loading} submit={true}>Modifier</Button>
              </div>
          }


        </div>
      }
    </form>
  );
}

export default CarFields;
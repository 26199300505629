import { newPasswordInputs, signInInputs, signUpInputs, signUpInputsErrors } from "./auth.interface"

export const defaultSignUp: signUpInputs = {
  email: '',
  firstName: '',
  lastName: '',
  num_tel: '',
  cin: '',
  birthDate: null,
  password: '',
  permis: '',
  permisExpDate: null
}

export const defaultSignUpErrors: signUpInputsErrors = {
  email: '',
  firstName: '',
  lastName: '',
  num_tel: '',
  cin: '',
  birthDate: '',
  password: '',
  permis: '',
  permisExpDate: ''
}

export const defaultSignIn: signInInputs = {
  email: '',
  password: '',
}

export const defaultNewPassword: newPasswordInputs = {
  token: '',
  password: '',
  confirmPassword: '',
}

export const defaultUpdatePasswordInputs = {
  actual_password: '',
  new_password: '',
  confirm_password: '',
}

export const defaultUpdateProfileInputs = {
  firstName: '',
  lastName: '',
  num_tel: '',
  cin: '',
  birthDate: '',
  permis: '',
  permisExpDate: ''
}
import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "components/inputs/textInput/text.component";
import PhoneInput from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';
import frLocale from 'date-fns/locale/fr';
import { useCookies } from "react-cookie";
//component
import Button from "components/button/Button.component";
//interface
import { AddContractInterface } from "services/contract.service/contract.interface";
import { clientInput } from "services/user.service/user.interface";
//other
import { defaultClient, defaultClientErrors } from "services/user.service/user.object";
import { addClient } from "services/user.service/user.service";
import { PopupContext } from "contexts/popup.context";
//style
import './addClient.scss';

interface AddClientInterface {
  form: AddContractInterface
}

const AddClient: FC<AddClientInterface> = ({ form }) => {

  const [cookies, setCookie] = useCookies(['addContract']);

  const [formFields, setFormFields] = useState<clientInput>(defaultClient);
  const { popNext } = useContext(PopupContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(defaultClientErrors);

  useEffect(() => {
    setCookie('addContract', form, { expires: new Date(expiredDate()) });
    return (() => {
      setLoading(false)
    })
  }, [form, setCookie])


  const handleForm = (event: any) => {
    const { name, value } = event;
    if (name === "email") {
      if (value === "") {
        setFormFields({ ...formFields, email: null });
        return;
      }
    }
    setFormFields({ ...formFields, [name]: value });
  }

  const handleNumber = (tel: any) => {
    setFormFields({ ...formFields, num_tel: tel })
    setError({ ...error, num_tel: "" })
  }

  const calculateMaxDate = (duration: number = 0): Date => {
    return new Date(new Date().setFullYear(new Date().getFullYear() + duration));
  }

  const submit = (event: FormEvent) => {
    event.preventDefault();
    setError(defaultClientErrors);
    setLoading(true)
    addClient(formFields)
      .then((result) => {
        const fullName = result.cin + " - " + result.firstName + " " + result.lastName;
        setCookie('addContract',
          { ...cookies.addContract, UserId: result.idClient, Search: fullName.toLowerCase() },
          { expires: new Date(expiredDate()) });
        popNext();
      })
      .catch((errors) => {
        setError({ ...defaultClientErrors, ...errors.errors })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const expiredDate = () => {
    var someDate = new Date();
    var numberOfDaysToAdd = 10;
    return someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
  }

  return (
    <div className="add-client">
      <h1>
        Ajouter un client
      </h1>
      <form className="form" onSubmit={submit}>

        <TextInput
          label="E-mail non obligatoire"
          option={{
            name: "email",
            type: "email",
            placeholder: "Adresse e-mail"
          }}
          error={error.email}
          handle={handleForm} />
        <div className="gap"></div>

        <TextInput
          option={{
            name: "cin",
            type: "text",
            placeholder: "Carte d'identité ou passeport"
          }}
          error={error.cin}
          handle={handleForm} />
        <div className="gap"></div>
        <div className="permis">
          <TextInput
            moreStyle="permis-input"
            option={{
              name: "permis",
              type: "text",
              placeholder: "Permis (00/000000)"
            }}
            error={error.permis}
            handle={handleForm} />
          <div className="inputComponent permis-expired">
            <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                inputFormat='dd/MM/yyyy'
                maxDate={new Date()}
                minDate={calculateMaxDate(-90)}
                views={['year', 'month', 'day']}
                openTo="year"
                value={formFields.permisExpDate}
                onChange={(newValue) => {
                  handleForm({ name: 'permisExpDate', value: newValue })
                }}
                renderInput={(params) =>
                  <TextField
                    className='date'
                    {...params}
                    inputProps={
                      {
                        ...params.inputProps,
                        placeholder: "délivrance"
                      }
                    } />
                }
              />
            </LocalizationProvider>
            {error.permisExpDate?.length ?
              <div className="input-error">{error.permisExpDate}</div>
              : null}
          </div>
        </div>
        <div className="gap"></div>

        <div className="nameInput">
          <TextInput
            option={{
              name: "firstName",
              type: "text",
              placeholder: "Nom"
            }}
            error={error.firstName}
            handle={handleForm} />
          <TextInput
            option={{
              name: "lastName",
              type: "text",
              placeholder: "Prénom"
            }}
            error={error.lastName}
            handle={handleForm} />
        </div>
        <div className="gap"></div>

        <div className="inputComponent">
          <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              inputFormat='dd/MM/yyyy'
              maxDate={calculateMaxDate(-18)}
              views={['year', 'month', 'day']}
              openTo="year"
              value={formFields.birthDate}
              onChange={(newValue) => {
                handleForm({ name: 'birthDate', value: newValue })
              }}
              renderInput={(params) =>
                <TextField
                  className='date'
                  {...params}
                  inputProps={
                    {
                      ...params.inputProps,
                      placeholder: "Date naissance (jj/mm/aaaa)"
                    }
                  } />
              }
            />
          </LocalizationProvider>
          {error.birthDate?.length ?
            <div className="input-error">{error.birthDate}</div>
            : null}
        </div>
        <div className="gap"></div>

        <div className="inputComponent">
          <div className="input">
            <PhoneInput
              labels={fr}
              country="TN"
              placeholder="Numéro téléphone"
              onChange={handleNumber} />
          </div>
          {error.num_tel?.length ?
            <div className="input-error">{error.num_tel}</div>
            : null}
        </div>
        <div className="gap"></div>

        <Button loading={loading} submit={true} moreStyle="auth-btn">Enregistrer</Button>
      </form>
    </div>
  );
}

export default AddClient;
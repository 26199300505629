import { FC, useContext, useEffect, useState } from 'react';
import { FaBan } from 'react-icons/fa';
import { TiUserDelete } from 'react-icons/ti';
//context
import { PopupContext } from 'contexts/popup.context';
//component
import Button from 'components/button/Button.component';
import SearchBar from '../searchBar/searchBar.component';
import NewOrOldEmployer from './addEmployer/newOrOld.component';
//service
import { blockUser, getEmployers } from 'services/user.service/user.service';
import { employerStatus } from 'services/employer.service/employer.service';
import { UserInterface } from 'services/user.service/user.interface';
//utils
import { date } from 'utils/date/date.util';
//style
import './employers.scss';
import PopupVerify from 'components/popup/popupVerify/popupVerify.component';


const Employers: FC = () => {

  const { pushNext } = useContext(PopupContext);

  const [search, setSearch] = useState('');
  const [employers, setEmployers] = useState<Array<UserInterface>>([]);
  const [employersFiltered, setEmployersFiltered] = useState<Array<UserInterface>>([]);

  const reset = () => {
    getEmployers()
      .then((result) => {
        setEmployers(result);
        setEmployersFiltered(result);
      })
      .catch((error) => {
        setEmployers([]);
      })
  }

  useEffect(() => {
    reset();
  }, [])

  useEffect(() => {
    if (search === '') {
      setEmployersFiltered(employers);
      return;
    }
    setEmployersFiltered(
      employers.filter(
        (user: any) => {
          let fullName = '';
          Object.keys(user).forEach((key) => {
            fullName += user[key];
          })

          return (fullName.toLowerCase()).includes(search.replace(/\s/g, '').toLowerCase());
        })
    );
  }, [employers, search])

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const handleAdd = () => {
    reset()
  }

  const addEmployer = () => {
    pushNext(<NewOrOldEmployer handleAdd={handleAdd} />);
  }

  const removeEmployer = (id: number, name: string) => {
    const handle = () => {
      employerStatus(id, false)
        .then((result) => {
          reset();
        })
        .catch((error) => {
          //error
        })
    }
    pushNext(<PopupVerify handle={handle} target={"le rôle administrateur " + name} type='delete' />);
  }

  const banEmployer = (id: number, name: string) => {
    const handle = () => {
      blockUser(id, false)
        .then((res) => {
          reset()
        })
        .catch((error) => {
          //error
        })
    }
    const messages = [
      "En cas de blocage,",
      `${name} ne peut pas ouvrier son compter une autrefois.`,
      "Sauf si vous supprimez l'action dans la liste blocage dans le dashboard."
    ]
    pushNext(<PopupVerify handle={handle} message={messages} target={name} type='ban' />);
  }

  return (
    <div className="employersPage">
      <SearchBar
        text={true}
        add={{
          text: "Ajouter un employeur",
          handle: addEmployer
        }}
        title='Liste des admins'
        handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Nom</th>
              <th>Identité</th>
              <th>Tél</th>
              <th>E-mail</th>
              <th>Date néssence</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              employersFiltered.map((element) => {
                return (
                  <tr key={element.id} className='table-row'>
                    <td className='sticky-col'>{element.firstName} {element.lastName}</td>
                    <td>{element.cin}</td>
                    <td>{element.num_tel}</td>
                    <td>{element.email}</td>
                    <td>{date(element.birthDate, false)}</td>
                    <td className='action2'>
                      <Button
                        handle={() => removeEmployer(element.id, element.firstName + " " + element.lastName)}
                        moreStyle='action-btn'
                        tooltip='Supprimer admin'
                        size='rounded'><TiUserDelete /></Button>
                      <Button
                        handle={() => banEmployer(element.id, element.firstName + " " + element.lastName)}
                        moreStyle='action-btn'
                        tooltip='Blocage'
                        size='rounded'><FaBan /></Button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Employers;
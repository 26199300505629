import { FC, useContext, useMemo, useState } from "react";
import { BiSearchAlt } from 'react-icons/bi';
//component
import TextInput from "components/inputs/textInput/text.component";
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
//context
import { PopupContext } from "contexts/popup.context";
//service
import { getUsers } from "services/user.service/user.service";
import { employerStatus } from "services/employer.service/employer.service";
//style
import './oldAccount.scss';

interface OldAccountProps {
  handleAdd: () => void
}

const OldAccount: FC<OldAccountProps> = ({ handleAdd }) => {

  const { closePopUp, pushNext } = useContext(PopupContext);

  const [users, setUsers] = useState<Array<any>>([]);
  const [delayedSearch, setDelayedSearch] = useState<NodeJS.Timeout>();

  const getData = useMemo(() => (search: string) => {
    getUsers(1, 5, search, "ROLE_USER")
      .then((result) => {
        setUsers(result.users);
      })
      .catch(() => {
        setUsers([]);
      })
  }, []);

  const addEmployer = useMemo(() => (user: any) => {
    const handle = () => {
      employerStatus(user.id, true)
        .then(() => {
          handleAdd();
          closePopUp();
        })
        .catch(() => {
        })
    }
    pushNext(<PopupVerify handle={handle} target={user.firstName + " " + user.lastName} type='add-admin' />);
  }, [handleAdd, closePopUp, pushNext])

  const handle = ({ value }: any) => {
    if (delayedSearch) {
      clearTimeout(delayedSearch);
      setDelayedSearch(undefined);
    }
    setDelayedSearch(setTimeout(() => {
      if (value)
        getData(value);
      else setUsers([]);
    }, 500))
  }

  return (
    <div className="oldAccount">
      <h1>Selectionner un compte</h1>
      <TextInput
        handle={handle}
        label="Nom du compte"
        option={{
          name: "serach",
          placeholder: "Nom",
          autoComplete: "off"
        }
        }
      />
      {
        users.length !== 0 ?
          <div className="searchResult">
            {
              users.map((user) => {
                return (
                  <div
                    onClick={() => addEmployer(user)}
                    className="user"
                    key={user.id}>
                    <BiSearchAlt /> {user.cin} - {user.firstName} {user.lastName}
                  </div>);
              })
            }
          </div>
          : null
      }
    </div>
  );
}

export default OldAccount;
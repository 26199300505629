import { FC, useContext, useMemo } from 'react';
//icons
import { GiGearStickPattern, GiCarDoor, GiGasPump, GiPerson } from 'react-icons/gi';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
//component
import Button from 'components/button/Button.component';
import CarDescription from 'components/popup/carDescription/carDescription.component';
//context
import { PopupContext } from 'contexts/popup.context';
//interface
import { Car } from 'services/car.service/car.interface';
//style
import './carItem.scss';

interface CarItemProps {
  car: Car
}

const url = process.env.REACT_APP_API_URL;

const CarItem: FC<CarItemProps> = ({ car }) => {
  const { setContent } = useContext(PopupContext);

  const open = () => {
    setContent(<CarDescription car={car} />);
  }

  const getPrices = useMemo(() => {
    let min = Infinity, max = 0
    for (const price of car.CarPrices) {
      if (price.firstPrice > max) max = price.firstPrice
      if (price.fourthPrice < min) min = price.fourthPrice
    }
    return [min, max]
  }, [car.CarPrices])

  return (
    <div className="carItem">
      <img loading='lazy' className='carImage' src={url + "/uploads/" + car.CarImages[0]?.imagePath} alt="" />
      <div className='carItem-content'>
        <h3 className='title'>{car.Model.name}</h3>
        <div className='carDescription'>
          <div><GiGearStickPattern />{car.GearBox.name}</div>
          <div><GiCarDoor />{car.carNbDoor} Portes</div>
          <div><GiGasPump />{car.Fuel.name}</div>
          <div><GiPerson />{car.carNbPlaces} Places</div>
        </div>
        <div className='carPrice'>
          <span>Prix :</span>
          <span><b>{getPrices[0]} - {getPrices[1]} </b><span className='TND'> TND TTC/jour</span></span>
        </div>
        <div className='carReserve'>
          <Button
            handle={open}
            moreStyle='reserve-btn'>
            Reserver<IoIosArrowDroprightCircle />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CarItem;

import Button from "components/button/Button.component";
import { PopupContext } from "contexts/popup.context";
import { FC, FormEvent, useContext, useEffect, useRef, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { EmailInterface, EmailResponseInterface } from "services/contact.service/contact.interface";
import { defaultContactResponse } from "services/contact.service/contact.object";
import { sendResponse, viewEmail } from "services/contact.service/contact.service";
import { date } from "utils/date/date.util";
import './emailContent.scss';

interface EmailContentProps {
  email: EmailInterface,
  handle: () => void
}

const EmailContent: FC<EmailContentProps> = ({ email, handle }) => {

  const [formFields, setFormFields] = useState<EmailResponseInterface>(defaultContactResponse);
  const { done } = useContext(PopupContext)
  const [, setError] = useState("");
  const form = useRef<HTMLFormElement>(null)

  useEffect(() => {
    setFormFields(oldValues => { return { ...oldValues, idUser: email.id } })
  }, [email.id])

  useEffect(() => {
    if (!email.isViewed) {
      viewEmail(email.id)
    }
  }, [email.id, email.isViewed])

  const handleForm = (event: any) => {
    const { value } = event.target;
    setFormFields(oldValues => { return { ...oldValues, response: value } });
  }

  const submit = (event: FormEvent) => {
    event.preventDefault();
    sendResponse(formFields)
      .then(() => {
        form.current?.reset();
        done("Repance envoyé");
        handle();
      })
      .catch((errors) => {
        setError(errors.errors.response);
      })
  }

  return (
    <div className="email-content">
      <div className="email-sent">
        <IoMdArrowRoundBack className="back" onClick={handle} />
        <h3 className="subject">
          {email.subject}
        </h3>
        <div className="header">
          <div>
            {email.from}
          </div>
          <div>
            {date(email.createdAt)}
          </div>
        </div>
        <div className="email-message">
          {email.bodyMessage}
        </div>
      </div>

      <div className="email-respance">
        <div className="line"></div>
        {
          email.response ?
            <div>
              <div className="header">
                <div>
                  {email.User.firstName} {email.User.lastName}
                </div>
                <div>
                  {date(email.responseDate)}
                </div>
              </div>
              <h3>Repance</h3>
              {email.response}
            </div>
            :
            <form ref={form} onSubmit={submit}>
              <div className="inputComponent">
                <label htmlFor="respance">Repance</label>
                <textarea
                  onChange={handleForm}
                  name="respance"
                  id="respance"
                  className="input textarea" ></textarea>
              </div>
              <Button
                submit={true}
                moreStyle="action"
              >Envoyer</Button>
            </form>

        }

      </div>
    </div>
  );
}

export default EmailContent;
import { PopupContext } from "contexts/popup.context";
import { FC, useContext, useEffect } from "react";
//style
import './popupDone.scss'

interface PopupDoneProps {
  message: string
}

const PopupDone: FC<PopupDoneProps> = ({ message }) => {

  const { popNext } = useContext(PopupContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      popNext();
    }, 4000)
    return () => {
      clearTimeout(timer);
    }
  }, [popNext])

  return (
    <div className="popup-done">
      <h3>{message}</h3>
      <div className="wrapper">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>
    </div>
  );
}

export default PopupDone;
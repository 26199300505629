import Button from "components/button/Button.component";
import TextInput from "components/inputs/textInput/text.component";
import { PopupContext } from "contexts/popup.context";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { Car } from "services/car.service/car.interface";
import { ContractInterface, ContractStatusInterface } from "services/contract.service/contract.interface";
import { ContractStatus, ContractUpdate, getContractsAdmin } from "services/contract.service/contract.service";
import { UserInterface } from "services/user.service/user.interface";
import { date, getDaysInMonthInRange, months } from "utils/date/date.util";

import './updateRent.scss';

interface UpdateRentProps {
  contractInput: ContractInterface,
  status: Array<ContractStatusInterface>,
  show: boolean
  handle: () => void
}

const UpdateRent: FunctionComponent<UpdateRentProps> = ({ status, show, contractInput, handle }) => {

  const { closePopUp } = useContext(PopupContext);
  const [contract, setContract] = useState(contractInput);
  const [prices, setPrices] = useState(new Map<number, number>());
  const [user, setUser] = useState<UserInterface>(contractInput.User);
  const [car, setCar] = useState<Car>();
  const [paidAmount, setPaidAmount] = useState<number>(contract.paidAmount ? contract.paidAmount : contract.initialAmount);

  useEffect(() => {
    setUser(contract.User);
    setCar(contract.Car);
  }, [contract.User, contract.Car])

  useEffect(() => {
    setContract(contractInput)
  }, [contractInput])

  const handleUpdate = (event: any) => {
    setPaidAmount(event.value);
  }

  const submit = (event: any) => {
    event.preventDefault();
    ContractUpdate(contract.id, paidAmount)
      .then((result) => {
        closePopUp();
        handle();
      })
      .catch(() => {

      })
  }

  useEffect(() => {
    if (car && contract) {
      let duration = 0;
      const start = new Date(contract.startDate);
      const end = new Date(contract.endDate);

      const Difference_In_Time = Math.abs(start.getTime() - end.getTime());
      duration = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

      const dayParMonth = new Map<number, number>();
      for (let month = start.getMonth(); month <= end.getMonth(); month++) {
        const days = getDaysInMonthInRange(start, end, month);
        if (days) {
          const price = car.CarPrices.find(carPrice => carPrice.month === month + 1);
          let monthPrice = 0;
          if (price) {
            monthPrice = price.firstPrice;
            if (duration > 3 && duration < 9)
              monthPrice = price.secondPrice;
            else if (duration > 8 && duration < 21)
              monthPrice = price.thirdPrice;
            else if (duration > 20)
              monthPrice = price.fourthPrice;
            dayParMonth.set(month, monthPrice);
          }
        }
      }
      setPrices(dayParMonth);
    }
  }, [contract, car])

  const contractStatus = (contractStatus: string) => {
    const statusSelected = status.find((status) => {
      return status.name === contractStatus;
    });
    if (statusSelected?.id)
      ContractStatus(contract.id, statusSelected.id)
        .then((result) => {
          getContractsAdmin()
            .then((result) => {
              setContract({ ...contract, ContractStatus: statusSelected, ContractStatusId: statusSelected.id });
              handle();
            })
        })
        .catch(() => {

        })
  }

  return (
    <div className="updateRent">
      <h2>Client</h2>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>CIN</th>
              <th>Permis</th>
              <th>Date de délivration</th>
              <th>Tél.</th>
              <th>E-mail</th>
              <th>Date néssence</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{user.firstName} {user.lastName}</td>
              <td>{user.cin}</td>
              <td>{user.permis}</td>
              <td>{user.permisExpDate}</td>
              <td>{user.num_tel}</td>
              <td>{user.email}</td>
              <td>{user.birthDate}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>Voiture</h2>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Matricule</th>
              <th>Marque</th>
              <th>Model</th>
              <th>Caution</th>
              <th>Prix/jour</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{car?.registrationNumber}</td>
              <td>{car?.Model.Brand.name}</td>
              <td>{car?.Model.name}</td>
              <td>{car?.caution} TND</td>
              <td>
                {Array.from(prices.entries()).map(([month, price]) =>
                  <li className="price-item" key={"month" + month}>{months[month] + " " + price} <span className="TND">TND TTC/jour</span></li>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>{show ? "Contrat" : "Reservation"}</h2>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Point</th>
              <th>De</th>
              <th>A</th>
              <th>2eme conducteur</th>
              <th>Siège bébé</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{contract.RetreivePoint.name}</td>
              <td>{date(contract.startDate)}</td>
              <td>{date(contract.endDate)}</td>
              <td>{contract.secondDriver ? "oui" : "non"}</td>
              <td>{contract.seatBebe ? "oui" : "non"}</td>
              <td>{contract.initialAmount} TND</td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        show ? (
          <div className="action">
            <h3>
              Montant payé :
              <div className="amount-paied">
                {contract.paidAmount ? contract.paidAmount : contract.initialAmount} TND
              </div>
            </h3>
          </div>
        ) : (
          <form onSubmit={submit} className="action">
            <TextInput
              label="Montant  à payer"
              option={{
                placeholder: contract.initialAmount,
                value: paidAmount,
                type: "number"
              }}

              handle={handleUpdate} />

            <div className="actions-btn">
              {
                ["Rejeté", "En attente"].includes(contract.ContractStatus.name) ?
                  <Button
                    tooltip='Approuver la reservation'
                    moreStyle='action-btn'
                    handle={() => contractStatus("Approuvé")}
                    size='rounded'
                    type='success'><BiCheck />
                  </Button> : ""
              }
              {
                ["Approuvé", "En attente"].includes(contract.ContractStatus.name) ?
                  <Button
                    tooltip='Rejeter la reservation'
                    moreStyle='action-btn'
                    handle={() => contractStatus("Rejeté")}
                    size='rounded'
                  ><IoIosClose />
                  </Button> : ""
              }
              <Button
                moreStyle="submit-btn"
                type="edit"
                submit={true}
              >Modifier</Button>
            </div>

          </form>
        )
      }

    </div>
  );
}

export default UpdateRent;
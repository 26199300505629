import { FC, FormEvent, useContext, useEffect, useState } from "react";
//context
import { AddCarContext } from "contexts/addCar.context";
import { CarsContext } from "contexts/cars.context";
import { PopupContext } from "contexts/popup.context";
//service
import { addCar } from "services/car.service/car.service";
//object
import { defaultErrors, defaultCarInput } from "services/car.service/car.object";
//interface
import { CarInput } from "services/car.service/car.interface";
//style
import './addCar.scss';
import CarFields from "../carFields/carFields.components";

const AddCar: FC = () => {

  const { reset } = useContext(CarsContext);
  const { car, img } = useContext(AddCarContext);
  const { popNext } = useContext(PopupContext);

  const [formFields, setFormFields] = useState<CarInput>(defaultCarInput);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [name: string]: string | undefined }>(defaultErrors);
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setFormFields(car);
    setImage(img)
  }, [car, img])

  const handleForm = (event: any) => {
    let { name, value } = event;
    if (name === "logoImg") {
      if (!event.files[0])
        setErrors({ ...errors, images: "Image obligatoire." });
      else
        setErrors({ ...errors, images: '' });
      setImage(event.files[0]);
      return;
    } else if ((name === "FuelId" || name === "GearBoxId") && typeof value === "string") {
      value = parseInt(value, 10);
    }

    setFormFields({ ...formFields, [name]: value });
    setErrors({ ...errors, [name]: "" });
  }

  const handlePrice = (month: number, name: "firstPrice" | "secondPrice" | "thirdPrice" | "fourthPrice", price: number) => {

    if (typeof price === "string") {
      price = parseInt(price, 10);
    }

    // Create a copy of the data object
    const updatedData = { ...formFields };

    if (formFields.CarPrices.find(carPrice => carPrice.month === month)) {
      updatedData.CarPrices = formFields.CarPrices.map(carPrice =>
        carPrice.month === month ? { ...carPrice, [name]: price } : carPrice
      );
    } else {
      updatedData.CarPrices.push({ month, firstPrice: 0, secondPrice: 0, thirdPrice: 0, fourthPrice: 0, [name]: price })
    }

    // Update the state with the new data object
    setFormFields(updatedData);
    setErrors(oldValues => { return { ...oldValues, [`CarPrices.${month - 1}.${name}`]: "" } });
  }

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true)
    setErrors(defaultErrors);

    prepareData()
      .then((data) => {
        addCar(data)
          .then(() => {
            popNext();
            reset();
          })
          .catch((error) => {
            setErrors(oldValues => { return { ...oldValues, ...error.errors } })
          })
          .finally(() => {
            setLoading(false)
          });
      })
      .catch((error) => {
        setErrors(oldValues => { return { ...oldValues, images: error } })
        setLoading(false)
      })
  }

  const prepareData = async () => {
    const formData = new FormData();

    if (image) {
      await formData.append(
        'images',
        image
      )
    } else {
      return Promise.reject("Image obligatoire.")
    }

    await Object.keys(formFields).forEach((key) => {
      if (key === "CarPrices") {
        formData.append(
          key,
          JSON.stringify((formFields as any)[key])
        );
      } else {
        formData.append(
          key,
          (formFields as any)[key]
        );
      }
    })

    return formData;
  }

  return (
    <div className="addCar">
      <div className="addCarTitle">
        <h1>
          Ajouter une voiture
        </h1>
        <p>
          Veuillez insérer les données suivantes concernant la voiture à ajouter.
        </p>
      </div>
      <CarFields
        loading={loading}
        image={image}
        formFields={formFields}
        errors={errors}
        type="add"
        handleSubmit={submitForm}
        handlePrice={handlePrice}
        handleForm={handleForm}
      />
    </div>
  )
}

export default AddCar;
import { createContext, useState } from "react";
import { CarInput } from "services/car.service/car.interface";
import { defaultCarInput } from "services/car.service/car.object";

interface AddCarContextInterface {
  brand: number,
  car: CarInput,
  img: File | null,
  setBrand: (brand: number) => void
  setCar: (car: CarInput) => void
  setImg: (img: File | null) => void
}

export const AddCarContext = createContext<AddCarContextInterface>({
  brand: 0,
  img: null,
  car: defaultCarInput,
  setBrand: () => { },
  setCar: () => { },
  setImg: () => { }
});


type addCarProps = {
  children: React.ReactChild
}

export const AddCarProvider = ({ children }: addCarProps) => {

  const [brand, setBrand] = useState<number>(0);
  const [car, setCar] = useState<CarInput>(defaultCarInput);
  const [img, setImg] = useState<File | null>(null);

  const value = { car, brand, img, setCar, setBrand, setImg };
  return <AddCarContext.Provider value={value}>{children}</AddCarContext.Provider>

}


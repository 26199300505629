import { FC } from "react";
//style
import './navItem.scss';

interface NavItemProps {
  children: string,
  select: string,
  id: string,
  handle: (page: string) => void
}

const NavItem: FC<NavItemProps> = ({ id, select, children, handle }) => {

  const onClick = () => {
    handle(id)
  }

  return (
    <div
      onClick={onClick}
      className={`nav-item ${select === id ? "nav-item-selected" : ""}`}>
      {children}
    </div>
  );
}

export default NavItem;
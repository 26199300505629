import { FC, useContext, useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
//context
import { AddCarContext } from "contexts/addCar.context";
import { PopupContext } from "contexts/popup.context";
import { CarsContext } from "contexts/cars.context";
//object
import { defaultBrandInput } from "services/brand.service/brand.object";
//service
import { addBrand, updateBrand } from "services/brand.service/brand.service";
import { BrandInterface } from "services/brand.service/brand.interface";
//component
import ImageInput from "components/inputs/selectImage/image.component";
import TextInput from "components/inputs/textInput/text.component";
import Button from "components/button/Button.component";
//style
import './addBrand.scss';

const URL_BACK = process.env.REACT_APP_API_URL;

interface AddBrandPops {
  brand?: BrandInterface,
  type: "add" | "update"
}

const AddBrand: FC<AddBrandPops> = ({ brand, type }) => {

  const { popNext } = useContext(PopupContext);
  const { reset } = useContext(CarsContext);
  const { setBrand } = useContext(AddCarContext);

  const [formFields, setFormFields] = useState(defaultBrandInput);
  const [errors, setErrors] = useState(defaultBrandInput);
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (brand) {
      setFormFields(brand);
    }
  }, [brand])

  const handleForm = (event: any) => {
    const { name, value } = event;
    if (name === "logoImg") {
      if (!event.files[0])
        setErrors({ ...errors, logoImg: "Image obligatoire." });
      else
        setErrors({ ...errors, logoImg: '' });
      setFormFields({ ...formFields, logoImg: event.files[0] });
      setImage(event.files[0]);
      return;
    }
    setFormFields({ ...formFields, [name]: value });
  }

  const prepareData = () => {
    if (formFields.logoImg) {
      const formData = new FormData();
      formData.append(
        "logoImg",
        formFields.logoImg
      );
      formData.append(
        "name",
        formFields.name
      );
      return Promise.resolve(formData);
    } else {
      return Promise.reject();
    }
  }

  const submit = (event: any) => {
    event.preventDefault();
    setLoading(true)
    setErrors(defaultBrandInput);
    prepareData()
      .then((formData) => {
        type === "add" ?
          requestAdd(formData)
          : requestUpdate(formData);
      })
      .catch(() => {
        setErrors({ ...errors, logoImg: "Image obligatoire." })
      })
  }

  const requestAdd = (formData: any) => {
    addBrand(formData)
      .then((result) => {
        setErrors(defaultBrandInput);
        reset();
        setBrand(result.brand.id);
        popNext();
      })
      .catch((error) => {
        setErrors({ ...errors, name: error.message })
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const requestUpdate = (formData: any) => {
    if (brand)
      updateBrand(formData, brand.id)
        .then((result) => {
          setErrors(defaultBrandInput);
          reset();
          popNext();
        })
        .catch((error) => {
          setErrors({ ...errors, ...error.errors })
        })
        .finally(() => {
          setLoading(false)
        });
  }

  return (
    <div className="addBrand">
      <IoArrowBackCircle onClick={() => popNext()} className="back-btn" />
      <div className="addBrandTitle">
        <h1>
          Ajouter une marque
        </h1>
        <p>
          Veuillez insérer le nom de la marque et sa logo.
        </p>
      </div>
      <form id="addBrand" className="form" onSubmit={submit}>
        {/* Name of the brand to add  */}
        <TextInput
          error={errors.name}
          required={true}
          handle={handleForm}
          label="Nom de la marque"
          option={{
            value: formFields.name,
            name: 'name',
            placeholder: 'Nom'
          }}
        ></TextInput>
        {/* brand image selector */}
        <div className="imageDisplayContainer">
          {
            image ?
              <img className="imageDisplay" src={URL.createObjectURL(image)} alt="" />
              : (type === "update" && <img className="imageDisplay" src={URL_BACK + "/uploads/" + formFields.logoImg} alt="" />)
          }
        </div>
        <div className="imageSelect">
          <ImageInput error={errors.logoImg} handle={handleForm} />
        </div>

        <div className="add-btn">
          <Button loading={loading} submit={true}>{type === "add" ? "Ajouter" : "Modifier"}</Button>
        </div>
      </form>
    </div>
  );
}

export default AddBrand;
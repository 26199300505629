import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
//component
import Button from 'components/button/Button.component';
//assets
import svg from 'assets/svg/404page.svg';
//style
import './404page.scss';
import { noIndexPage, setDescription, setTitle } from 'utils/seo/seo.utils';

const Page404: FC = () => {

  useEffect(() => {
    noIndexPage();
    setTitle("Page introuvable");
    setDescription(`Le lien que vous avez suivi est peut-être rompu`)
  }, [])

  return (
    <div className='page404'>
      <div className='page404header'>
        <div className='background'></div>
        <h1>404 Page</h1>
      </div>
      <div className='page404content'>

        <p>Le lien que vous avez suivi est peut-être rompu</p>

        <img src={svg} alt="404 page slama rent car" />

        <Link to="/">
          <Button>Retourner à la page d'accueil</Button>
        </Link>


      </div>
    </div>
  )
}

export default Page404;
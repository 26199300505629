import { FC, useEffect, useState } from 'react';
//service
import { resetPassword } from 'services/auth.service/auth.service';
//components
import MainNavigation from '../../main/header/navigation.component';
import TextInput from 'components/inputs/textInput/text.component';
import Button from 'components/button/Button.component';
import Footer from 'app/main/footer/footer.component';
import { indexPage, noIndexPage, setDescription, setTitle } from 'utils/seo/seo.utils';



const ForgetPassword: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Réinitialisation de mot de passe | Slama rent a car");
    setDescription(`
    Réinitialisation de mot de passe - Oublié votre mot de passe ? Pas de souci. 
    Chez slama rent a car, nous facilitons la récupération de votre compte. 
    Suivez les étapes simples pour réinitialiser votre mot de passe et accéder à votre compte en toute sécurité.
    `)
  }, []);

  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    return () => {
      setLoading(false);
    }
  }, [])

  const emailHandle = (event: any) => {
    const { value } = event
    setEmail(value);
    setError("");
  }

  const resetPasswordSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    resetPassword(email)
      .then((result) => {
        setSend(true);
        setResult(result.message)
      }).catch((error) => {
        setError(error.errors.email)
      }).finally(() => {
        setLoading(false)
      });
  }

  return (
    <div className='auth'>
      <div className="forget-password">
        <MainNavigation />
        <div className='forget-password-content'>
          <div className='background'>
          </div>
          <form onSubmit={resetPasswordSubmit}>
            <div className='forget-password-container'>
              <h2>Réinitialiser votre mot de passe</h2>
              <p>Veuillez entrer votre adresse e-mail pour rechercher votre compte.</p>
              <TextInput
                handle={emailHandle}
                message={result}
                error={error}
                option={{
                  name: "email",
                  type: "email",
                  placeholder: "Adresse e-mail"
                }} />
              {
                !send ?
                  <Button
                    loading={loading}
                    submit={true}
                    moreStyle='reset-btn'
                  >Réinitialiser</Button>
                  : null
              }
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default ForgetPassword;
import { FC, useState } from "react";
import BrandsManage from "./manageBrans/brandsManage.component";
import CarsManage from "./manageCars/cars.component";
import ModelsManage from "./manageModels/modelsManage.component";
import NavItem from "components/navItem/NavItem.component";
//style
import './car.scss';

const Cars: FC = () => {

  const [pageSelected, setPage] = useState<string>('car');

  const pageSelectHandler = (page: string) => {
    setPage(page);
  }

  return (
    <div className="cars">
      <nav className='nav'>
        <NavItem id={"car"} select={pageSelected} handle={pageSelectHandler}>Voitures</NavItem>
        <NavItem id={"model"} select={pageSelected} handle={pageSelectHandler}>Models</NavItem>
        <NavItem id={"brand"} select={pageSelected} handle={pageSelectHandler}>Marques</NavItem>
      </nav>
      {
        (() => {
          switch (pageSelected) {
            case 'car': return <CarsManage />;
            case 'brand': return <BrandsManage />;
            case 'model': return <ModelsManage />;
          }
        })()
      }
    </div>
  );
}

export default Cars;
import { NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom';

//components
import Auth from './routes/auth.route';
import AuthGuard from 'guards/authGuard.component';
import DashboardRoute from 'routes/dashboard.route';
import MainRoute from 'routes/main.route';

//style
import './App.scss';
import 'services/interceptor.service';
import { useContext, useEffect } from 'react';
import { PopupContext } from 'contexts/popup.context';

export let navigate: NavigateFunction;
export let closePopupHandle: () => void;

function App() {

  const { closePopUp } = useContext(PopupContext)

  navigate = useNavigate();
  closePopupHandle = closePopUp;

  useEffect(() => {

    const appHeight = () => {
      const vh = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    const stopScrollInput = (event: any) => {
      if ((document.activeElement as HTMLInputElement)?.type === "number") {
        (document.activeElement as HTMLInputElement)?.blur();
      }
    }
    document.addEventListener("wheel", stopScrollInput);

    return () => {
      window.removeEventListener('resize', appHeight);
      window.removeEventListener('wheel', stopScrollInput);
    }

  }, [])

  return (
    <Routes>
      <Route path='/*' element={<MainRoute />} />
      <Route path='auth/*' element={<Auth />} />
      <Route path='dashboard/*' element={
        <AuthGuard auth={true}>
          <DashboardRoute />
        </AuthGuard>} />
    </Routes>
  );
}

export default App;

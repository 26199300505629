import { EmailInputInterface, EmailResponseInterface } from "./contact.interface";

export const defaultContactInputs:  EmailInputInterface = {
  from: "",
  subject: "",
  bodyMessage: "",
}

export const defaultContactResponse:  EmailResponseInterface = {
  idUser : 0,
  response : ""
}
import { Navigate, Route, Routes } from "react-router-dom";
//components
import Page404 from "app/main/404page/404page.component";
import AboutUs from "app/main/aboutUs/aboutUs.component";
import Cars from "app/main/cars/cars.component";
import Home from "app/main/home/home.component";
import Main from "app/main/main.component";
import ContactUs from "app/main/contactUs/ContactUs.component";

const MainRoute = () => {
  return (
    <Routes>
      <Route path='/' element={<Main />}>
        <Route index element={<Home />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  )
}

export default MainRoute;
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import frLocale from 'date-fns/locale/fr';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Link, } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import { FC, useEffect, useMemo, useState } from "react";
import fr from 'react-phone-number-input/locale/fr.json';
//components
import { ReactComponent as SlamaRentCar } from 'assets/svg/slamaRentCar.svg';
import TextInput from "components/inputs/textInput/text.component";
import Button from "components/button/Button.component";
import EmailSend from "../emailSend/emailSend.component";
//object
import { defaultSignUp, defaultSignUpErrors } from "services/auth.service/auth.object";
//service
import { signUp } from "services/auth.service/auth.service";
//interface
import { signUpInputs } from "services/auth.service/auth.interface";
//assets
import logo from 'assets/logo/logo-black.svg';
//style
import 'react-phone-number-input/style.css';
import { indexPage, setDescription, setTitle } from "utils/seo/seo.utils";


const SignUp: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Inscription | Slama rent a car");
    setDescription(`
    Inscrivez-vous chez Slama rent a car pour bénéficier d'un accès complet à nos services. 
    Créez rapidement un compte personnel en remplissant le formulaire d'inscription. Rejoignez notre communauté dès aujourd'hui !
    `)
  }, []);

  const [emailSend, setEmailSend] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<signUpInputs>(defaultSignUp);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(defaultSignUpErrors);

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  const handleForm = (event: any) => {
    const { name, value } = event;
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" });
  }

  const handleNumber = (tel: any) => {
    setFormFields({ ...formFields, num_tel: tel })
    setError({ ...error, num_tel: "" })
  }

  const submit = (event: any) => {
    event.preventDefault();
    setLoading(true)
    signUp(formFields)
      .then((result) => {
        localStorage.setItem("verifyToken", result.token);
        setError(defaultSignUpErrors);
        setEmailSend(true);
      }).catch((error) => {
        setError({ ...defaultSignUp, ...error.errors });
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const calculateMaxDate = useMemo(() => (duration: number = 0): Date => {
    return new Date(new Date().setFullYear(new Date().getFullYear() + duration));
  }, [])

  return (
    emailSend ?
      <EmailSend
        form={formFields} />
      :
      <div className="auth">
        <div className="thumbnail">
          <div></div>
          <div className="slegon">
            <SlamaRentCar />
            <p>Toujours opté pour le professionnalisme et le meilleur service</p>
          </div>
          <div className="links">
            <a
              className='sociel-link'
              href={process.env.REACT_APP_FACEBOOK}
              target="_blank"
              rel="noreferrer"
            ><FaFacebookF /></a>

            <a
              className='sociel-link'
              href={process.env.REACT_APP_INSTAGRAM}
              target="_blank"
              rel="noreferrer"
            ><FaInstagram /></a>
          </div>
        </div>
        <div className="form-view">
          <div className="form-container">
            <div className="logo-container signUp">
              <Link to="/"><img src={logo} className="logo" alt="slama rent carlogo" /></Link>
            </div>
            <form className="form" onSubmit={submit}>

              <TextInput
                option={{
                  name: "email",
                  type: "email",
                  placeholder: "Adresse e-mail"
                }}
                error={error.email}
                handle={handleForm} />
              <div className="gap"></div>

              <TextInput
                option={{
                  name: "cin",
                  type: "text",
                  placeholder: "Carte d'identité ou passeport"
                }}
                error={error.cin}
                handle={handleForm} />
              <div className="gap"></div>
              <div className="permis">
                <TextInput
                  moreStyle="permis-input"
                  option={{
                    name: "permis",
                    type: "text",
                    placeholder: "Permis (00/000000)"
                  }}
                  error={error.permis}
                  handle={handleForm} />
                <div className="inputComponent permis-expired">
                  <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat='dd/MM/yyyy'
                      minDate={calculateMaxDate(-90)}
                      maxDate={new Date()}
                      views={['year', 'month', 'day']}
                      openTo="year"
                      value={formFields.permisExpDate}
                      onChange={(newValue) => {
                        handleForm({ name: 'permisExpDate', value: newValue })
                      }}
                      renderInput={(params) =>
                        <TextField
                          className='date'
                          {...params}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: "délivrance"
                            }
                          } />
                      }
                    />
                  </LocalizationProvider>
                  {error.permisExpDate?.length ?
                    <div className="input-error">{error.permisExpDate}</div>
                    : null}
                </div>
              </div>
              <div className="gap"></div>

              <div className="nameInput">
                <TextInput
                  option={{
                    name: "firstName",
                    type: "text",
                    placeholder: "Nom"
                  }}
                  error={error.firstName}
                  handle={handleForm} />
                <TextInput
                  option={{
                    name: "lastName",
                    type: "text",
                    placeholder: "Prénom"
                  }}
                  error={error.lastName}
                  handle={handleForm} />
              </div>
              <div className="gap"></div>

              <div className="inputComponent">
                <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    openTo="year"
                    inputFormat='dd/MM/yyyy'
                    maxDate={calculateMaxDate(-18)}
                    minDate={calculateMaxDate(-90)}
                    views={['year', 'month', 'day']}
                    value={formFields.birthDate}
                    onChange={(newValue) => {
                      handleForm({ name: 'birthDate', value: newValue })
                    }}
                    renderInput={(params) =>
                      <TextField
                        className='date'
                        {...params}
                        inputProps={
                          {
                            ...params.inputProps,
                            placeholder: "Date naissance (jj/mm/aaaa)"
                          }
                        } />
                    }
                  />
                </LocalizationProvider>
                {error.birthDate?.length ?
                  <div className="input-error">{error.birthDate}</div>
                  : null}
              </div>
              <div className="gap"></div>

              <div className="inputComponent">
                <div className="input">
                  <PhoneInput
                    labels={fr}
                    country="TN"
                    placeholder="Numéro téléphone"
                    onChange={handleNumber} />
                </div>
                {error.num_tel?.length ?
                  <div className="input-error">{error.num_tel}</div>
                  : null}
              </div>
              <div className="gap"></div>

              <TextInput
                option={{
                  name: "password",
                  type: "password",
                  placeholder: "Mot de passe"
                }}
                error={error.password}
                handle={handleForm} />


              <div className="gap"></div>

              <Button loading={loading} submit={true} moreStyle="auth-btn">Enregistrer</Button>
              <div className="gap"></div>

              <div className="separetor">
                <div className="line"></div>
                <p>OU</p>
                <div className="line"></div>
              </div>
              <div className="gap"></div>

              <Link to="/auth/sign-in"><Button type="secondary" >Connecter</Button></Link>
            </form>
            <div>
            </div></div>
        </div>
      </div>
  )
}

export default SignUp;
import SearchBar from "app/dashboard/searchBar/searchBar.component";
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
import { PopupContext } from "contexts/popup.context";
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { EmailInterface } from "services/contact.service/contact.interface";
import { deleteEmail, getEmails } from "services/contact.service/contact.service";
import { dateMonthText } from "utils/date/date.util";
import EmailContent from "../emailContent/emailContent.component";
//style
import './allEmails.scss';

const AllEmails: FC = () => {

  const { setContent } = useContext(PopupContext)

  const [emailSelected, setEmailSelected] = useState<EmailInterface>();
  const [emails, setEmails] = useState<Array<EmailInterface>>();
  const [emailsFiltered, setEmailsFiltered] = useState<Array<EmailInterface>>();
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const navigator = useNavigate();

  useEffect(() => {
    getMessages();
  }, [])

  useEffect(() => {
    setEmailsFiltered(emails?.filter((email) => {
      const string = email.from + email.subject
      return (string.toLowerCase()).includes(search.replace(/\s/g, '').toLowerCase());
    }))
  }, [emails, search])

  useEffect(() => {
    if (id && emails?.length) {
      const email = emails?.find((email) => {
        return email.id.toString() === id;
      })
      if (email)
        setEmailSelected(email);
      else {
        setEmailSelected(undefined);
        navigator("/dashboard/contact");
      }
    } else {
      setEmailSelected(undefined);
    }
  }, [emails, id, navigator])

  const openEmail = (event: MouseEvent, email: EmailInterface) => {
    const deleteIcons = document.querySelectorAll(".delete");
    let contain = false;
    if (deleteIcons.length) {
      deleteIcons.forEach((el) => {
        if ((el?.contains(event.target as Node))) {
          contain = true;
          return;
        }
      });
    }
    if (!contain)
      navigator(`/dashboard/contact/${email.id}`);
  }

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const removeEmail = (email: EmailInterface) => {
    const handle = () => {
      deleteEmail(email.id)
        .then(() => {
          getMessages();
        })
        .catch(() => {

        })
    }
    setContent(<PopupVerify handle={handle} target={"l' e-mail"} type='delete' />);
  }

  const getMessages = () => {
    getEmails()
      .then((result) => {
        setEmails(result);
      })
      .catch(() => {
        setEmails([]);
      })
  }

  const closeMessage = () => {
    navigator("/dashboard/contact")
    setEmailSelected(undefined);
    getMessages();
  }

  return (
    <div className="contact">
      <SearchBar
        reload={getMessages}
        text={true}
        title='Boîte de réception'
        handle={handleSearch} />
      <div className='dashboard-page-content'>
        {
          !emailSelected ?
            <table className="emails">
              <tbody>
                {
                  emailsFiltered?.map((email) => {
                    return (
                      <tr onClick={(event) => openEmail(event, email)} key={email.id} className={`email ${email.isViewed ? "" : "already-seen"}`}>
                        <td>{dateMonthText(email.createdAt)}</td>
                        <td>{email.from}</td>
                        <td>{email.subject}</td>
                        <td className="action">
                          <AiFillDelete
                            className="delete"
                            onClick={() => removeEmail(email)} />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            :
            <EmailContent email={emailSelected} handle={closeMessage} />
        }
      </div>
    </div>
  );
}

export default AllEmails;
import { FC, useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers';
import fr from 'react-phone-number-input/locale/fr.json';
import frLocale from 'date-fns/locale/fr';
//context
import { UserContext } from 'contexts/user.context';
//service
import { updateProfile } from 'services/auth.service/auth.service';
//components
import TextInput from 'components/inputs/textInput/text.component';
import Button from 'components/button/Button.component';
//object
import { defaultUpdateProfileInputs } from 'services/auth.service/auth.object';
//style
import 'react-phone-number-input/style.css';
import './profile.scss';
import { PopupContext } from 'contexts/popup.context';
import { TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { date } from 'utils/date/date.util';
import { getUserData } from 'services/user.service/user.service';

const Profile: FC = () => {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const [permisError] = useState<string | null>(useQuery().get('permis'));
  const [reservation] = useState<string | null>(useQuery().get('reservation'));
  const navigator = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [formFields, setFormFields] = useState(user);
  const [error, setError] = useState(defaultUpdateProfileInputs);
  const [loading, setLoading] = useState(false);
  const { done } = useContext(PopupContext);
  const [change, setChange] = useState(false);

  useEffect(() => {
    getUserData()
      .then((result) => {
        setUser(oldValues => { return { ...oldValues, ...result, birthDate: new Date(result.birthDate) } });
      })
  }, [setUser])

  useEffect(() => {
    setFormFields(user);
    if (!user.permis && permisError === "none") {
      setError({ ...defaultUpdateProfileInputs, permis: "Donner votre Permis" })
    }
  }, [user, permisError])

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  const handleForm = (event: any) => {
    const { name, value } = event;
    setError({ ...error, [name]: "" })
    if (value === "") {
      setFormFields({ ...formFields, [name]: user[name as keyof typeof user] });
    } else {
      setFormFields({ ...formFields, [name]: value });
      setChange(true)
    }
  }

  const handleNumber = (tel: any) => {
    setError({ ...error, num_tel: "" })
    setFormFields({ ...formFields, num_tel: tel })
    setChange(true)
  }

  const submit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    updateProfile(formFields, user.token)
      .then((result) => {
        setUser({ ...user, ...result });
        setError(defaultUpdateProfileInputs);
        setChange(false)
        const form = document.getElementById("updateForm") as HTMLFormElement;
        form?.reset();
        done("Profile modifié");
        if (result.permis && reservation)
          navigator(`/cars?reservation=${reservation}`)
      })
      .catch((error) => {
        setError({ ...defaultUpdateProfileInputs, ...error.errors });
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const calculateMaxDate = (duration: number = 0): Date => {
    return new Date(new Date().setFullYear(new Date().getFullYear() + duration));
  }

  return (
    <div className='profile'>
      <h4>Profile</h4>
      mettez à jour vos données personnelles ici
      <div className='line'></div>
      <form id="updateForm" onSubmit={submit}>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Nom
            </span>
            <span className='description'>
              Nom et prénom
            </span>
          </div>
          <div className=' settingsProfilInputs NameInputs'>
            <TextInput
              option={{
                name: "firstName",
                type: "text",
                placeholder: user.firstName
              }}
              error={error.firstName}
              handle={handleForm}></TextInput>
            <TextInput
              option={{
                name: "lastName",
                type: "text",
                placeholder: user.lastName
              }}
              error={error.lastName}
              handle={handleForm}></TextInput>
          </div>
        </div>

        <div className='line'></div>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Numéro de téléphone
            </span>
          </div>
          <div className='settingsProfilInputs'>
            <div className="inputComponent">
              <div className="input">
                <PhoneInput
                  labels={fr}
                  country="TN"
                  value={user.num_tel}
                  placeholder="Numéro téléphone"
                  onChange={handleNumber} />
              </div>
              {error.num_tel?.length ?
                <div className="input-error">{error.num_tel}</div>
                : null}
            </div>
          </div>
        </div>

        <div className='line'></div>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Piece d'identité
            </span>
          </div>
          <div className='settingsProfilInputs'>
            {
              user.cinIsVerified ?
                user.cin
                :
                <TextInput
                  option={{
                    name: "cin",
                    type: "text",
                    placeholder: user.cin
                  }}
                  error={error.cin}
                  handle={handleForm}></TextInput>
            }
          </div>
        </div>

        <div className='line'></div>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Permis
            </span>
          </div>
          <div className='settingsProfilInputs'>
            {
              user.cinIsVerified ?
                <div className='permis'>
                  <span>
                    {user.permis}
                  </span>
                  <span>{date(user.permisExpDate, false)}</span>



                </div>
                :
                <div className='permis'>
                  <TextInput
                    option={{
                      name: "permis",
                      type: "text",
                      placeholder: user.permis
                    }}
                    error={error.permis}
                    handle={handleForm}></TextInput>
                  <div className="inputComponent permis-expired">
                    <LocalizationProvider locale={frLocale} dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        inputFormat='dd/MM/yyyy'
                        maxDate={new Date()}
                        minDate={calculateMaxDate(-90)}
                        views={['year', 'month', 'day']}
                        openTo="year"
                        value={formFields.permisExpDate}
                        onChange={(newValue) => {
                          handleForm({ name: 'permisExpDate', value: newValue })
                        }}
                        renderInput={(params) =>
                          <TextField
                            className='date'
                            {...params}
                            inputProps={
                              {
                                ...params.inputProps,
                                placeholder: "délivrance"
                              }
                            } />
                        }
                      />
                    </LocalizationProvider>
                    {error.permisExpDate?.length ?
                      <div className="input-error">{error.permisExpDate}</div>
                      : null}
                  </div>
                </div>
            }
          </div>
        </div>

        <div className='line'></div>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Date de naissance
            </span>
          </div>
          <div className='settingsProfilInputs'>
            <div className="inputComponent">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  inputFormat='dd/MM/yyyy'
                  value={formFields.birthDate}
                  views={['year', 'month', 'day']}
                  openTo="year"
                  minDate={calculateMaxDate(-90)}
                  maxDate={calculateMaxDate(-18)}
                  onChange={(newValue) => {
                    handleForm({ name: 'birthDate', value: newValue })
                  }}
                  renderInput={(params) =>
                    <TextField
                      className='date'
                      {...params}
                      inputProps={
                        {
                          ...params.inputProps,
                          placeholder: "jj/mm/aaaa"
                        }
                      }
                    />}
                />
              </LocalizationProvider>
              {error.birthDate?.length ?
                <div className="input-error">{error.birthDate}</div>
                : null}
            </div>
          </div>
        </div>
        <div className="update-btn">
          <Button
            disabled={!change}
            loading={loading}
            submit={true}>Modifier</Button>
        </div>
      </form>

    </div>
  )
}

export default Profile;
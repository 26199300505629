import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//icons
import { GiHomeGarage } from 'react-icons/gi';
import { AiFillCar } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { HiMenuAlt3 } from 'react-icons/hi';
//components
import Button from 'components/button/Button.component';
//context
import { PopupContext } from 'contexts/popup.context';
//assets
import { ReactComponent as Slogan } from 'assets/svg/slamaRentCar.svg';
import logo from 'assets/logo/logo.svg';
//styling
import './navigation.scss';

const MainNavigation: FC = () => {

  const { setContent, setVisibility, closePopUp } = useContext(PopupContext);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  const openMenu = () => {
    setContent(
      <div className='mobile-menu'>
        <Link
          onClick={closePopUp}
          to="/cars">
          <div className='icon'>
            <AiFillCar />
          </div> Voitures
        </Link>
        <Link
          onClick={closePopUp}
          to="about-us">
          <div className='icon'>
            <GiHomeGarage /></div>Agence
        </Link>
      </div>
    )
    setVisibility(true);
  }

  return (
    <header className=''>
      <div className='header'>
        <Link to="/" className='logo-desctop'>
          <img src={logo} className="logo" alt="slama rent carlogo" />
        </Link>
        {width < 700 &&
          <Link to="/" className='logo-mobile'>
            <Slogan className='logo' />
          </Link>
        }

        <div className='nav'>
          <a className='number D1900' href={`tel:${process.env.REACT_APP_Tel}`}>
            <Button size='small'><FaWhatsapp />{process.env.REACT_APP_Tel}</Button>
          </a>
          <Link to="/dashboard/settings">
            <Button moreStyle='profile' tooltip='Profile' size='small rounded'><CgProfile /></Button>
          </Link>
          <nav className='nav-menu'>
            <HiMenuAlt3 onClick={openMenu} />
            <Link to="/cars">Voitures</Link>
            <Link to="about-us">Agence</Link>
          </nav>
        </div>
      </div>
      <div className='D700 cull-mobile'>
        <a className='number' href={`tel:${process.env.REACT_APP_Tel}`}>
          <Button size='small'><FaWhatsapp />{process.env.REACT_APP_Tel}</Button>
        </a>
      </div>
    </header>
  )
}

export default MainNavigation; 
import Button from "components/button/Button.component";
import { PopupContext } from "contexts/popup.context";
import { FC, useContext } from "react";
//style
import './popupVerify.scss';

const typeConvertor = {
  "delete": "supprimer",
  "ban": "blocker",
  "unban": "débloquer",
  "add-admin": "ajouter l'administrateur : "
}

interface PopupVerifyProps {
  type: "delete" | "ban" | "unban" | "add-admin",
  target: string,
  message?: Array<string>,
  handle: () => void
}

const PopupVerify: FC<PopupVerifyProps> = ({ type, target, message, handle }) => {

  const { closePopUp } = useContext(PopupContext);

  const confirm = () => {
    closePopUp();
    handle();
  }

  return (
    <div className="verify-popup">
      <h3>{
        typeConvertor[type].charAt(0).toUpperCase() + typeConvertor[type].slice(1)} {target}
      </h3>
      <p>Êtes-vous sûr de {typeConvertor[type]} {target}?</p>
      {
        message?.map((element, index) => {
          return (<p key={index}>{element}</p>);
        })
      }
      <div className="action">
        <Button moreStyle="cancel-btn" handle={closePopUp} type="secondary">Annuler</Button>
        <Button handle={confirm} type="primary">Confirmer</Button>
      </div>
    </div>
  );
}

export default PopupVerify;
export const getDaysInMonthInRange = (startDate: Date, endDate: Date, targetMonth: number) => {

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();

  let days = 0;

  for (let year = startYear; year <= endYear; year++) {
    const monthStart = (year === startYear) ? startMonth : 0;
    const monthEnd = (year === endYear) ? endMonth : 11;

    for (let month = monthStart; month <= monthEnd; month++) {
      if (month === targetMonth) {
        const firstDay = (year === startYear && month === startMonth) ? startDate.getDate() : 1;
        const lastDay = (year === endYear && month === endMonth) ? endDate.getDate() - 1 : new Date(year, month + 1, 0).getDate();

        days += lastDay - firstDay + 1;
      }
    }
  }

  return days;
}

export const date = (d: Date, fullDate: boolean = true): string => {
  if (!d) return "";
  const input = new Date(d);
  let date = "";
  const dd = lengthVerif(input.getDate());
  const MM = lengthVerif(input.getMonth() + 1);
  const yyyy = lengthVerif(input.getFullYear());
  date = dd + '-' + MM + '-' + yyyy;
  if (fullDate) {
    const hh = lengthVerif(input.getHours());
    const mm = lengthVerif(input.getMinutes());
    date += ' ' + hh + ':' + mm
  }
  return date;
}

export const isToday = (someDate: Date) => {
  if (someDate) {
    const hours = Math.abs(new Date().getTime() - someDate.getTime()) / 36e5;
    return hours < 24;
  } else return false;
}
export const isTomorrow = (someDate: Date) => {
  if (someDate) {
    const hours = Math.abs(new Date().getTime() - someDate.getTime()) / 36e5;
    return hours < 48 && hours > 24;
  } return false;
}

export const dateMonthText = (d: Date): string => {
  const thisYear = new Date().getFullYear();
  const input = new Date(d);
  let date = "";
  const dd = lengthVerif(input.getDate());
  const MM = months[input.getMonth()];
  let yyyy = "";
  if (thisYear !== input.getFullYear())
    yyyy = '-' + lengthVerif(input.getFullYear())
  date = dd + ' ' + MM + yyyy;
  return date;
}

const lengthVerif = (numberToVerif: number) => {
  const number = numberToVerif.toString()
  if (number.length === 1)
    return "0" + number;
  else return number;
}

export const months = [
  "janv.",
  "févr.",
  "mars",
  "avril",
  "mai",
  "juin",
  "juil.",
  "août",
  "sept.",
  "oct.",
  "nov.",
  "déc.",
]

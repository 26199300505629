import { FC, useContext, useEffect, useState } from "react";
//component
import TextInput from "components/inputs/textInput/text.component";
import Button from "components/button/Button.component";
//context
import { UserContext } from "contexts/user.context";
//service
import { updateEmail } from "services/auth.service/auth.service";
import { getUserData } from "services/user.service/user.service";
//style
import './email.scss';

const Email: FC = () => {

  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState(user.email);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserData()
      .then((result) => {
        setUser(oldValues => { return { ...oldValues, ...result, birthDate: new Date(result.birthDate) } });
      })
  }, [setUser])

  useEffect(() => {
    setLoading(false);
  }, [])

  const handleForm = (event: any) => {
    const { value } = event
    setEmail(value);
    setError("")
  }

  const submit = (event: any) => {
    event.preventDefault();
    setLoading(true)
    updateEmail(email)
      .then((result) => {
        setUser({ ...user, newEmail: email });
        setError('');
        const form = document.getElementById("emailForm") as HTMLFormElement;
        form?.reset();
      })
      .catch((error) => {
        setError(error.errors.email);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='profile'>
      <h4>E-mail</h4>
      mettez à jour votre e-mail de contact ici
      <div className='line'></div>
      <form id="emailForm" onSubmit={submit}>
        <div className='settingsProfileItem'>
          <div className='settingsProfileLabelName'>
            <span className='label'>
              Contact
            </span>
            <span className='description'>
              Votre email est obligatoir pour recevoir votre contrat
              il faut la verifié en cas de mise à jour
            </span>
          </div>
          <div className='settingsProfilInputs emailUpdate'>
            <TextInput
              option={{
                name: "email",
                type: "email",
                placeholder: user.email
              }}
              error={error}
              handle={handleForm}></TextInput>

            {
              (() => {
                return user.newEmail ?
                  <div className="noVerifyEmail">
                    {user.newEmail} <span>non verifié</span>
                  </div> : ''
              })()
            }

            { }

          </div>
        </div>
        <div className="update-btn">
          <Button loading={loading} submit={true}>Modifier</Button>
        </div>
      </form>

    </div>
  )
}

export default Email;
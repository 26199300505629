import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
//provider
import { UserProvider } from 'contexts/user.context';
import { PopupProvider } from 'contexts/popup.context';
import { AddCarProvider } from 'contexts/addCar.context';
import { SearchProvider } from 'contexts/search.context';
import { CarsProvider } from 'contexts/cars.context';

import App from './App';

import './index.css';
import { ContractProvider } from 'contexts/contract.context';
import ScrollToTop from 'utils/scrollTop/scrollTop';


ReactDOM.render(

  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop/>
      <UserProvider>
        <AddCarProvider>
          <CarsProvider>
            <SearchProvider>
              <ContractProvider>
                <PopupProvider>
                  <App />
                </PopupProvider>
              </ContractProvider>
            </SearchProvider>
          </CarsProvider>
        </AddCarProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

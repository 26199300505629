import { FC } from "react";
//style
import './selectList.scss';

type listInputProps = {
  id?: string,
  prefix?: string,
  value?: number,
  label?: string,
  list: Array<any>,
  placeholder?: string,
  error?: string,
  name: string,
  handle: (event: any) => void
}

const ListInput: FC<listInputProps> = ({
  list, label,
  placeholder, value,
  name, prefix,
  id, handle,
  error
}) => {

  const selectElement = (event: any) => {
    handle(event.target);
  }

  return (
    <div id={id} className="selectInput">
      {
        label && <label className="label">{label}</label>
      }
      <select
        disabled={list.length === 0}
        name={name}
        value={value ?? value}
        onChange={selectElement}
        className={`select ${error?.length ? "error-style" : ""}`}>
        <option

          value={0}>
          -- {placeholder ? placeholder : "sélect"} --
        </option>
        {
          (() => {

            if (prefix === "C") {
              return list.map((element: any) => {
                return <option
                  key={prefix ? prefix + element.id : element.id}
                  value={element.id}>
                  {element.registrationNumber}
                </option>
              })
            } else {
              return list.map((element: any) => {
                return <option
                  key={prefix ? prefix + element.id : element.id}
                  value={element.id}>
                  {element.name}
                </option>
              })
            }
          })()
        }
      </select>
      {error?.length ?
        <div className="input-error">{error}</div>
        : null}
    </div>

  )
}

export default ListInput;
import { FC, FormEvent, useContext, useEffect, useMemo, useState } from "react";
import { Checkbox } from "@mui/material";
import { BiSearchAlt } from "react-icons/bi";
import { BsPeopleFill } from "react-icons/bs";
import { FaBaby } from "react-icons/fa";
import { IoAddCircleSharp } from "react-icons/io5";
import { useCookies } from "react-cookie";
//component
import Button from "components/button/Button.component";
import SelectDateTime from "components/inputs/selectDateTime/selectDateTime.component";
import ListInput from "components/inputs/selectList/list.component";
import TextInput from "components/inputs/textInput/text.component";
//component
import AddClient from "./addClient/addClient.component";
//context
import { ContractContext } from "contexts/contract.context";
import { PopupContext } from "contexts/popup.context";
//interface
import { AddContractErrorsInterface, AddContractInterface } from "services/contract.service/contract.interface";
import { UserInterface } from "services/user.service/user.interface";
import { Car } from "services/car.service/car.interface";
//service
import { getUsers } from "services/user.service/user.service";
import { addContactAdmin } from "services/contract.service/contract.service";
import { getAvailabilityCars } from "services/car.service/car.service";
//object
import { defaultAddContract, defaultAddContractErrors } from "services/contract.service/contract.object";
import { getDaysInMonthInRange, isToday, isTomorrow, months } from "utils/date/date.util";
//style
import './addRent.scss';

interface AddRentProps {
  handleContacts: () => void
}

const AddRent: FC<AddRentProps> = ({ handleContacts }) => {

  const [cookies, , removeCookie] = useCookies(['addContract']);

  const { points } = useContext(ContractContext);
  const { done, pushNext } = useContext(PopupContext);

  const [search, setSearch] = useState('');
  const [carSearch, setCarSearch] = useState('');
  const [users, setUsers] = useState<Array<UserInterface>>([]);
  const [carsState, setCarsState] = useState<Array<Car>>([]);
  const [carsFiltered, setCarsFiltered] = useState<Array<Car>>([]);
  const [car, setCar] = useState<Car>();
  const [errors, setErrors] = useState<AddContractErrorsInterface>(defaultAddContractErrors);
  const [duration, setDuration] = useState(0);
  const [amount, setAmount] = useState(0);
  const [prices, setPrices] = useState(new Map<number, number>());
  const [formFields, setFormFields] = useState<AddContractInterface>(
    cookies.addContract ? {
      ...cookies.addContract,
      startDate: cookies.addContract.startDate ? new Date(cookies.addContract.startDate) : null,
      endDate: cookies.addContract.endDate ? new Date(cookies.addContract.endDate) : null,
    } : defaultAddContract);
  const [delayedSearch, setDelayedSearch] = useState<NodeJS.Timeout>();

  useEffect(() => {
    return (() => {
      removeCookie('addContract');
    })
  }, [removeCookie]);

  const getData = useMemo(() => (search: string) => {
    getUsers(1, 5, search)
      .then((result) => {
        setUsers(result.users);
      })
      .catch(() => {
        setUsers([]);
      })
  }, []);

  useEffect(() => {
    const cookieInput = cookies.addContract;
    if (cookieInput?.UserId) {
      setSearch(cookieInput.Search.toLowerCase());
      setUsers([]);
    }
  }, [cookies.addContract]);

  useEffect(() => {
    let newDuration = 0;
    if (formFields.startDate && formFields.endDate) {
      if (formFields.endDate <= formFields.startDate) {
        setFormFields(oldValues => {
          return {
            ...oldValues,
            endDate: new Date(
              new Date(formFields.startDate!!)
                .setDate(formFields.startDate!!.getDate() + 1)
            )
          };
        });
      }

      const start = formFields.startDate.getTime();
      const end = formFields.endDate.getTime();
      const Difference_In_Time = Math.abs(start - end);
      newDuration = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

      getAvailabilityCars({
        startDate: formFields.startDate,
        endDate: formFields.endDate,
        idFuel: 0,
        idGear: 0,
        nbDoor: 0,
        nbPlaces: 0
      }).then((result) => {
        setCarsState(result);
      }).catch(() => { });
    }

    setDuration(newDuration);
    setCar(undefined);
    setCarSearch('');

  }, [formFields.startDate, formFields.endDate]);


  useEffect(() => {
    if (car && formFields.startDate && formFields.endDate) {
      let total = 0;
      const dayParMonth = new Map<number, number>();
      for (let month = formFields.startDate.getMonth(); month <= formFields.endDate.getMonth(); month++) {
        const days = getDaysInMonthInRange(formFields.startDate, formFields.endDate, month);
        if (days) {
          const price = car.CarPrices.find(carPrice => carPrice.month === month + 1);
          let monthPrice = 0;
          if (price) {
            monthPrice = price.firstPrice;
            if (duration > 3 && duration < 9)
              monthPrice = price.secondPrice;
            else if (duration > 8 && duration < 21)
              monthPrice = price.thirdPrice;
            else if (duration > 20)
              monthPrice = price.fourthPrice;
            dayParMonth.set(month, monthPrice);
          }
          total += monthPrice * days;
        }
      }

      setPrices(dayParMonth);
      setAmount(total);
      setFormFields(oldValues => { return { ...oldValues, paidAmount: total } });
    } else {
      setAmount(0);
      setFormFields(oldValues => { return { ...oldValues, paidAmount: 0, CarId: 0 } });
    }
  }, [duration, car]); // eslint-disable-line

  useEffect(() => {
    if (carSearch === "") {
      setCarsFiltered(carsState);
      setFormFields(oldValues => { return { ...oldValues, CarId: 0 } });
      return;
    } else {
      const exactCar = carsState.find((car) => {
        const stringToTest = (car.registrationNumber + " - " + car.Model.name).toLowerCase();
        return stringToTest === carSearch.toLowerCase();
      })

      if (exactCar) {
        setCar(exactCar);
        setCarsFiltered([]);
      } else {
        setCar(undefined);
        setCarsFiltered(
          carsState.filter((car) => {
            const stringToTest = (car.registrationNumber + " - " + car.Model.name).toLowerCase();
            return stringToTest.includes(carSearch.toLowerCase());
          })
        );
      }
    }
  }, [carSearch, carsState]);

  const selectUser = (user: UserInterface) => {
    setFormFields(oldValues => { return { ...oldValues, UserId: user.id } });
    const fullName = user.cin + " - " + user.firstName + " " + user.lastName
    setSearch(fullName.toLowerCase());
    setUsers([]);
    setErrors({ ...errors, UserId: "" });
  }

  const selectCar = (search: string, id: number) => {
    const carSelected = carsFiltered.find((element) => {
      return element.id === id;
    });

    if (carSelected) {
      setErrors({ ...errors, CarId: "", paidAmount: "" });
      setCar(carSelected);
      setCarSearch(search);
      setFormFields((formFields) => { return { ...formFields, CarId: id } });
    }
  }

  const handle = (event: any) => {
    const { name, value } = event;
    switch (name) {
      case "car":
        setCarSearch(value);
        break;
      case "search":
        setSearch(value);
        if (delayedSearch) {
          clearTimeout(delayedSearch);
          setDelayedSearch(undefined);
        }
        setDelayedSearch(setTimeout(() => {
          if (value)
            getData(value);
          else setUsers([]);
        }, 500))
        break;
    }
  }

  const handleCheck = (event: any) => {
    const { name, checked } = event.target;
    setFormFields({ ...formFields, [name]: checked });
  }

  const handleForm = (event: any) => {
    const { name, value } = event;
    let hour: number;
    if (value) {
      switch (name) {
        case "end":
        case "start":
          if (formFields.startDate && (isToday(formFields.startDate) || isToday(value))) {
            hour = formFields.startDate.getHours();
          } else if (formFields.endDate && (isTomorrow(formFields.endDate) || isTomorrow(value))) {
            hour = formFields.endDate.getHours();
          } else {
            hour = value.getHours();
          }

          name === "end" ?
            setFormFields(oldValues => {
              return {
                ...oldValues,
                startDate: oldValues.startDate ? new Date(oldValues.startDate.setHours(hour, 0, 0)) : null,
                endDate: new Date(value.setHours(hour, 0, 0))
              };
            })
            : setFormFields(oldValues => {
              return {
                ...oldValues,
                startDate: new Date(value.setHours(hour, 0, 0)),
                endDate: oldValues.endDate ? new Date(oldValues.endDate.setHours(hour, 0, 0)) : null
              };
            });
          break;
        case "hour":
          hour = value.getHours();
          if (formFields.endDate && formFields.startDate) {
            setFormFields(oldValues => {
              return {
                ...oldValues,
                endDate: new Date(oldValues.endDate!!.setHours(hour, 0, 0)),
                startDate: new Date(oldValues.startDate!!.setHours(hour, 0, 0))
              };
            });
          } else if (formFields.endDate) {
            setFormFields(oldValues => {
              return {
                ...oldValues,
                endDate: new Date(oldValues.endDate!!.setHours(hour, 0, 0))
              };
            });
          } else if (formFields.startDate) {
            setFormFields(oldValues => {
              return {
                ...oldValues,
                startDate: new Date(oldValues.startDate!!.setHours(hour, 0, 0))
              };
            });
          }
          break;
        default:
          setErrors({ ...errors, [name]: "" });
          setFormFields(oldValues => { return { ...oldValues, [name]: value } });
          break;
      }
    }
  }

  const addContract = (event: FormEvent) => {
    event.preventDefault();
    if (formFields.startDate && formFields.endDate)
      addContactAdmin(formFields)
        .then(() => {
          removeCookie("addContract");
          done("Contrat ajouté");
          handleContacts();
        })
        .catch((error) => {
          if (error.errors)
            setErrors({ ...errors, ...error.errors });
        });
  }

  const addClient = () => {
    pushNext(<AddClient form={formFields} />);
  }

  return (
    <div className="add-rent">
      <h1 className="contrat">Ajouter un contrat</h1>
      <form onSubmit={addContract}>
        <SelectDateTime
          handle={handleForm}
          defaultForm={{
            start: formFields.startDate,
            end: formFields.endDate
          }}
          admin={true}
          type="start" />

        <SelectDateTime
          handle={handleForm}
          defaultForm={{
            start: formFields.startDate,
            end: formFields.endDate
          }}
          admin={true}
          type="end" />

        {
          formFields.startDate && formFields.endDate &&
          <div>
            <div className="search">
              <div className="add-client">
                <TextInput
                  handle={handle}
                  label="Identifiant client"
                  error={errors.UserId}
                  moreStyle="selectInput"
                  option={{
                    name: "search",
                    placeholder: "Nom",
                    autoComplete: "off",
                    value: search
                  }
                  }
                />
                <IoAddCircleSharp
                  onClick={addClient}
                  className="add"
                />
              </div>
              {
                users.length !== 0 ?
                  <div
                    style={errors.UserId ? { marginTop: "-90px" } : { marginTop: "-66px" }}
                    className="searchResult">
                    {
                      users.map((user) => {
                        return (
                          <div
                            onClick={() => selectUser(user)}
                            className="user"
                            key={user.id}>
                            <BiSearchAlt /> {user.cin} - {user.firstName} {user.lastName}
                          </div>);
                      })
                    }
                  </div>
                  : null
              }
            </div>

            <div className="search">
              <TextInput
                handle={handle}
                label="Identifiant voiture"
                error={errors.CarId}
                option={{
                  name: "car",
                  placeholder: "Voiture",
                  autoComplete: "off",
                  value: carSearch
                }
                }
              />
              {
                carsFiltered.length !== 0 ?
                  <div
                    style={errors.CarId ? { marginTop: "-90px" } : { marginTop: "-66px" }}
                    className="searchResult">
                    {
                      carsFiltered.map((car) => {
                        return (
                          <div
                            onClick={() => selectCar(
                              car.registrationNumber + " - " + car.Model.name,
                              car.id
                            )}
                            className="user"
                            key={car.id}>
                            <BiSearchAlt /> {car.registrationNumber} - {car.Model.name}
                          </div>);
                      })
                    }
                  </div>
                  : null
              }
            </div>


            <ListInput
              name="RetreivePointId"
              error={errors.RetreivePointId}
              placeholder="Point de retrait"
              value={formFields.RetreivePointId}
              handle={handleForm}
              list={points} />

            <div className="check">
              <div className="inputField checkBox">
                <div className="checkBoxLabel">
                  <FaBaby className="icon" />
                  <span>Siège bébé</span>
                  <span>0 TND TTC/jour</span>
                </div>
                <Checkbox
                  checked={formFields.seatBebe}
                  className="checkbox"
                  onChange={handleCheck}
                  name="seatBebe" />
              </div>

              <div id="secondDriver" className="inputField checkBox">
                <div className="checkBoxLabel">
                  <BsPeopleFill className="icon" />
                  <span>2éme conducteur</span>
                  <span>0 TND TTC/jour</span>
                </div>
                <Checkbox
                  checked={formFields.secondDriver}
                  className="checkbox"
                  onChange={handleCheck}
                  name="secondDriver" />
              </div>
            </div>
            {
              car ?
                <div className="resum">
                  <ul>
                    <li>Caution : {car.caution} TND</li>
                    <li>Coût de la location : {duration ? "" : <span className="TND">__ TND TTC/jour</span>}</li>
                    <li className="remarq price-item">* Le Prix de la location varie selon la durée</li>
                    {duration ? Array.from(prices.entries()).map(([month, price]) =>
                      <li className="price-item" key={"month" + month}>{months[month] + " " + price} <span className="TND">TND TTC/jour</span></li>
                    ) : ""}
                    <li>Durée : {duration} jours</li>
                  </ul>
                  <span className="total">Prix Total : {
                    formFields.paidAmount && formFields.paidAmount !== amount ?
                      <span>
                        <del>{amount}</del> {formFields.paidAmount}
                      </span> :
                      amount
                  } TND</span>
                </div>
                : null
            }
          </div>
        }

        <div className="action">
          {
            amount ?
              <TextInput
                label="Montant  à payer"
                error={errors.paidAmount}
                option={{
                  name: 'paidAmount',
                  placeholder: amount,
                  value: formFields.paidAmount,
                  autoComplete: "off",
                }}
                handle={handleForm} />
              : null
          }

          <Button submit={true}>Ajouter</Button>
        </div>
      </form>


    </div>
  );
}

export default AddRent;
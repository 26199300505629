import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
//context
import { AddCarContext } from 'contexts/addCar.context';
import { PopupContext } from 'contexts/popup.context';
import { CarsContext } from 'contexts/cars.context';
import { UserContext } from 'contexts/user.context';
//component
import PopupVerify from 'components/popup/popupVerify/popupVerify.component';
import Button from 'components/button/Button.component';
import SearchBar from '../../searchBar/searchBar.component';
import UpdateCar from './updateCar/updateCar.component';
import AddCar from './addCar/addCar.component';
//interface
import { Car, CarPrice } from 'services/car.service/car.interface';
import { defaultCarInput } from 'services/car.service/car.object';
import { deleteCar } from 'services/car.service/car.service';
//style
import './cars.scss';

const carSttus = {
  "Disponible": "black",
  "Loué": "green",
  "Reservé": "yellow",
  "Hors service": "red"
}


const CarsManage: FC = () => {

  const { user } = useContext(UserContext);
  const { setContent } = useContext(PopupContext);
  const { carsFiltered, reset } = useContext(CarsContext);
  const { setCar, setBrand, setImg } = useContext(AddCarContext);

  const [controlAccess, setControlAccess] = useState<{ text: string, handle: () => void }>();

  useEffect(() => {
    if (user.role_name === "ROLE_SUPER_ADMIN")
      setControlAccess({
        text: 'Ajouter une voitures',
        handle: () => {
          setBrand(0);
          setCar(defaultCarInput);
          setImg(null);
          setContent(<AddCar />);
        }
      })
  }, [user, setBrand, setCar, setImg, setContent])

  useEffect(() => {
    reset();
  }, [reset]);

  const updateCar = (element: Car) => {
    setBrand(element.Model.BrandId)
    setCar({ ...element, images: element.CarImages[0]?.imagePath });
    setImg(null);
    setContent(<UpdateCar id={element.id} />);
  }

  const removeCar = (car: Car) => {
    const handle = () => {
      deleteCar(car.id)
        .then(() => {
          reset();
        })
        .catch(() => {

        })
    }
    setContent(<PopupVerify handle={handle} target={car.registrationNumber} type='delete' />);
  }

  const getPrices = useMemo(() => (carPrices: Array<CarPrice>) => {
    let min = Infinity, max = 0
    for (const price of carPrices) {
      if (price.firstPrice > max) max = price.firstPrice
      if (price.fourthPrice < min) min = price.fourthPrice
    }
    return [min, max]
  }, [])

  return (
    <div className="carsPage">
      <SearchBar
        reload={reset}
        add={controlAccess}
        car={true}
        title='Liste des voiture' />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Matricule</th>
              <th>Marque</th>
              <th>Model</th>
              <th>Status</th>
              <th>Prix</th>
              {
                user.role_name === "ROLE_SUPER_ADMIN" ?
                  <th /> : null
              }
            </tr>
          </thead>
          <tbody>
            {
              carsFiltered.map((element, index) => {
                return (
                  <tr key={element.id} className='table-row'>
                    <td className='sticky-col'>{element.registrationNumber}</td>
                    <td>{element.Model.Brand.name}</td>
                    <td>{element.Model.name}</td>
                    <td className={carSttus[element.CarStatus.name]}>
                      {element.CarStatus.name}
                    </td>
                    <td className='prices'>
                      <span><b>{getPrices(element.CarPrices)[0]} - {getPrices(element.CarPrices)[1]}</b> <span className='TND'> TND TTC/jour</span></span>
                    </td>
                    {
                      user.role_name === "ROLE_SUPER_ADMIN" ?
                        <td className='action2'>
                          <Button
                            tooltip='Modifier voiture'
                            moreStyle='action-btn'
                            handle={() => updateCar(element)}
                            size='rounded'
                            type='edit'>
                            <MdEdit />
                          </Button>
                          <Button
                            tooltip='Supprimer voiture'
                            moreStyle='action-btn'
                            handle={() => removeCar(element)}
                            size='rounded'>
                            <AiFillDelete />
                          </Button>
                        </td>
                        : null
                    }
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </div>

    </div>
  )
}

export default CarsManage;
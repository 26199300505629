import { FC, useEffect, useState } from "react";
import { ContractInterface } from "services/contract.service/contract.interface";
import { getUserContracts } from "services/contract.service/contract.service";
import { date } from "utils/date/date.util";

import './userHistory.scss';

interface UserHistoryProps {
  id: number,
  fullName: string
}

const UserHistory: FC<UserHistoryProps> = ({ id, fullName }) => {

  const [contracts, setContracts] = useState<Array<ContractInterface>>([])

  useEffect(() => {
    getUserContracts(id)
      .then((result) => {
        setContracts(result);
      })
      .catch(() => {
        setContracts([]);
      })
  }, [id])

  return (
    <div className="userHistory">
      <h1>Historique de {fullName}</h1>
      <div className="tableContainer">
        <table className="table">
          <thead className='table-header'>
            <tr>
              <th className='car-name sticky-col'>Voiture</th>
              <th>De</th>
              <th>à</th>
              <th>Status</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {
              contracts.map((contract) => {
                return (
                  <tr key={contract.id} className='table-row'>
                    <td className='car-name sticky-col'>{contract.Car.Model.name}</td>
                    <td>{date(contract.startDate)}</td>
                    <td>{date(contract.endDate)}</td>
                    <td className={
                      (() => {
                        switch (contract.ContractStatus.name) {
                          case 'Approuvé':
                            return 'green';
                          case 'Rejeté':
                            return 'red';
                          case 'En attente':
                            return 'yellow'
                          default: return 'black';
                        }
                      })()
                    }
                    >{contract.ContractStatus.name}</td>
                    <td>{
                      contract.paidAmount && contract.paidAmount !== contract.initialAmount ?
                        <span>
                          <del>{contract.initialAmount}</del> {contract.paidAmount}
                        </span> :
                        contract.initialAmount
                    } TND</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserHistory;
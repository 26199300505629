import { FC, FormEvent, useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/logo/logo-black.svg"
//style
import './emailSend.scss';
import Button from "components/button/Button.component";
import { signUpInputs } from "services/auth.service/auth.interface";
import TextInput from "components/inputs/textInput/text.component";
import { Link } from "react-router-dom";
import { changeEmailOfVerification, resendEmailOfVerification } from "services/auth.service/auth.service";
import { noIndexPage, setDescription, setTitle } from "utils/seo/seo.utils";

interface EmailSendProps {
  form: signUpInputs,
}

const EmailSend: FC<EmailSendProps> = ({ form }) => {

  useEffect(() => {
    noIndexPage();
    setTitle("Confirmation d'envoi d'email | Slama rent a car");
    setDescription(`
    Confirmation d'envoi d'email - Votre message a été expédié avec succès.
    `)
  }, []);

  const [email, setEmail] = useState<string>(form.email);
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const [, setLoop] = useState<any>();
  const [error, setError] = useState("");

  const handleEmail = (event: any) => {
    setEmail(event.value);
    setError("");
  }

  const cancelChange = () => {
    setEmail(form.email);
    setChangeEmail(false);
  }

  const confirm = (event: FormEvent) => {
    event.preventDefault();
    setError("");
    changeEmailOfVerification(email)
      .then(() => {
        setChangeEmail(false);
      })
      .catch((error) => {
        setError(error.errors.email)
      })
  }

  const stopTimer = () => {
    setLoop((interval: NodeJS.Timeout) => {
      clearInterval(interval);
      return null;
    });
  }

  const startTimer = () => {
    setSent(true);
    setTimeout(() => {
      setSent(false);
      stopTimer();
      setCounter(60);
    }, 60000);

    setLoop(setInterval(() => {
      setCounter(c => c - 1);
    }, 1000));
  }

  const resendEmail = () => {
    resendEmailOfVerification()
      .then(() => {
        startTimer();
      })
      .catch(() => { })
  }

  return (
    <div className="emailSend">
      <Link to={"/"} ><Logo className="logo" /></Link>
      <div className="email-send-container">
        <div className="letter-image">
          <div className="animated-mail">
            <div className="back-fold"></div>
            <div className="letter">
              <div className="letter-border"></div>
              <div className="letter-title"></div>
              <div className="letter-context"></div>
              <div className="letter-stamp">
                <div className="letter-stamp-inner"></div>
              </div>
            </div>
            <div className="top-fold"></div>
            <div className="body"></div>
            <div className="left-fold"></div>
          </div>
          <div className="shadow"></div>
        </div>
        <h2 className="title">E-mail de vérification envoyé à votre adresse e-mail</h2>
        {
          changeEmail ?
            <form
              onSubmit={confirm}
              className="change-email-container">
              <TextInput
                handle={handleEmail}
                error={error}
                option={{
                  value: email,
                  type: "email",
                  placeholder: "Adresse email"
                }}
                required={true}
              />
              <Button
                handle={cancelChange}
                type="secondary">Annuler</Button>
              <Button
                submit={true}
              >Confirmer</Button>
            </form>
            :
            <h4 className="email">{email} <span
              onClick={() => setChangeEmail(true)}
              className="change-email">
              Changez votre email</span></h4>
        }
        <Link to={'/auth/sign-in'}><Button>Connecter</Button></Link>
        <p className="notSend">
          {
            !sent ?
              <span>
                Vous n'avez pas reçu l'e-mail?<br />
                Vérifiez dans votre dossier spam ou
                <span
                  onClick={resendEmail}
                  className="receive-again"> cliquez ici pour recevoir à nouveau l'e-mail.
                </span>
              </span>
              : <span>
                E-mail envoyé, vous pouvez réessayer dans {counter}.
              </span>
          }
        </p>
      </div>
    </div>
  );
}

export default EmailSend;
import { Navigate, Route, Routes } from "react-router-dom";
//components
import Settings from "app/dashboard/settings/settings.component";
import Dashboard from "app/dashboard/dashboard.component";
import Historic from "app/dashboard/historic/historic.component";
import Calendar from "app/dashboard/calendar/calendar.component";
import Cars from "app/dashboard/cars/cars.component";
import Rents from "app/dashboard/rents/rents.component";
import Users from "app/dashboard/users/users.component";
import Employers from "app/dashboard/employers/employers.component";
import RoleGuard from "guards/roleGuard.component";
import Ban from "app/dashboard/ban/ban.component";
import AllEmails from "app/dashboard/contact/allEmails/allEmails.component";
import Availability from "app/dashboard/availablity/availablity.component";

const DashboardRoute = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />}>
        <Route index element={<Navigate to="/404" replace />} />
        <Route path='historic' element={<Historic />} />
        <Route path='calendar' element={
          <RoleGuard role="ROLE_ADMIN">
            <Calendar />
          </RoleGuard>
        } />
        <Route path='availablity' element={
          <RoleGuard role="ROLE_ADMIN">
            <Availability />
          </RoleGuard>
        } />
        <Route path='cars' element={
          <RoleGuard role="ROLE_ADMIN">
            <Cars />
          </RoleGuard>
        } />
        <Route path='rents' element={
          <RoleGuard role="ROLE_ADMIN">
            <Rents />
          </RoleGuard>
        } />
        <Route path='users' element={
          <RoleGuard role="ROLE_ADMIN">
            <Users />
          </RoleGuard>
        } />
        <Route path='bloc' element={
          <RoleGuard role="ROLE_SUPER_ADMIN">
            <Ban />
          </RoleGuard>
        } />
        <Route path='employers' element={
          <RoleGuard role="ROLE_SUPER_ADMIN">
            <Employers />
          </RoleGuard>
        } />
        <Route path='contact/:id' element={
          <RoleGuard role="ROLE_ADMIN">
            <AllEmails />
          </RoleGuard>
        } />
        <Route path='contact' element={
          <RoleGuard role="ROLE_ADMIN">
            <AllEmails />
          </RoleGuard>
        } />
        <Route path='settings' element={<Settings />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>

  )
}

export default DashboardRoute;
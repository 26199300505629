import { FC, useContext, useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
//context
import { AddCarContext } from "contexts/addCar.context";
import { PopupContext } from "contexts/popup.context";
import { CarsContext } from "contexts/cars.context";
//service
import { addModel, updateModel } from "services/model.service/model.service";
//component
import TextInput from "components/inputs/textInput/text.component";
import Button from "components/button/Button.component";
//style
import './addModel.scss';
import { ModelInterface, ModelInterfaceInput } from "services/model.service/model.interface";
import { defaultModelInput, ModelInputErrors } from "services/model.service/model.object";
import ListInput from "components/inputs/selectList/list.component";

interface AddModelProps {
  idBrand?: number,
  model?: ModelInterface,
  type: "add" | "update"
}

const AddModel: FC<AddModelProps> = ({ idBrand, model, type }) => {

  const { popNext } = useContext(PopupContext);
  const { brands, reset } = useContext(CarsContext);
  const { car, setCar } = useContext(AddCarContext);

  const [formFields, setFormFields] = useState<ModelInterfaceInput>(defaultModelInput);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState(ModelInputErrors);

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (model) {
      setFormFields({ ...model, idBrand: model.BrandId })
    } else if (idBrand) {
      setFormFields(oldValues => { return { ...oldValues, idBrand: idBrand } })
    }
  }, [model, idBrand])

  const handleForm = (event: any) => {
    const { name, value } = event;
    setFormFields({ ...formFields, [name]: value });
    setErrors({ ...errors, [name]: '' });
  }

  const submit = (event: any) => {
    event.preventDefault();
    setLoading(true)
    setErrors(ModelInputErrors);
    type === "add" ?
      requestAdd()
      : requestUpdate();
  }

  const requestUpdate = () => {
    if (model)
      updateModel(formFields, model.id)
        .then(() => {
          const form = document.getElementById("addModel") as HTMLFormElement;
          form.reset();
          reset();
          popNext();
        })
        .catch((error) => {
          setErrors({ ...ModelInputErrors, ...error.errors })
        })
        .finally(() => {
          setLoading(false)
        });
  }

  const requestAdd = () => {
    addModel(formFields)
      .then((result) => {
        const form = document.getElementById("addModel") as HTMLFormElement;
        form.reset();
        reset();
        if (idBrand)
          setCar({
            ...car,
            ModelId: result.model.id
          })
        popNext();
      })
      .catch((error) => {
        setErrors({ ...ModelInputErrors, ...error.errors })
      })
      .finally(() => {
        setLoading(false)
      });
  }


  return (
    <div className="addModel">
      <IoArrowBackCircle onClick={popNext} className="back-btn" />
      <div className="addModelTitle">
        <h1>
          Ajouter un model
        </h1>
        <p>
          Veuillez insérer le nom de la model.
        </p>
      </div>
      <form id="addModel" className="form" onSubmit={submit}>
        {
          !idBrand ?
            <ListInput
              id="select-brand"
              error={errors.idBrand}
              prefix="B"
              handle={handleForm}
              name="idBrand"
              list={brands}
              value={formFields.idBrand ? formFields.idBrand : 0}
              label="Marque"
              placeholder="--Marque--"
            />
            : null
        }
        {/* Name of the brand to add  */}
        <TextInput
          error={errors.name}
          required={true}
          handle={handleForm}
          label="Nom de la model"
          option={{
            value: formFields.name,
            name: 'name',
            placeholder: 'Nom'
          }}
        ></TextInput>

        <div className="add-btn">
          <Button loading={loading} submit={true}>{type === "add" ? "Ajouter" : "Modifier"}</Button>
        </div>
      </form>
    </div>
  );
}

export default AddModel;
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import fr from '@fullcalendar/core/locales/fr';
//service
import { getContractsAdmin } from 'services/contract.service/contract.service';
//component
import SearchBar from '../searchBar/searchBar.component';
//style
import './calendar.scss';
import { CarsContext } from 'contexts/cars.context';

interface Size {
  width: number;
  height: number;
}

interface contractCar {
  title: string,
  start: Date,
  end: Date
}

const Calendar: FC = () => {

  const { search, reset } = useContext(CarsContext);

  const [size, setSize] = useState<Size>();
  const [contracts, setContracts] = useState<Array<contractCar | never>>([]);
  const calendarContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    reset();
  }, [reset])

  const getData = useMemo(() => (id: number) => {
    getContractsAdmin(1, 0, id)
      .then((result) => {
        setContracts(result.contracts.map((contract) => {
          return {
            title: contract.User.firstName + " " + contract.User.lastName,
            start: contract.startDate,
            end: contract.endDate
          }
        }));
      }).catch(() => { })
  }, []);

  useEffect(() => {
    const div = calendarContainer.current;
    if (search.car === 0) {
      setContracts([]);
      div?.classList.add("disabled");
    }
    else {
      setContracts([]);
      getData(search.car);
      div?.classList.remove("disabled");
    }
  }, [search.car])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        var element = mutationRecord.target as HTMLElement
        element.removeAttribute('style');
      });
    });
    const elements = document.getElementsByClassName('fc-view-harness');
    Array.prototype.map.call(elements, (element) => {
      observer.observe(element, { attributes: true, attributeFilter: ['style'] });
    });

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [size]);

  useEffect(() => {
    let elements = document.getElementsByClassName('fc-scroller');
    Array.prototype.map.call(elements, (element) => {
      element.style.position = 'relative';
    });
    elements = document.getElementsByClassName('fc-view');
    Array.prototype.map.call(elements, (element) => {
      element.style.position = 'relative';
    });
  })

  const handleWindowSizeChange = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  return (
    <div className="calendarPage">
      <SearchBar
        car={true}
        title='Calendrier'
      />

      <div ref={calendarContainer}>
        <FullCalendar
          locale={fr}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={contracts}
          eventColor={"#EB4E62"}
          headerToolbar={{

            start: 'today title',
            center: '',
            end: 'prev,next'

          }}
        />
      </div>
    </div>
  )
}

export default Calendar;
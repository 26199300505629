import React, { createContext, useEffect, useMemo, useState } from "react";
//interface
import { ContractInterface } from "services/contract.service/contract.interface";
import { ReceivePointInterface } from "services/points.service/points.interface";
//services
import { getContracts } from "services/contract.service/contract.service";
import { getPoints } from "services/points.service/points.service";

interface contractContextInterface {
  points: Array<ReceivePointInterface>
  contracts: Array<ContractInterface>
  contractsFiltered: Array<ContractInterface>
  setContracts: (contracts: Array<ContractInterface>) => void
  setContractsFiltered: (contracts: Array<ContractInterface>) => void,
  update: () => void,
  resetPoints: () => void
}

export const ContractContext = createContext<contractContextInterface>({
  contracts: [],
  points: [],
  contractsFiltered: [],
  setContracts: () => { },
  setContractsFiltered: () => { },
  update: () => { },
  resetPoints: () => { }
})

type ContractContextProps = {
  children: React.ReactChild
}

export const ContractProvider = ({ children }: ContractContextProps) => {

  const [contracts, setContracts] = useState<Array<ContractInterface>>([]);
  const [points, setPoints] = useState<Array<ReceivePointInterface>>([]);
  const [contractsFiltered, setContractsFiltered] = useState<Array<ContractInterface>>(contracts);

  const update = useMemo(() => () => {
    getContracts()
      .then((result) => {
        setContracts(result);
        setContractsFiltered(result);
      })
  }, [])

  const resetPoints = useMemo(() => () => {
    getPoints()
      .then((result) => {
        setPoints(result);
      })
  }, [])

  useEffect(() => {
    update();
    resetPoints();
  }, [resetPoints, update])

  const value = {
    contracts,
    contractsFiltered,
    points,
    setContracts,
    setContractsFiltered,
    update,
    resetPoints
  };
  return <ContractContext.Provider value={value}>{children}</ContractContext.Provider>
}
import { AddContractErrorsInterface, AddContractInterface, ContractInterfaceInput, DefaultContractInterfaceInputsErrors } from "./contract.interface";

export const defaultContractInputs: ContractInterfaceInput = {
  startDate: null,
  endDate: null,
  seatBebe: false,
  secondDriver: false,
  CarId: 0,
  RetreivePointId: 0,
}

export const defaultContractInputsErrors: DefaultContractInterfaceInputsErrors = {
  startDate: "",
  endDate: "",
  RetreivePointId: "",
  car: ""
}

export const defaultAddContract: AddContractInterface = {
  startDate: null,
  endDate: null,
  seatBebe: false,
  secondDriver: false,
  CarId: 0,
  RetreivePointId: 0,
  Search: "",
  UserId: 0,
  paidAmount: 0
}

export const defaultAddContractErrors: AddContractErrorsInterface = {
  startDate: "",
  endDate: "",
  seatBebe: "",
  secondDriver: "",
  CarId: "",
  RetreivePointId: "",
  UserId: "",
  paidAmount: ""
}
import { Delete, Get, Post, Put } from "services/axios.service";
import { Car, CheckCarByIdInterface, FilterInterface, FuelInterface, GearBoxInterface, Status } from "services/car.service/car.interface";

const api = "car";

export const getCars = (): Promise<Array<Car>> => {
  return Get(`/${api}/get-all-cars`)
}

export const getBestPlan = () => {
  return Get(`/${api}/best-plan`)
}

export const addCar = (data: FormData) => {
  return Post(`/${api}/add-car`, data)
}

export const updateCar = (data: FormData, id: number) => {
  return Put(`/${api}/update-car/${id}`, data)
}


export const deleteCar = (id: number) => {
  return Delete(`/${api}/delete-car/${id}`)
}

export const getCarId = (id: number) => {
  return Get(`/${api}/get-car/${id}`)
}


export const getCarStatus = (): Promise<Array<Status>> => {
  return Get(`/${api}/get-car-status`)
}

export const getFuel = (): Promise<Array<FuelInterface>> => {
  return Get(`/${api}/get-fuels`)
}

export const getGearBox = (): Promise<Array<GearBoxInterface>> => {
  return Get(`/${api}/get-gear-box`)
}

export const getAvailabilityCars = (filter: FilterInterface): Promise<Array<Car>> => {
  return Get(`/${api}/available-car`, filter)
}

export const checkCarById = (filter: CheckCarByIdInterface) => {
  return Post(
    `/${api}/check-car-availability`, filter)
}


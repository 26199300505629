import { Navigate, Route, Routes } from 'react-router-dom';
//components
import SignIn from 'app/auth/signIn/signIn.component';
import SignUp from 'app/auth/signUp/signUp.component';
import ForgetPassword from 'app/auth/forgetPassword/forgetPassword.component';
import NewPassword from 'app/auth/newPassword/newPassword.component';
import VerifyEmail from 'app/auth/verifyEmail/verifyEmail.component';
import UpdateEmail from 'app/auth/updateEmail/updateEmail.component';
//guard
import AuthGuard from 'guards/authGuard.component';
//style
import 'app/auth//auth.scss';


function Auth() {
  return (
    <Routes>
      <Route path='sign-in' element={
        <AuthGuard auth={false}>
          <SignIn />
        </AuthGuard>
      } />
      <Route path='sign-up' element={
        <AuthGuard auth={false}>
          <SignUp />
        </AuthGuard>
      } />
      <Route path='verify-email' element={
        <AuthGuard auth={false}>
          <VerifyEmail />
        </AuthGuard>} />
        
      <Route path='forget-password' element={
        <AuthGuard auth={false}>
          <ForgetPassword />
        </AuthGuard>
      } />
      <Route path='reset-password' element={
        <AuthGuard auth={false}>
          <NewPassword />
        </AuthGuard>
      } />

      <Route path='update-email' element={<UpdateEmail />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  )
}

export default Auth;
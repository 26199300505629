export const setTitle = (title: string) => {
  document.title = title;
}

export const noIndexPage = () => {
  updateMeta("robots", "noindex, nofollow, max-snippet:0");
}

export const indexPage = () => {
  updateMeta("robots", "index, follow, max-snippet:-1");
}

export const setDescription = (description: string) => {
  updateMeta("description", description);
}

export const updateMeta = (name: string, context: string) => {
  const metaDescription = document.querySelector(`meta[name="${name}"]`);
  if (metaDescription) {
    metaDescription.setAttribute('content', context);
  }
}
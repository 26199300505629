import { createContext, useEffect, useMemo, useState } from "react";

interface searchContext {
  startDate: Date | null,
  endDate: Date | null,
  idGear: number,
  idFuel: number,
  setStartDate: (dateTime: Date | null) => void,
  setEndDate: (dateTime: Date | null) => void,
  setGear: (gear: number) => void,
  setFuel: (fuel: number) => void,
  reset: () => void

}

export const SearchContext = createContext<searchContext>({
  startDate: null,
  endDate: null,
  idFuel: 0,
  idGear: 0,
  setStartDate: () => { },
  setEndDate: () => { },
  setGear: () => { },
  setFuel: () => { },
  reset: () => { }
})

type searchProps = {
  children: JSX.Element;
}



export const SearchProvider = ({ children }: searchProps) => {

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [idGear, setGear] = useState<number>(0);
  const [idFuel, setFuel] = useState<number>(0);

  useEffect(() => {
    if (startDate && endDate) {
      if (endDate <= startDate) {
        setEndDate(new Date(
          new Date(startDate)
            .setDate(startDate.getDate() + 1)
        ))
      }
    }
  }, [endDate, startDate])

  const reset = useMemo(() => () => {
    setStartDate(null);
    setEndDate(null);
    setGear(0);
    setFuel(0);
  }, [])


  const value = {
    startDate,
    endDate,
    idGear,
    idFuel,
    setStartDate,
    setEndDate,
    setGear,
    setFuel,
    reset
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

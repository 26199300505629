import { FC } from "react";
//style
import './spinner.scss'

const Spinner: FC = () => {
  return (
    <div className="spinner">
      <div className="lds-ellipsis">
        <div></div><div></div><div></div><div></div>
      </div>
    </div>
  );
}

export default Spinner;
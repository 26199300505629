import { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//component
import CarDescription from 'components/popup/carDescription/carDescription.component';
import SelectDateTime from 'components/inputs/selectDateTime/selectDateTime.component';
import ListInput from 'components/inputs/selectList/list.component';
import CarItem from 'components/carItem/carItem.component';
//context
import { SearchContext } from 'contexts/search.context';
import { CarsContext } from 'contexts/cars.context';
import { PopupContext } from 'contexts/popup.context';
//interface
import { Car, FilterInterface } from 'services/car.service/car.interface';
//service
import { getAvailabilityCars, getCarId } from 'services/car.service/car.service';
//style
import './cars.scss';
import { isToday, isTomorrow } from 'utils/date/date.util';
import { indexPage, setDescription, setTitle } from 'utils/seo/seo.utils';

const Cars: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Nos voitures");
    setDescription(`
    Explorez notre collection de voitures de rêve et trouvez la voiture parfaite pour vous. 
    Utilisez notre outil de filtrage avancé pour personnaliser votre choix en fonction de vos préférences et de votre budget. 
    Découvrez votre allocation automobile idéale aujourd'hui.
    `)
  }, [])

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const {
    startDate,
    endDate,
    idGear,
    idFuel,
    setStartDate,
    setEndDate,
    setGear,
    setFuel,
  } = useContext(SearchContext);
  const { setContent } = useContext(PopupContext);
  const [reservation] = useState<string | null>(useQuery().get('reservation'));
  const { fuel, carGear, cars, reset } = useContext(CarsContext);
  const [formFields, setFormFields] = useState<FilterInterface>(
    {
      startDate: startDate,
      endDate: endDate,
      idGear: idGear,
      idFuel: idFuel,
      nbDoor: 0,
      nbPlaces: 0
    }
  );
  const [carsState, setCarsState] = useState<Array<Car>>([]);
  const [carFiltered, setCarFiltered] = useState<Array<Car>>([]);

  useEffect(() => {
    reset();
    if (!reservation) return;

    getCarId(parseInt(reservation))
      .then((result) => {
        setContent(<CarDescription car={result} />);
      })
  }, [reservation, reset, setContent]);

  useEffect(() => {
    let myMap: Array<Car> = [];
    let present: boolean;
    carsState.forEach((car) => {
      present = false;
      if (myMap.find((element) => {
        return (
          element.CarPrices[0].firstPrice === car.CarPrices[0].firstPrice &&
          element.CarPrices[0].secondPrice === car.CarPrices[0].secondPrice &&
          element.CarPrices[0].thirdPrice === car.CarPrices[0].thirdPrice &&
          element.CarPrices[0].fourthPrice === car.CarPrices[0].fourthPrice &&
          element.ModelId === car.ModelId
        );
      })) present = true;
      if (!present) {
        myMap.push(car);
      }
    })
    setCarFiltered(myMap);
  }, [carsState])

  useEffect(() => {
    getAvailabilityCars(formFields)
      .then((result) => {
        setCarsState(cars.filter((car) => {
          return result.find((element: any) => {
            return car.id === element.id;
          })
        }));
      })
      .catch((_error) => {
        setCarsState([]);
      })
    return () => {
      setFuel(formFields.idFuel);
      setGear(formFields.idGear);
    }
  }, [cars, formFields, setFuel, setGear]);

  useEffect(() => {
    setFormFields(oldValues => { return { ...oldValues, startDate: startDate, endDate: endDate } })
  }, [startDate, endDate])

  const handleForm = (event: any) => {
    const { name, value } = event;
    let hour;
    if (value)
      switch (name) {
        case "end":
        case "start":
          if (startDate) {
            hour = startDate.getHours();
            if ((isToday(startDate) || isToday(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else if (endDate) {
            hour = endDate.getHours();
            if ((isTomorrow(endDate) || isTomorrow(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else {
            const date1 = new Date(new Date(value).setHours(value.getHours()));
            const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
            if (
              ((name === "start" && isToday(value))
                || (name === "end" && isTomorrow(value)))
              && date1 < date2)
              hour = value.getHours() + 1;
            else {
              hour = value.getHours();
            }
          }

          if (name === "end") {
            setStartDate(startDate ? new Date(startDate.setHours(hour, 0, 0)) : null);
            setEndDate(new Date(value.setHours(hour, 0, 0)));
          } else {
            setEndDate(endDate ? new Date(endDate.setHours(hour, 0, 0)) : null);
            setStartDate(new Date(value.setHours(hour, 0, 0)));
          }
          break;
        case "hour":
          hour = value.getHours();
          if (endDate && startDate) {
            setEndDate(new Date(endDate.setHours(hour, 0, 0)));
            setStartDate(new Date(startDate.setHours(hour, 0, 0)));
          } else if (endDate) {
            setEndDate(new Date(endDate.setHours(hour, 0, 0)));
          } else if (startDate) {
            setStartDate(new Date(startDate.setHours(hour, 0, 0)));
          }
          break;
        default:
          setFormFields({ ...formFields, [name]: value });
          break;
      }
  }

  return (
    <div className='carsMainPage'>
      <div className='carFilters'>
        <div className='background'>
        </div>
        <div className='filters'>
          <h3 className='title'>Rechercher une voiture à votre choix</h3>
          <form className="form">
            <div className='inputsFilter'>
              <div className='inputContainer'>
                <SelectDateTime
                  admin={false}
                  handle={handleForm}
                  defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="start" />
              </div>
              <div className='inputContainer'>
                <SelectDateTime
                  admin={false}
                  handle={handleForm}
                  defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="end" />
              </div>
            </div>
            <div className='inputsFilter'>
              <div className='inputContainer'>
                <ListInput
                  value={formFields.idGear ?? 0}
                  name="idGear"
                  handle={handleForm}
                  list={carGear}
                  placeholder="Transmission" />
              </div>
              <div className='inputContainer'>
                <ListInput
                  value={formFields.idFuel ?? 0}
                  name="idFuel"
                  handle={handleForm}
                  list={fuel}
                  placeholder="Carburant" />
              </div>
              <div className='inputContainer'>
                {/* <ListInput
                  value={formFields.description.siegeNbr ?? 0}
                  name="siegeNbr"
                  handle={handleForm}
                  list={siege}
                  placeholder="Nombre de siège" /> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='carList view'>
        {
          carFiltered.length ?
            carFiltered.map((element) => {
              return <CarItem key={element.id} car={element} />;
            }) :
            <div className='no-car'>
              <h3>0 voiture disponible pour cette durée</h3>
              <div className='recommendation'><h4 className='title'>Notre collection:</h4><div className='line'></div> </div>
              <div className='carList'>
                {
                  ((): Array<Car> => {
                    let myMap: Array<Car> = [];
                    let present: boolean;
                    cars.forEach((car) => {
                      present = false;
                      if (myMap.find((element) => {
                        return (
                          element.CarPrices[0].firstPrice === car.CarPrices[0].firstPrice &&
                          element.CarPrices[0].secondPrice === car.CarPrices[0].secondPrice &&
                          element.CarPrices[0].thirdPrice === car.CarPrices[0].thirdPrice &&
                          element.CarPrices[0].fourthPrice === car.CarPrices[0].fourthPrice &&
                          element.ModelId === car.ModelId
                        );
                      })) present = true;
                      if (!present) {
                        myMap.push(car);
                      }
                    })
                    return myMap;
                  })().map((element) => {
                    return <CarItem key={element.id} car={element} />;
                  })
                }
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default Cars;
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import {
  MdOutlinePhoneForwarded,
  MdOutlineMarkEmailRead,
  MdOutlineLocationCity
} from 'react-icons/md';
//assets
import lines from 'assets/svg/linesFooter.svg';
import logo from 'assets/logo/logo.svg'
//style
import './footer.scss';

const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className='footer-content'>
        <div className='footer-icons'>
          <a href={`tel:${process.env.REACT_APP_Tel}`} className='footer-icon'>
            <MdOutlinePhoneForwarded />
            <p>Avoir une question ? appelez nous maintenant</p>
          </a>
          <a href={`mailto:${process.env.REACT_APP_Email}`} className='footer-icon'>
            <MdOutlineMarkEmailRead />
            <p>Besoin de soutien?
              Envoyer nous un e-mail</p>
          </a>
          <a target="_blank"
            rel="noreferrer"
            href='https://goo.gl/maps/Mw7w4EvkEdgbVFFg8'
            id='lastIcon' className='footer-icon'>
            <MdOutlineLocationCity />
            <p>
              Un conseiller a votre disposition
              Contactez-nous
            </p>
          </a>
        </div>
        <div className='footer-content-2'>
          <div className='brand footer-content-column'>
            <img className='logo' src={logo} alt="slama rent car logo footer" />
            <p>
              SLAMA Rent a Car est une agence de location
              de voitures en Tunisie disposant à Sousse,
            </p>
          </div>

          <div className='social footer-content-column-social'>
            <a
              className='sociel-link'
              href={process.env.REACT_APP_FACEBOOK}
              target="_blank"
              rel="noreferrer"
            ><FaFacebookF /></a>

            <a
              className='sociel-link'
              href={process.env.REACT_APP_INSTAGRAM}
              target="_blank"
              rel="noreferrer"
            ><FaInstagram /></a>

          </div>
          <div className='menu footer-content-column'>
            <nav>
              <ul>
                <Link to='/about-us' ><li>A propos</li></Link>
                <Link to='/cars'><li>Voitures</li></Link>
                <Link to='/contact-us'><li>Contacter nous</li></Link>
              </ul>
            </nav>
          </div>
          <div className='contact footer-content-column'>
            <h2>Contacter nous</h2>

            <ul>
              <li>GSM 1 : {process.env.REACT_APP_Tel}</li>
              <li>GSM 2 : {process.env.REACT_APP_Tel_fix}</li>
              <li>Localisation : {process.env.REACT_APP_Location}</li>
              <li>Email : {process.env.REACT_APP_Email}</li>
            </ul>

          </div>
        </div>
        <div className='copyright'>
          <div className='line'></div>
          <p>
            Copyright © 2022 Tous droits réservés.&nbsp;
            <Link to={"/"}>slama-rent-car.com</Link>. Powered BY&nbsp;
            <a href="http://www.wadia.dev" target="_blank" rel="noreferrer">W</a> & &nbsp;
            <a href="http://www.khaled-baili.tn" target="_blank" rel="noreferrer">K</a>
          </p>
        </div>
      </div>
      <div className='background'>
        <img className='lines' src={lines} alt="" />
      </div>
    </footer>
  )
}

export default Footer;
import { IconType } from "react-icons/lib";

import { AiOutlineHome, AiOutlineCar, AiOutlineIdcard } from 'react-icons/ai';
import { VscHistory } from 'react-icons/vsc';
import { BsCalendar4Range } from 'react-icons/bs';
import { GiCarKey } from 'react-icons/gi';
import { HiOutlineUsers } from 'react-icons/hi';
import { FaBan, FaRegCalendarCheck } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";


type item = {
  id: string
  icon: IconType
  text: string
  role: Array<string>
}

const list: Array<item> = [
  {
    id: "accueil",
    icon: AiOutlineHome,
    text: "Accueil",
    role: ["ROLE_USER", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "historic",
    icon: VscHistory,
    text: "Mes locations",
    role: ["ROLE_USER", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "calendar",
    icon: BsCalendar4Range,
    text: "Calendrier",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "availablity",
    icon: FaRegCalendarCheck,
    text: "Disponibilité",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "cars",
    icon: AiOutlineCar,
    text: "Gérer les voitures",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "rents",
    icon: GiCarKey,
    text: "Gérer les locations",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "users",
    icon: HiOutlineUsers,
    text: "Gérer les utilisateurs",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
  {
    id: "bloc",
    icon: FaBan,
    text: "Blocage",
    role: ["ROLE_SUPER_ADMIN"]
  },
  {
    id: "employers",
    icon: AiOutlineIdcard,
    text: "Gérer les employeurs",
    role: ["ROLE_SUPER_ADMIN"]
  },
  {
    id: "contact",
    icon: MdMarkEmailUnread,
    text: "Messages",
    role: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
  },
]

export const getUserNav = (userRole: string) => {
  return list.filter((item) => { return item.role.includes(userRole) });
}
import { FunctionComponent, useEffect, useState } from 'react';
import { getMyContracts } from 'services/contract.service/contract.service';
import { ContractInterface } from 'services/contract.service/contract.interface';
import { date } from 'utils/date/date.util';
//component
import SearchBar from '../searchBar/searchBar.component';
//style
import './historic.scss';

const url = process.env.REACT_APP_API_URL;

const Historic: FunctionComponent = () => {
  const [search, setSearch] = useState('');
  const [contracts, setContracts] = useState<Array<ContractInterface>>([]);
  const [contractsFiltered, setContractsFiltered] = useState<Array<ContractInterface>>([]);

  useEffect(() => {
    setContractsFiltered(
      contracts.filter((element) => {
        const stringToTest =
          element.Car.Model.name +
          element.startDate +
          element.endDate +
          element.ContractStatus.name;
        return stringToTest.toLowerCase().includes(
          search.replace(/\s/g, '').toLowerCase()
        );
      })
    );
  }, [search, contracts])

  useEffect(() => {
    getMyContracts()
      .then((result) => {
        setContracts(result);
        setContractsFiltered(result);
      })
      .catch(() => {
        setContracts([]);
        setContractsFiltered([]);
      })
  }, [])

  const handleSearch = (search: string) => {
    setSearch(search)
  }

  return (
    <div className="historicPage">
      <SearchBar
        text={true}
        title='Votre historique de location'
        handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='car-name-header sticky-col'>Voiture</th>
              <th>De</th>
              <th>à</th>
              <th>Status</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            {
              contractsFiltered.map((element) => {
                return (
                  <tr key={element.id} className='table-row'>
                    <td className='car-name sticky-col'>
                      <img
                        className='carImg'
                        src={url + "/uploads/" + element.Car.CarImages[0]?.imagePath}
                        alt={element.Car.Model.name} />
                      <p>{element.Car.Model.name}</p>
                    </td>
                    <td>{date(element.startDate)}</td>
                    <td>{date(element.endDate)}</td>
                    <td className={
                      (() => {
                        switch (element.ContractStatus.name) {
                          case 'Approuvé':
                            return 'green';
                          case 'Rejeté':
                            return 'red';
                          case 'En attente':
                            return 'yellow'
                          default: return 'black';
                        }
                      })()
                    }
                    >{element.ContractStatus.name}</td>
                    <td>{
                      element.paidAmount && element.paidAmount !== element.initialAmount ?
                        <span>
                          <del>{element.initialAmount}</del> {element.paidAmount}
                        </span> :
                        element.initialAmount
                    } TND</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Historic;
import { BrandInterface } from "./brand.interface";

export const defaultBrand: BrandInterface = {
  id: 0,
  name: '',
  logoImg: ''
}

export const defaultBrandInput = {
  name: '',
  logoImg: ''
}
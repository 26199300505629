import { Outlet, useLocation } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
//components
import DashboardNav from "./dashboardNav/dashboardNav.component";
//style
import './dashboard.scss';
import { getUserData } from "services/user.service/user.service";
import { UserContext } from "contexts/user.context";
import { HiMenuAlt3 } from "react-icons/hi";
import { noIndexPage, setDescription, setTitle } from "utils/seo/seo.utils";

const Dashboard: FC = () => {

  useEffect(() => {
    noIndexPage();
    setTitle("Dashboard");
    setDescription(`Votre tableau de bord personnel chez Slama rent a car. 
    Suivez facilement vos achats de voitures, gérez votre profil et accédez à des fonctionnalités exclusives. 
    L'endroit idéal pour rester connecté avec votre collection automobile.
    `)
  }, []);

  const { setUser } = useContext(UserContext);

  const [lastChild, setLastChild] = useState('');
  const [openMenu, setOpenMenu] = useState(0);
  let location = useLocation();

  useEffect(() => {
    getUserData()
      .then((result) => {
        setUser(oldValues => { return { ...oldValues, ...result, birthDate: new Date(result.birthDate) } });
      })
  }, [setUser])

  useEffect(() => {
    const path = location.pathname;
    setLastChild(path.substring(path.lastIndexOf('/') + 1));

    const table = document.querySelector(".dashboard-page-content");
    if (table) {
      document.documentElement.style.setProperty('--widthSticky', "1px");
      table.addEventListener("scroll", () => addShadow(table.scrollLeft), { passive: true });
    }
    return () => {
      if (table)
        table.removeEventListener("scroll", () => addShadow(table.scrollLeft))
    }

  }, [location]);

  const addShadow = (position: number) => {
    position ?
      document.documentElement.style.setProperty('--widthSticky', "2px")
      : document.documentElement.style.setProperty('--widthSticky', "1px")
  }

  return (
    <div className="dashboard">
      <DashboardNav select={lastChild} openMenu={openMenu} />
      <div className="dashboard-content">
        <div className="btn-menu-mobile">
          <HiMenuAlt3 onClick={() => { setOpenMenu(oldValue => oldValue + 1) }} className="btn-open-menu" />
        </div>
        <Outlet />
      </div>
    </div>

  )
}

export default Dashboard;


import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
//icons
import { MdPriceCheck } from 'react-icons/md';
import { FaBaby, FaPlaneDeparture } from 'react-icons/fa';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { GiClick } from 'react-icons/gi';
//components
import Button from 'components/button/Button.component';
import CarItem from 'components/carItem/carItem.component';
import SelectDateTime from 'components/inputs/selectDateTime/selectDateTime.component';
//context
import { SearchContext } from 'contexts/search.context';
//interface
import { Car } from 'services/car.service/car.interface';
import { getBestPlan } from 'services/car.service/car.service';
//assets
import { ReactComponent as Slogon2 } from 'assets/svg/slamaRentCar2.svg';
import lines from 'assets/svg/linesFooter.svg';
import golf from 'assets/images/golf.png';
import { isToday, isTomorrow } from 'utils/date/date.util';
//style
import './home.scss';
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import { indexPage, setDescription, setTitle } from 'utils/seo/seo.utils';

const url = process.env.REACT_APP_API_URL;

const Home: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Slama rent a car : Agence de location de voitures en Tunisie");
    setDescription(`Bienvenue chez Slama rent a car - Votre destination ultime pour l'automobile de luxe. 
    Explorez notre gamme exclusive de voitures haut de gamme, découvrez les dernières tendances et trouvez l'inspiration pour votre prochain véhicule de rêve. 
    Découvrez le luxe automobile dès aujourd'hui.
    `)
  }, []);

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useContext(SearchContext);

  const [formFields, setFormFields] = useState({
    startDate: startDate,
    endDate: endDate
  });
  const [cars, setCars] = useState<Array<Car>>([]);

  useEffect(() => {
    getBestPlan()
      .then((result) => {
        setCars(result);
      })
    setFormFields({ startDate: startDate, endDate: endDate });
  }, [startDate, endDate])

  const handleForm = (event: any) => {
    const { name, value } = event;
    let hour;
    if (value)
      switch (name) {
        case "end":
        case "start":

          if (startDate) {
            hour = startDate.getHours();
            if ((isToday(startDate) || isToday(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else if (endDate) {
            hour = endDate.getHours();
            if ((isTomorrow(endDate) || isTomorrow(value))) {
              const date1 = new Date(new Date(value).setHours(hour));
              const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
              if (date1 < date2) {
                hour = new Date(date2).getHours() + 1;
              }
            }
          } else {
            const date1 = new Date(new Date(value).setHours(value.getHours()));
            const date2 = new Date(new Date().setHours(new Date().getHours() + 1));
            if (
              ((name === "start" && isToday(value)) ||
                (name === "end" && isTomorrow(value)))
              && date1 < date2)
              hour = value.getHours() + 1;
            else {
              hour = value.getHours();
            }
          }

          if (name === "end") {
            setStartDate(startDate ? new Date(startDate.setHours(hour, 0, 0)) : null);
            setEndDate(new Date(value.setHours(hour, 0, 0)));
          } else {
            setEndDate(endDate ? new Date(endDate.setHours(hour, 0, 0)) : null);
            setStartDate(new Date(value.setHours(hour, 0, 0)));
          }
          break;

        case "hour":
          hour = value.getHours();
          if (endDate && startDate) {
            setEndDate(new Date(endDate.setHours(hour, 0, 0)));
            setStartDate(new Date(startDate.setHours(hour, 0, 0)));
          } else if (endDate) {
            setEndDate(new Date(endDate.setHours(hour, 0, 0)));
          } else if (startDate) {
            setStartDate(new Date(startDate.setHours(hour, 0, 0)));
          }
          break;
      }
  }

  return (
    <div className="home">
      <div className="home-header">
        <div className="home-header-background">
          <img className='lines' src={lines} alt="" />
        </div>
        <div className='home-header-content view'>
          <div className='home-header-top'>
            <div className='form'>
              <Slogon2 className='D1100' />
              <p>
                Avec SLAMA RENT A CAR, effectuez une location voiture tunisie pas cher.
                Les meilleures promos de location auto tunisie.
                Réservez votre voiture en 3 clics.
              </p>
              <h2>Location voiture Tunisie</h2>
              <form>
                <SelectDateTime
                  admin={false}
                  handle={handleForm}
                  defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="start" />
                <SelectDateTime
                  admin={false}
                  handle={handleForm}
                  defaultForm={{
                    start: formFields.startDate,
                    end: formFields.endDate
                  }} type="end" />
                <div className='search-btn'>
                  <Link to={'/cars'}>
                    <Button
                      moreStyle='devis'
                      submit={true}>
                      Devis et réservation
                    </Button>
                  </Link>
                </div>
              </form>
            </div>
            <div className='carSlide'>
              <Swiper
                spaceBetween={100}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {
                  cars.map((element, index) => {
                    return (
                      <SwiperSlide key={index} className='carImage'>
                        <img
                          src={url + "/uploads/" + element.CarImages[0]?.imagePath}
                          alt="Slama rent a car slider" />
                      </SwiperSlide>
                    );
                  })
                }
              </Swiper>
            </div>
          </div>
          <div className='home-header-bot'>
            <div className='container'>
              <div className="mouse"></div>
              <div className="links">
                <a
                  className='sociel-link'
                  href={process.env.REACT_APP_FACEBOOK}
                  target="_blank"
                  rel="noreferrer"
                ><FaFacebookF /></a>

                <a
                  className='sociel-link'
                  href={process.env.REACT_APP_INSTAGRAM}
                  target="_blank"
                  rel="noreferrer"
                ><FaInstagram /></a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='bon-plan view'>
        <h2>BON PLAN</h2>
        <p>
          SLAMA RENT A CAR est le spécialiste de la location de voiture en Tunisie .
          Afin de répondre à  vos besoins en matière de mobilité
        </p>
        <div className='line'></div>
        <div className='carList'>
          {
            cars.map((element) => {
              return <CarItem key={element.id} car={element} />;
            })
          }
        </div>
      </div>
      <div className='showMore view'>
        <div className='line'>
          <Link to={'cars'}><Button>Voir plus</Button></Link>
        </div>

      </div>
      <div className='why-us view'>
        <div className='title'>
          <h2>POURQUOI LOUER AVEC SLAMA RENT A CAR?</h2>
        </div>
        <div className='why-us-content'>
          <div className='slama-offers'>
            <div className='slama-description'>
              <h3>A propos de</h3>
              <p>
                SLAMA Rent a Car est une agence de location de voitures en Tunisie.
                Disposant d'agence à Sousse, SLAMA Rent a Car propose la location de voitures à l'aéroport.
                Notre agence de location voiture vous garantit une prestation qui cible le zéro incident tout au long de la période de location.
                Nous sommes convaincus que la location de véhicule n’est pas seulement d’aller d’un point A à un point B mais vivre
                une expérience unique.
              </p>
            </div>
            <div className='offers-list'>
              <div className='offer'>
                <FaBaby />
                <p>Siège Bébé gratuit</p>
              </div>
              <div className='offer'>
                <FaPlaneDeparture />
                <p>Livraison à l’aéroport</p>
              </div>
              <div className='offer'>
                <GiClick />
                <p>Réservez à tout moment</p>
              </div>
              <div className='offer'>
                <MdPriceCheck />
                <p>Meilleur prix de location</p>
              </div>
            </div>
          </div>
          <img className='golf-image' src={golf} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Home; 
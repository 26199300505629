import { FC, FormEvent, useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
//context
import { AddCarContext } from "contexts/addCar.context";
import { CarsContext } from "contexts/cars.context";
import { PopupContext } from "contexts/popup.context";
//object
import { defaultErrors, } from "services/car.service/car.object";
//interface
import { CarInput } from "services/car.service/car.interface";
//service
import { updateCar } from "services/car.service/car.service";
//component
import CarFields from "../carFields/carFields.components";
//style
import './updateCar.scss';

interface UpdateCarProps {
  id: number
}

const UpdateCar: FC<UpdateCarProps> = ({ id }) => {

  const { status, reset } = useContext(CarsContext);
  const { popNext } = useContext(PopupContext);
  const { car, img } = useContext(AddCarContext);

  const [formFields, setFormFields] = useState<CarInput>(car);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setFormFields(car);
    setImage(img)
  }, [car, img])

  const handleForm = (event: any) => {
    let { name, value } = event;
    if (name === "logoImg") {
      if (!event.files[0])
        setErrors(oldValues => { return { ...oldValues, images: "Image obligatoire." } });
      else
        setErrors(oldValues => { return { ...oldValues, images: '' } });
      setImage(event.files[0]);
      return;
    } else if ((name === "FuelId" || name === "GearBoxId") && typeof value === "string") {
      value = parseInt(value, 10);
    }

    setFormFields(oldValues => { return { ...oldValues, [name]: value } });
    setErrors(oldValues => { return { ...oldValues, [name]: "" } })
  }

  const handlePrice = (month: number, name: "firstPrice" | "secondPrice" | "thirdPrice" | "fourthPrice", price: number) => {

    if (typeof price === "string") {
      price = parseInt(price, 10);
    }

    // Create a copy of the data object
    const updatedData = { ...formFields };

    if (formFields.CarPrices.find(carPrice => carPrice.month === month)) {
      updatedData.CarPrices = formFields.CarPrices.map(carPrice =>
        carPrice.month === month ? { ...carPrice, [name]: price } : carPrice
      );
    } else {
      updatedData.CarPrices.push({ month, firstPrice: 0, secondPrice: 0, thirdPrice: 0, fourthPrice: 0, [name]: price })
    }

    // Update the state with the new data object
    setFormFields(updatedData);
  }


  const handleStatus = () => {
    if (formFields.CarStatus) {
      if (formFields.CarStatus.name === "Disponible") {
        const outOfServiceStatus = status.filter((element) => { return element.name === "Hors service" })[0];
        setFormFields({
          ...formFields,
          CarStatus: outOfServiceStatus,
          CarStatusId: outOfServiceStatus.id,
        })
      }
      else if (formFields.CarStatus.name === "Hors service") {
        const freeStatus = status.filter((element) => { return element.name === "Disponible" })[0];
        setFormFields({
          ...formFields,
          CarStatus: freeStatus,
          CarStatusId: freeStatus.id,
        })
      }
      ReactTooltip.rebuild();
    }
  }

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setErrors(defaultErrors);
    prepareData()
      .then((data) => {
        updateCar(data, id)
          .then((result) => {
            reset();
            popNext();
          })
          .catch((error) => {
            if (error.message) {
              setErrors({ ...errors, registrationNumber: error.message });
            }
            else {
              setErrors({ ...errors, ...error.errors });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setErrors({ ...errors, images: error });
        setLoading(false);
      })
  }

  const prepareData = async () => {
    const formData = new FormData();
    if (image) {
      await formData.append(
        'images',
        image
      )
    }
    else {
      if (!formFields.images)
        return Promise.reject("Image obligatoire.")
    }

    await Object.keys(formFields).forEach((key) => {
      if (key === "CarPrices") {
        formData.append(
          key,
          JSON.stringify((formFields as any)[key])
        );
      } else {
        formData.append(
          key,
          (formFields as any)[key]
        );
      }
    })

    return formData;
  }

  return (
    <div className="updateCar">
      <h1>Modifier voiture</h1>
      <CarFields
        loading={loading}
        image={image}
        formFields={formFields}
        errors={errors}
        type="update"
        handleSubmit={submitForm}
        handleForm={handleForm}
        handlePrice={handlePrice}
        handleStatus={handleStatus}
        carStatus={formFields.CarStatus?.name}
      />
    </div>
  );
}

export default UpdateCar;
import Popup from "components/popup/popup.component";
import PopupDone from "components/popup/popupDone/popupDone.component";
import React, { createContext, useEffect, useMemo, useState } from "react";

interface PopupInterface {
  visibility: boolean,
  content: JSX.Element
  next: Array<JSX.Element>,
  setVisibility: (open: boolean) => void,
  setContent: (content: JSX.Element) => void,
  closePopUp: () => void,
  pushNext: (content: JSX.Element) => void,
  popNext: () => void,
  done: (message: string) => void
}

export const PopupContext = createContext<PopupInterface>({
  visibility: false,
  content: <div></div>,
  next: [],
  setVisibility: () => { },
  setContent: () => { },
  closePopUp: () => { },
  pushNext: () => { },
  popNext: () => { },
  done: () => { }
})

type popUpProps = {
  children: React.ReactChild
}

export const PopupProvider = ({ children }: popUpProps) => {
  const [visibility, setVisibility] = useState(false);
  const [content, setContentState] = useState(<div></div>);
  const [next, setNext] = useState<Array<JSX.Element>>([]);


  useEffect(() => {
    if (next.length === 0)
      setContentState(<div />)
    else
      setContentState(next[next.length - 1]);
  }, [next]);

  const closePopUp = useMemo(() => () => {
    setContentState(<div />);
    setNext([]);
    setVisibility(false);
    document.body.style.overflow = 'auto';
  }, [])

  const popNext = useMemo(() => () => {
    if (next.length > 1) {
      setNext(next => next.slice(0, -1));
    }
    else {
      closePopUp();
    }
  }, [closePopUp, next.length])

  const setContent = useMemo(() => (element: JSX.Element) => {
    document.body.style.overflow = 'hidden';
    setContentState(element);
    setNext([element]);
    setVisibility(true);
  }, [])

  const pushNext = useMemo(() => (element: JSX.Element) => {
    if (next.length > 0) {
      setNext(next => [...next, element]);
    }
    else {
      setContent(element);
    }
  }, [next.length, setContent])


  const done = useMemo(() => (message: string) => {
    pushNext(
      <PopupDone
        message={message}
      />);
  }, [pushNext])

  const value = { visibility, content, next, pushNext, popNext, setVisibility, setContent, closePopUp, done };
  return <PopupContext.Provider value={value}>{visibility && <Popup>{content}</Popup>}{children}</PopupContext.Provider>
}
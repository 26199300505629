import { PopupContext } from 'contexts/popup.context';
import { FC, ReactChild, useContext, useEffect, useState } from 'react';
import './popup.scss';

interface PopupProps {
  children: ReactChild
}

const Popup: FC<PopupProps> = ({ children }) => {

  const { popNext } = useContext(PopupContext);
  const [popupElement, setPopupElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPopupElement(document.getElementById('popup'))
  }, [])

  const closePopup = (e: any) => {
    const dialog = document.querySelectorAll('[role="dialog"]')
    if (dialog.length) {
      dialog.forEach((el) => {
        if (!(el?.contains(e.target as Node) || popupElement?.contains(e.target as Node))) {
          popNext();
          return;
        }
      });
    } else {
      if (!popupElement?.contains(e.target as Node)) {
        popNext()
        return;
      }
    }
  }

  return (
    <div onClick={closePopup} className="popup-screen">
      <div id='popup' className="popup">
        <div className="popup-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Popup;
import React, { FunctionComponent } from 'react';
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
//style
import './navItem.scss';

type NavItemProps = {
  children: React.ReactChild,
  icon: IconType,
  selected?: string,
  id: string
  click?: () => void
}

const NavItem: FunctionComponent<NavItemProps> = ({ children, icon, selected, id, click }) => {
  return (
    <Link onClick={click} to={id === "accueil" ? "/" : id}>
      <div className={`navItem ${selected === id ? "navItemselected" : "navItems"}`} >
        {React.createElement(icon)}
        {children}
      </div>
    </Link>

  )
}

export default NavItem;
import { FC } from 'react';
//style
import './navItem.scss';

interface NavItemProps {
  children: string,
  select: string
  handle: (page: string) => void
}
const NavItem: FC<NavItemProps> = ({ children, select, handle }) => {

  const onClick = () => {
    handle(children.toLowerCase())
  }

  return (
    <div
      onClick={onClick}
      className={`settingsNavItem ${select === children.toLowerCase() ? "settingsNavItemSelected" : ""}`}>
      {children}
    </div>
  )
}

export default NavItem;
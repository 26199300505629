import { useLocation, useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
//services
import { newPassword } from 'services/auth.service/auth.service';
//object
import { defaultNewPassword } from 'services/auth.service/auth.object';
//components
import MainNavigation from '../../main/header/navigation.component';
import TextInput from 'components/inputs/textInput/text.component';
import Button from 'components/button/Button.component';
import Footer from 'app/main/footer/footer.component';
import { indexPage, setDescription, setTitle } from 'utils/seo/seo.utils';

const NewPassword: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Définir un Nouveau Mot de Passe | Slama rent a car");
    setDescription(`
    Définir un Nouveau Mot de Passe - Créez en toute sécurité un nouveau mot de passe pour votre compte chez Slama rent a car. 
    Suivez les étapes pour définir un mot de passe fort et fiable afin d'améliorer la sécurité de votre compte.
    `)
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const [token] = useState<string | null>(useQuery().get('token'));
  const [formFields, setFormFields] = useState({ ...defaultNewPassword, token: token });
  const [error, setError] = useState(defaultNewPassword);
  const navigate = useNavigate();

  if (!token) {
    setTimeout(() => {
      navigate("/404");
    }, 1)
  }

  const formHandle = (event: any) => {
    const { name, value } = event
    setFormFields({ ...formFields, [name]: value });
    setError({ ...error, [name]: "" });
  }


  const newPasswordSubmit = (e: any) => {
    e.preventDefault();
    setError(defaultNewPassword);
    newPassword(formFields)
      .then((result) => {
        navigate("/auth/sign-in");
      })
      .catch((error) => {
        setError({ ...defaultNewPassword, ...error.errors });
      });
  }

  return (
    <div className='auth'>
      <div className="forget-password">
        <MainNavigation />
        <div className='forget-password-content'>
          <div className='background'>
          </div>
          <form onSubmit={newPasswordSubmit}>
            <div className='forget-password-container newPassword'>
              <h2>Réinitialiser votre mot de passe</h2>
              <TextInput
                handle={formHandle}
                error={error.password}
                option={{
                  name: "password",
                  type: "password",
                  placeholder: "Nouveau mot de passe"
                }} />

              <TextInput
                handle={formHandle}
                error={error.confirmPassword}
                option={{
                  name: "confirmPassword",
                  type: "password",
                  placeholder: "Répetez nouveau mot de passe"
                }} />

              <Button
                submit={true}
                moreStyle='reset-btn'
              >Réinitialiser</Button>
            </div>
          </form>

        </div>
        <Footer />
      </div>
    </div>
  )
}

export default NewPassword;
import React, { Dispatch, createContext, useMemo, useState } from "react";
//service
import { signOutService } from 'services/auth.service/auth.service';
//object
import { UserInterface } from "services/user.service/user.interface";
import { defaultUser } from "services/user.service/user.object";

interface UserContextInterface {
  user: UserInterface,
  setUser: Dispatch<React.SetStateAction<UserInterface>>,
  signOut: () => void
}

export const UserContext = createContext<UserContextInterface>({
  user: defaultUser,
  setUser: () => { },
  signOut: () => { }
})

type userProps = {
  children: React.ReactChild
}

export const UserProvider = ({ children }: userProps) => {
  const [user, setUser] = useState(defaultUser);

  const signOut = useMemo(() => () => {
    signOutService();
    setUser(defaultUser);
  }, [])

  const value = { user, setUser, signOut };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
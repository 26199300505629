import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FC, FormEvent, useContext, useEffect, useState } from "react";
//service
import { signIn } from 'services/auth.service/auth.service';
//object
import { defaultSignIn } from "services/auth.service/auth.object";
//context
import { UserContext } from "contexts/user.context";
//assets
import { ReactComponent as SlamaRentCar } from 'assets/svg/slamaRentCar.svg';
import logo from 'assets/logo/logo-black.svg';
//components
import TextInput from "components/inputs/textInput/text.component";
import Button from "components/button/Button.component";
import { indexPage, setDescription, setTitle } from "utils/seo/seo.utils";


const SignIn: FC = () => {

  useEffect(() => {
    indexPage();
    setTitle("Connexion | Slama rent a car");
    setDescription(`
    Connectez-vous à votre compte chez slama rent a car pour accéder à des fonctionnalités exclusives. 
    Entrez vos identifiants pour accéder à votre espace personnel en toute sécurité.
    `)
  }, []);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const [token] = useState<string | null>(useQuery().get('verifyEmail'));
  const [reservation] = useState<string | null>(useQuery().get('reservation'));
  const [formFields, setFormFields] = useState(defaultSignIn);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(defaultSignIn);
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleForm = (event: any) => {
    const { name, value } = event
    setFormFields({ ...formFields, [name]: value });
  }

  useEffect(() => {
    setLoading(false);
    return () => {
      setLoading(false);
    }
  }, [])

  const submit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    signIn(formFields)
      .then((result) => {
        setUser(result);
        setError(defaultSignIn);
        token ?
          navigate(`/auth/update-email?token=${token}`)
          : reservation ? navigate(`/cars?reservation=${reservation}`) : navigate("/dashboard/settings");
      }).catch((error) => {
        setError({ ...defaultSignIn, ...error.errors });
      })
      .finally(() => {
        setLoading(false);
      });

  }

  return (
    <div className="auth">
      <div className="form-view" onSubmit={submit}>
        <div className="form-container">
          <div className="logo-container signIn">
            <Link to="/"><img src={logo} className="logo" alt="slama rent car logo" /></Link>
          </div>
          <form className="form">
            <TextInput
              option={{
                name: "email",
                type: "email",
                placeholder: "Numéro ou Email"
              }}
              error={error.email}
              handle={handleForm} />
            <div className="gap"></div>
            <TextInput
              option={{
                name: "password",
                type: "password",
                placeholder: "Mot de passe"
              }}
              error={error.password}
              handle={handleForm} />
            <div className="gap"></div>
            <Button loading={loading} submit={true} moreStyle="auth-btn">Connecter</Button>
            <div className="gap"></div>
            <Link to="/auth/forget-password" className="forget-password-link">Mot de passe oublié ?</Link>
            <div className="gap"></div>
            <div className="separetor"> <div className="line"></div> <p>OU</p> <div className="line"></div></div>
            <div className="gap"></div>
            <Link to="/auth/sign-up"><Button type="secondary">Créer un compte</Button></Link>
          </form>
          <div>

          </div>
        </div>
      </div>
      <div className="thumbnail">
        <div></div>
        <div className="slegon">
          <SlamaRentCar />
          <p>Toujours opté pour le professionnalisme et le meilleur service</p>
        </div>
        <div className="links">
          <a
            className='sociel-link'
            href={process.env.REACT_APP_FACEBOOK}
            target="_blank"
            rel="noreferrer"
          ><FaFacebookF /></a>

          <a
            className='sociel-link'
            href={process.env.REACT_APP_INSTAGRAM}
            target="_blank"
            rel="noreferrer"
          ><FaInstagram /></a>
        </div>
      </div>
    </div>
  )
}

export default SignIn;
import Spinner from "components/spinner/spinner.component";
import ReactTooltip from "react-tooltip";
import { FC } from "react";
//style
import './button.scss';

type ButtonProps = {
  option?: object
  size?: "small" | "normal" | "small rounded" | "rounded",
  type?: "primary" | "secondary" | "success" | "edit" | "white",
  submit?: boolean,
  moreStyle?: string,
  tooltip?: string,
  handle?: () => void;
  children?: React.ReactNode,
  loading?: boolean,
  disabled?: boolean
};

const Button: FC<ButtonProps> = (props) => {

  return (
    <button
      disabled={props.loading || props.disabled}
      data-tip={props.tooltip}
      onClick={props.handle}
      type={props.submit ? "submit" : "button"}
      className={`btn ${!props.size?.length ?
        "normal-btn" :
        BUTTON_SIZE[props.size]} ${!props.type?.length ?
          "primary-btn" :
          BUTTON_TYPE[props.type]} ${props.moreStyle ? props.moreStyle : ""}`
      }
      {...props.option}>
      {
        props.loading ?
          <Spinner />
          : null
      }
      {props.children}
      <ReactTooltip place="bottom" type="dark" effect="solid" />
    </button>
  )
}

const BUTTON_TYPE = {
  "primary": "primary-btn",
  "secondary": "secondary-btn",
  "success": "success-btn",
  "edit": "edit-btn",
  "white": "white-btn"
}

const BUTTON_SIZE = {
  "small": "small-btn",
  "normal": "normal-btn",
  "small rounded": "small-btn-rounded",
  "rounded": "normal-btn-rounded"
}

export default Button;
import SearchBar from "app/dashboard/searchBar/searchBar.component";
import { FC, useContext, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
//component
import Button from "components/button/Button.component";
import AddModel from "./addModel/addModel.component";
import PopupVerify from "components/popup/popupVerify/popupVerify.component";
//context
import { CarsContext } from "contexts/cars.context";
import { PopupContext } from "contexts/popup.context";
import { UserContext } from "contexts/user.context";
//interface
import { ModelInterface } from "services/model.service/model.interface";
import { deleteModel } from "services/model.service/model.service";

interface ModelsManageProps {

}

const ModelsManage: FC<ModelsManageProps> = () => {

  const { user } = useContext(UserContext);
  const { models, cars, reset } = useContext(CarsContext);
  const { setContent, pushNext } = useContext(PopupContext);

  const [modelFiltered, setModelFiltered] = useState<Array<ModelInterface>>();
  const [search, setSearch] = useState<string>('');
  const [controlAccess, setControlAccess] = useState<{ text: string, handle: () => void }>();

  useEffect(() => {
    reset();
  }, [reset]);


  useEffect(() => {
    if (user.role_name === "ROLE_SUPER_ADMIN")
      setControlAccess({
        text: "Ajouter un model",
        handle: () => {
          pushNext(<AddModel type="add" />)
        }
      })
  }, [user, pushNext]);

  useEffect(() => {
    setModelFiltered(models.filter((element) => {
      return element.name.toLowerCase().includes(search.toLowerCase())
    }).sort((element1, element2) => {
      return element1.Brand.name < element2.Brand.name ?
        -1
        : 1;
    }))
  }, [search, models])

  const handleSearch = (event: any) => {
    setSearch(event);
  }

  const updateModel = (model: ModelInterface) => {
    pushNext(<AddModel type="update" model={model} />)
  }

  const removeModel = (model: ModelInterface) => {
    const handle = () => {
      deleteModel(model.id)
        .then(() => {
          reset();
        })
        .catch(() => {

        })
    }
    setContent(<PopupVerify handle={handle} target={model.name} type='delete' />);
  }


  return (
    <div className="manage-brand">
      <SearchBar
        text={true}
        add={controlAccess}
        title='Paramètre des models'
        handle={handleSearch} />

      <div className='dashboard-page-content'>
        <table className='table'>
          <thead className='table-header'>
            <tr>
              <th className='sticky-col'>Model</th>
              <th>Marque</th>
              <th>Voitures</th>
              {
                user.role_name === "ROLE_SUPER_ADMIN" ?
                  <th /> : null
              }
            </tr>
          </thead>
          <tbody>
            {
              modelFiltered ?
                modelFiltered.map((element) => {
                  const carCount = cars.filter((elementCar) => {
                    return elementCar.ModelId === element.id;
                  }).length;
                  return (
                    <tr key={element.id} className='table-row'>
                      <td className='sticky-col'>{element.name}</td>
                      <td>{element.Brand.name}</td>
                      <td>{carCount}</td>
                      {
                        user.role_name === "ROLE_SUPER_ADMIN" ?
                          <td className='action2'>
                            <Button
                              tooltip='Modifier marque'
                              moreStyle='action-btn'
                              handle={() => updateModel(element)}
                              size='rounded'
                              type='edit'>
                              <MdEdit />
                            </Button>
                            <Button
                              tooltip='Supprimer modele'
                              moreStyle='action-btn'
                              handle={() => removeModel(element)}
                              size='rounded'>
                              <AiFillDelete />
                            </Button>
                          </td>
                          : null
                      }
                    </tr>
                  )
                })
                : null
            }
          </tbody>

        </table>
      </div>
    </div>
  );
}

export default ModelsManage;
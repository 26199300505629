import { FC, FormEvent, useContext, useEffect, useState } from "react";
//component
import Button from "components/button/Button.component";
import TextInput from "components/inputs/textInput/text.component";
//context
import { PopupContext } from "contexts/popup.context";
//service
import { addPoint, updatePoint } from "services/points.service/points.service";
//style
import "./addPoint.scss"
import { ReceivePointInterface } from "services/points.service/points.interface";
import { ContractContext } from "contexts/contract.context";

interface AddPointProps {
  point?: ReceivePointInterface
}

const AddPoint: FC<AddPointProps> = ({ point }) => {

  const { closePopUp } = useContext(PopupContext);
  const { resetPoints } = useContext(ContractContext);

  const [pointName, setPointName] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    return () => {
      resetPoints();
    }
  }, [resetPoints])

  useEffect(() => {
    if (point)
      setPointName(point.name)
  }, [point])

  const handleForm = (event: any) => {
    const { value } = event;
    setPointName(value);
  }

  const submit = (event: FormEvent) => {
    event.preventDefault();
    setError("");
    if (pointName) {
      if (point) {
        updatePoint(point.id, pointName)
          .then(() => {
            closePopUp();
          })
          .catch((error) => {
            setError(error.errors.name)
          })
      } else {
        addPoint(pointName)
          .then(() => {
            closePopUp();
          })
          .catch((error) => {
            setError(error.errors.name)
          })
      }
    }
    else setError("Nom du point retrait est obligatoir")
  }

  return (
    <div className="add-point-popup">
      <h2>{point ? "Modifier" : "Ajouter"} un point de retrait</h2>
      <form onSubmit={submit}>
        <TextInput
          option={{
            value: pointName,
            placeholder: "Nom du point de retrait"
          }
          }
          label="Nom"
          error={error}
          handle={handleForm} />
        <Button submit={true}>{point ? "Modifier" : "Ajouter"}</Button>
      </form>
    </div>
  );
}

export default AddPoint;